export const useFetchStatus = ()=> {
  const statusOptions = [
    {label: '全部', value: ''},
    {label: '待录入', value: 'RUNNING'},
    {label: '已录入', value: 'AGREE'},
  ]

  const statusMap = new Map(statusOptions.map(({label, value})=> [value, label]))

  return {
    statusOptions,
    statusMap,
  }
}
