import detailStyl from './DetailModal.module.scss'

import React, { useEffect, useRef, useState, useCallback } from 'react'



import { message as messager, Button, Modal, Spin, Switch } from 'antd';
import { DataType } from '../';
import { GetFormDataById, getTaskDistributeInputDetail, saveFormData, SubmitInputTask } from '../../../../../services/dct/DctService';
import { GetOcrLocation } from '../../../../../services/ocr/OcrService';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Current from './Current';
import Control from '../../../../crf_form/control/Control';
import ImageEditor, { OcrMark } from '../../../../mosaic-editor';
import AlbumSlider from '../../../imageCheck/components/album-slider';
import FullModal from '../../../../full-modal';
import { useFetchStatus } from '../repo';

type DetailModalProps = {
  data: DataType[];
  onClose: (refresh: boolean)=> void;
}

const DetailModal: React.FC<DetailModalProps> = ({ onClose, data })=> {

  const {statusMap} = useFetchStatus()

  // 加载状态
  const [loading, setLoading] = useState(false)
  // 是否需要在关闭详情时刷新列表
  const [refresh, setRefresh] = useState(false)
  // 当前某条记录
  const [current, setCurrent] = useState<DataType>()
  // 当前数据在records中的索引
  const [index, setIndex] = useState(-1)
  // 翻页数据
  const [records, setRecords] = useState<DataType[]>([])

  // 详情信息
  const [formSet, setFormSet] = useState<any>()

  const [thumb, setThumb] = useState<DataType>()
  const [thumbs, setThumbs] = useState<DataType[]>([])

  // ocr所有标记数据
  const [ocrMarkData, setOcrMarkData] =  useState<OcrMark[]>();
  // 实际标记数据, 兼容点击标记
  const [ocrSinglelMarkData, setOcrSinglelMarkData] =  useState<OcrMark[]>();
  // 是否高亮开关
  const [isOcrMark, setIsOcrMark ] = useState<boolean>(true);
  // 左右伸缩
  const [isResizing, setIsResizing] = useState(false);
  const mainRef = useRef<any>(null);

  const formRef = useRef()

  const sliderRef = useRef<any>(null)


  useEffect(()=> {
    setIndex(0)
    setRecords(data)
    setCurrent(data[0])
    sliderRef.current.slickGoTo(0)
  }, [])

  useEffect(()=>{
    // 获取所有ocr标记
    if(current && thumb){
      //重置，防止渲染
      setOcrSinglelMarkData([])
      setOcrMarkData([])
      getAllOcrLocation()
    }
  }, [thumb, current])

  // 获取详情信息
  const fetchDetail = async(id: number)=> {
    const {code, data} = await getTaskDistributeInputDetail(id)
    if(code === 0 && data.length > 0) {
      setThumbs(data)
      setThumb(data[0])
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0); // 重置到第一项
      }
    }
  }

  // 获取表单数据
  const fetchForm = async (id: number, dataTenantCode: string) => {
    const {code, data} = await GetFormDataById({ id, dataTenantCode }, true)

    if(code === 0) {
      data.formActions = [
        "FormActionSave",
        "FormActionSubmit"
      ]
      data.dataActions = [
        "DataActionInput",
        "DataActionAnnotation",
        "DataActionAnswer",
        // "DataActionQuestion",
        // "DataActionOcr"
      ]
      setFormSet(data)
    }
  }

  // 1. 获取表单相关数据
  // 2. 获取详情当前表单所有图片列表
  // 3. 清空拒绝数据
  useEffect(()=> {
    if(records[index]) {
      const { formId, tenantCode } = records[index]
      
      fetchDetail(formId)
      fetchForm(formId, tenantCode)
    }
  }, [index])

  // 处理上一条下一条数据
  const recordControl = async (step: number)=> {
    sliderRef.current.slickGoTo(0)
    // 第1条
    if(step === -1 && index === 1) {
      messager.warn('已经是第1条了')
    }
    // 最后一条
    if(step === 1 && index == records.length - 2) {
      messager.warn('已经是最后1条了')
    }

    let currentIndex = index + step;

    // 未涉及到翻页
    if(records[currentIndex]) {
      setIndex(currentIndex)
      setCurrent(records[currentIndex])
    }
  }

  const afterChange =(i: number)=> {
    setThumb(thumbs[i])
  }

  const submitForm = async()=> {
    if(!formRef.current || !current) {
      return
    }
    // @ts-ignore
    const {body, ...params} = formRef.current.getSavedForm();
    params.submit = true;
    params.dataTenantCode = current.tenantCode

    const r1: any = await saveFormData(params)

    const r2: any = await SubmitInputTask({
      id: current.id,
      rejectReason: ''
    })

    if(r1.code === 0 && r2.code === 0) {
      recordControl(1)
    }

    if(r1.code === 0 || r2.code === 0) {
      if(r2.code === 0) {
        const tmp = [...records]
        tmp[index].approveStatus = 'AGREE'
        setRecords(tmp)
        messager.success('提交成功')
      }
      setRefresh(true)
    } else {
      const pieces = []
      if(r1.code > 0) {
        pieces.push(r1.message)
      }
      if(r2.code > 0) {
        pieces.push(r2.message)
      }
      messager.error(pieces.join(','))
    }
  }

  const getAllOcrLocation = async ()=>{
    const { formId } = current as DataType
    const res = await GetOcrLocation({
      formId,
      url: thumb?.url
      
    })
    const { code , data } = res
    if(code == 0){
      setOcrMarkData(data)
    }
  }
  // 获取ocr标记参数
  const getOcrMarkParams = (item : any) =>{
      // 后端拿不到dataCode, 统一使用的deCode，忽略list结构
      setOcrSinglelMarkData(ocrMarkData?.filter(e=>e.deCode === item.deCode))
      
  }
  // ocr标记开关
  const isMarkHandle = (checked: boolean)=>{
      setIsOcrMark(checked)
     
  }

  // 左右两边拉伸
  const onMouseDown = useCallback(() => {
    setIsResizing(true);
  }, []);
 
  const onMouseMove = useCallback(
    (event) => {
      if (!isResizing || !mainRef.current) return;
      const containerWidth = mainRef.current.parentElement.offsetWidth;
      const newMainWidth = Math.max(400, Math.min(event.clientX, containerWidth - 400));
      mainRef.current.style.width = `${newMainWidth}px`;
    },
    [isResizing]
  );

  const onMouseUp = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [isResizing, onMouseMove, onMouseUp]);


  return (
    <FullModal onClose={()=> onClose(refresh)} title="数据补充录入">
      <div className={detailStyl.wrapper}>
        <div className={detailStyl.main} ref={mainRef}>
        <div className={detailStyl.mainTop}>
            <div className={detailStyl.thumbs}>
              <AlbumSlider thumbs={thumbs} ref={sliderRef} onChange={afterChange}/>
            </div>
            <div  className={detailStyl.mark}>
               高亮 <Switch defaultChecked={true} onChange={isMarkHandle} ></Switch>
            </div>
          </div>
          <div className={detailStyl.canvas}>
            {
              thumb && (
                <ImageEditor  mainRef={mainRef} preview url={thumb.url as string}  
                isOcrMark={isOcrMark} ocrMarkData={ocrMarkData} ocrSinglelMarkData={ocrSinglelMarkData}/>
              )
            }
          </div>
          <div className={detailStyl.steps}>
            <Button disabled={index === 0} onClick={()=> recordControl(-1)} shape="round">
              <ArrowLeftOutlined/>
              <span>上一条</span>
            </Button>
            <Button disabled={index === records.length - 1} onClick={()=> recordControl(1)} shape="round" style={{marginLeft: 20}}>
              <span>下一条</span>
              <ArrowRightOutlined/>
            </Button>
          </div>
        </div>
        <div className={detailStyl.resizer} onMouseDown={onMouseDown}></div>
        <div className={detailStyl.east}>
          {thumb && <Current data={thumb} showApproveTime/>}
          <div className={detailStyl.info}>
            {
              formSet && current && (
                <Control  showOcrMark={getOcrMarkParams}  tenantCode={current.tenantCode} formId={current.formId} data={formSet} ref={formRef} isDct={true}></Control>
              )
            }
          </div>
          <div className={detailStyl.foot}>
            <Button onClick={submitForm} loading={loading} type="primary" shape="round" style={{marginRight: 10}}>提交任务</Button>
          </div>
          {
            current && (
              <span data-status={current.approveStatus} className={detailStyl.status}>{statusMap.get(current.approveStatus)}</span>
            )
          }
        </div>
      </div>
    </FullModal>
  )
}

export default DetailModal