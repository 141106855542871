import axios, { AxiosResponse } from "axios";
import { requestGetRes, requestPostFileRes, requestPostRes } from "../EdcService";
import { UploadFile } from "antd";

export interface PagResult {
  code: number;
  message?: string;
  data: {
    page: number;
    pageSize: number;
    total: number;
    rows: any[];
  }
}

export interface Result<T> {
  code: number;
  message?: string;
  data: T
}

// 签署模板信息
type SignAgreementParams = {
  subjectCode: string;
  agreement: string;
  // 是否是医生签名
  judgment?: number;
}
export const signAgreement = (params: SignAgreementParams, fromDoctor=false)=> {
  return requestPostRes('plat/apply/subjectSign', {
    // 非必填做默认值
    judgment: fromDoctor ? 1 : 2,
    ...params,
  })
}

export interface DoctorRegistrationPayload {
  docterName?: string
  hospitalName?: string
  startTime?: string
  endTime?: string
}
// 医生备案列表
export const getDoctorRegistrationList = (payload: DoctorRegistrationPayload, page = 1): Promise<PagResult>=> {
  return requestPostRes('plat/doctor/recordTime/list', {
    page,
    pageSize: 20,
    ...payload,
  })
}

export interface ImportDoctorRegistrationRecord {
  currentRowNumber: number
  msg: string
}

// 导入医生备案
export const ImportDoctorRegistration = (file: File): Promise<Result<ImportDoctorRegistrationRecord[]>>=> {
  const fd = new FormData()
  fd.append('file', file)
  return requestPostFileRes('plat/doctor/import', fd)
}

// 下载医生备案表格模板
export const downloadDoctorRegistrationTemplate = (): Promise<BufferSource>=> {
  return new Promise((resolve, reject) => {
    axios.request<BufferSource>({
      url: 'plat/doctor/template/download',
      responseType: 'blob',
    })
      .then(({ data })=> resolve(data))
      .catch((err)=> reject(err))
  })
}