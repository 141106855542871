import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './control.less';
import { Badge, Col, message, Modal, Row, Tooltip } from 'antd';
import UnknownDateCom from '../components/unknownDate/UnknownDate';
import SelectCom from '../components/select/Select';
import InputCom from '../components/input/Input';
import NumberCom from '../components/number/Number';
import DateCom from '../components/date/Date';
import DecimalCom from '../components/decimal/Decimal';
import PhotoCom from '../components/photo/Photo';
import PhotoOssCom from '../components/photoOss/Photo';
import CameraCom from '../components/camera/Photo';
import FileCom from '../components/file/file'
import VideoCom from '../components/video/video';
import CheckboxCom from '../components/checkbox/checkbox';
import InputSelectCom from '../components/inputSelect/InputSelect';
import TextareaCom from '../components/textarea/Textarea';
import TextCom from '../components/text/Text';
import HideCom from '../components/hide/Hide';
import AutocompleteCom from '../components/autocomplete/Autocomplete';
import OCRAutofillCom from '../components/ocr-autofill/OCRAutofill';
import RegionSelectCom from '../components/region-select';
import { GetFormDataById, deleteFormData, isDisableDelete } from '../../../services/data/DataService';
import tool from '../utils/tool';
import verify from '../utils/verify';
import { createFromIconfontCN, WarningTwoTone, ExclamationCircleFilled, WarningOutlined } from '@ant-design/icons';
import ActionView from '../com/actionView/actionView';
import PubSub from 'pubsub-js';
import formAction from '../utils/formAction';
import { CheckFieldOptions, SimpleValueItem, StandardValueItem, debounceCheckUnique, getAllDuplicateCheckField } from '../utils/checkField';
import { ControlContext, ControlProvider } from './provider';

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_i3vee2xkqg.js',
    ],
});
let deleteListItemFlag = true

function Control(props: any, ref: any) {
    const [formId, setFormId] = useState<any>();
    const [formData, setFormData] = useState<any>([]);
    const [dataActions, setDataActions] = useState<any>([]);
    const [formActions, setFormActions] = useState<any>([]);
    const [formIsRead, setFormIsRead] = useState<boolean>(true);
    const [savedValues, setSavedValues] = useState<any>([]);
    const [visitInfoList, setVisitInfoList] = useState<any>([]);
    // 划词角标列表
    const [ocrLists, setOcrLists] = useState<any>([]);
    const [isDct, setIsDct] = useState<boolean>(false);

    const [ duplicateCheckField, setDuplicateCheckField ] = useState<CheckFieldOptions[]>([]);

    // formCenterCode 表单所在中心的code
    const [formCenterCode, setFormCenterCode] = useState<any>('');

    useEffect(() => {
        if (props.data) {
            if (props.data.body) {
                //处理数据
                tool.dealFieldList(props.data);
                tool.dealFieldListByValues(props.data);
                setFormId(props.formId);
                setFormData(props.data.body);
                setDataActions(props.data.dataActions);
                setFormActions(props.data.formActions);
                setSavedValues(props.data.values || []);
                setOcrLists(props.data.ocrFieldList || []);
                setFormCenterCode(props.data.centerCode || '')
                setVisitInfoList(props.data.visitInfoList || []);

                if (props.isDct) {
                    setIsDct(props.isDct)
                }

                setDuplicateCheckField(getAllDuplicateCheckField(props.data.body))

                //本地暂存下数据
                let localStore_formData = sessionStorage.getItem('localStore_formData');

                if (localStore_formData) {
                    let formDataArr = JSON.parse(localStore_formData);
                    let formData = formDataArr.find((item: any) => {
                        return item.formId === props.formId
                    })
                    if (formData) {
                        formData.init = true;
                        formData.values = props.data.values;

                        if (props.data.ocrFieldList && props.data.ocrFieldList.length > 0) {
                            sessionStorage.setItem('localStore_formData', JSON.stringify(formDataArr))
                        }
                    }
                    else {
                        formDataArr.push({
                            formId: props.formId,
                            submit: false,
                            init: true,
                            values: props.data.values
                        })
                        sessionStorage.setItem('localStore_formData', JSON.stringify(formDataArr))
                    }
                }
                else {
                    let formDataArr = [];
                    formDataArr.push({
                        formId: props.formId,
                        submit: false,
                        init: true,
                        values: props.data.values
                    })
                    sessionStorage.setItem('localStore_formData', JSON.stringify(formDataArr))
                }

                let currentRole = sessionStorage.getItem('roleCode');
                let projectRoleCode = sessionStorage.getItem('projectRoleCode');
                let forceReadonly = sessionStorage.getItem('forceReadonly');

                // 君实项目特殊访视设置
                if (forceReadonly || projectRoleCode == 'ReadOnly' || currentRole == 'EDC_READ' 
                    || (currentRole !== 'CALL_CENTER' && props.data?.formCode === 'FMJSNSCLC01CC01')
                    || (currentRole === 'CALL_CENTER' && props.data?.formCode !== 'FMJSNSCLC01CC01')) {
                    setFormIsRead(false);
                } else {
                    if (props.data.formActions && props.data.dataActions) {
                        // 新编辑模式下提交后可修改
                        if (props.editNew !== 1 && props.editModel !== 1 && (props.data.formActions.includes('FormActionRead') || !props.data.dataActions.includes('DataActionInput')) ) {
                            setFormIsRead(false);
                        } else {
                            setFormIsRead(true);
                        }
                    } else {
                        setFormIsRead(true);
                    }
                }

            }
        }

    }, [props.data])

    useEffect(() => {
        if (props.data) {
            fillFeildVlaue(props.data.body, props.data.globalValues, props.data.values)
        }
    }, [props])

    const fillFeildVlaue = (data: any, globalValues: any, values: any) => {
        if (values && values.length > 0) {
            return
        }

        data.forEach((item: any) => {
            if (item.type == 'group' || item.type == 'list') {
                fillFeildVlaue(item.children, globalValues, values)
            } else {
                if (item.logicOperations) {
                    item.logicOperations.forEach((v: any) => {
                        if (v.type == "globalValue" && globalValues && globalValues.length > 0) {
                            let arr = globalValues.filter((c: any) => {
                                if (c.visitName == v.visit && c.formCode == v.form) {
                                    return !c.dataCode.indexOf(v.dataCode)
                                }
                            })              
                            if (arr.length > 0) {
                                let sValues: any = []
                                arr.forEach((c: any) => {
                                    if (c.dataCode.indexOf('#') !== -1) {
                                        let _itemValue = {
                                            dataCode: `${item.deCode}#${c.dataCode.split('#')[1]}`,
                                            deCode: item.deCode,
                                            value: c.value,
                                            type: item.type // 对于hide类型传值
                                        }

                                        let localStore_formData: any = sessionStorage.getItem('localStore_formData');
                                        if (localStore_formData) {
                                            let localStoreArr = JSON.parse(localStore_formData);
                                            let formData = localStoreArr.find((item: any) => {
                                                return item.formId === formId
                                            })
                                            if (formData) {
                                                let Index = formData.values.findIndex((v: any) => v.dataCode == _itemValue.dataCode)
                                                //判断字段内容是不是已填写
                                                if (Index == -1 && deleteListItemFlag) {
                                                    sValues.push(_itemValue)
                                                    let params = {
                                                        formId: formId,
                                                        submit: false,
                                                        values: [_itemValue]
                                                    }
                                                    formAction.saveData(params);
                                                }
                                            }
                                        }
                                    }
                                });
                                getCurrentValues()

                                setTimeout(() => {
                                    let _data = JSON.parse(JSON.stringify(props.data))
                                    _data.values = [...savedValues, ...sValues]
                                    tool.dealFieldList(_data)
                                    tool.dealFieldListByValues(_data)
                                    setFormData(_data.body);
                                }, 500);

                            }
                        }
                    })


                }
            }
        });
    }

    useImperativeHandle(ref, () => ({
        getSavedForm: () => {
            return {
                formId: formId,
                submit: true,
                values: savedValues,
                body: formData
            };
        },
    }));

    // 请求校验结果
    const debounceValidateResult = useMemo(()=> {
        return debounceCheckUnique(savedValues, duplicateCheckField, (content: string)=> {
            Modal.error({
                title: '提示',
                content
            })
        })
    }, [savedValues, duplicateCheckField])

    // 用于首次输入会将缓存中当表单的数据填充到字段
    const lockValue = useRef<SimpleValueItem | null>(null)
    //
    useEffect(()=> {
        if(savedValues.length > 0 && lockValue.current) {
            debounceValidateResult(lockValue.current)
            // 只用一次，用后清理
            lockValue.current = null
        }
    }, [savedValues])

    //更新当前保存的数据
    const getCurrentValues = (item?: SimpleValueItem) => {
        //获取本地缓存数据
        let localStore_formData = sessionStorage.getItem('localStore_formData');
        if (localStore_formData) {
            let localStoreArr = JSON.parse(localStore_formData);
            let formData = localStoreArr.find((item: any) => {
                return item.formId === formId
            })
            if (formData) {
                if(savedValues.length === 0 && item) {
                    lockValue.current = item
                }
                setSavedValues(formData.values);
            }
            else {
                setSavedValues([]);
            }
        }

        // 异步校验字段唯一性
        if(item && null === lockValue.current) {
            debounceValidateResult(item)
        }
    }

    //开启定时器
    const startTimer = () => {
        // props.startTimerFun();
    }
    
    //修改时更新数据
    const updateData = (item: SimpleValueItem) => {
        props.updateSelfData()
        getCurrentValues(item)
        // 更新当前数据后接口有请求时长
        // setTimeout(() => {
        //     getCurrentValues(item)
        // }, 1000);
        // setTimeout(() => {
        //     getCurrentValues(item)
        // }, 2000);
        // GetFormDataById({ id: formId }).then(res => {
        //     if (res.code === 0) {
        //         // setSavedValues(res.data.values);
        //         // setDataActions(res.data.dataActions);
        //         // setFormActions(res.data.formActions);
        //         props.updateSelfData()
        //     }
        // })
    }

    //验证是否为空
    const validationIsNull = (item: any) => {
        //检查是否必须选项
        if (item.validationRules && item.validationRules.length > 0) {
            let isNotNull = false;
            for (let rule of item.validationRules) {
                if (rule.condition === 'ne' && rule.rule === 'null') {
                    return (
                        <span style={{ color: 'red', paddingRight: '4px' }}>*</span>
                    )
                }
                else {
                    return null
                }
            }
        }
    }

    //处理group类型表单
    const handleGroup = (item: any) => {
        return (
            <div className="formItem">
                {item.label ? <div className="title">
                    {item.label}
                </div> : null}
                <div className="form_body">
                    <Row justify="start">
                        {
                            item.span && item.span.map((span: string | number | undefined, index: any) => {
                                return <Col span={ocrLists.length > 0 || isDct ? 24 : span} key={index}>
                                    {
                                        item.children.map((field: any, i: any) => {
                                            if (field.col) {
                                                if (field.col == index + 1) {
                                                    return (
                                                        <div className="field" key={i}>
                                                            {switchField(field, i)}
                                                        </div>
                                                    )
                                                }
                                                else if (field.col > item.span.length) {
                                                    return (
                                                        <div className="field" key={i}>
                                                            {switchField(field, i)}
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return null;
                                                }
                                            }
                                            else {
                                                if (index === 0) {
                                                    return (
                                                        <div className="field" key={i}>
                                                            {switchField(field, i)}
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return null;
                                                }
                                            }
                                        })
                                    }
                                </Col>
                            })
                        }
                    </Row>
                </div>
            </div>
        )
    }

    //当点击了添加list按钮
    const onClickAddList = (item: any) => {
        return () => {
            setFormData(tool.addList(JSON.parse(JSON.stringify(formData)), item.id));
        }
    }

    //判断是否加载更多
    const isAddMore = (item: any) => {

        let currentRole = sessionStorage.getItem('roleCode');
        let projectRoleCode = sessionStorage.getItem('projectRoleCode');
        if (projectRoleCode == 'ReadOnly' || currentRole == 'EDC_READ' 
            || (currentRole !== 'CALL_CENTER' && props.data?.formCode === 'FMJSNSCLC01CC01')
            || (currentRole === 'CALL_CENTER' && props.data?.formCode !== 'FMJSNSCLC01CC01')) {
            return ""
        }
        // 新编辑模式下，允许加载过多选项
        if (formActions && formActions.length > 0) {
            if (formActions.includes('FormActionSubmit') || props.editNew === 1 || props.editModel === 1) {
                return (
                    <div style={{ height: '40px', display: 'flex', alignItems: 'center', fontSize: '14px', lineHeight: '20px', cursor: 'pointer' }} onClick={onClickAddList(item)}>
                        <IconFont style={{ fontSize: '20px', marginRight: '4px' }} type="iconaddList"></IconFont>
                        点击添加更多
                    </div>
                )
            }
        }
    }

    //判断是否显示删除
    const isShowDelete = (i: any, item: any, list: any) => {
        let currentRole = sessionStorage.getItem('roleCode');
        let projectRoleCode = sessionStorage.getItem('projectRoleCode');
        if (projectRoleCode == 'ReadOnly' || currentRole == 'EDC_READ' 
            || (currentRole !== 'CALL_CENTER' && props.data?.formCode === 'FMJSNSCLC01CC01')
            || (currentRole === 'CALL_CENTER' && props.data?.formCode !== 'FMJSNSCLC01CC01')) {
            return ""
        }

        if (formActions && formActions.length > 0) {
            if (formActions.includes('FormActionSubmit') || props.editNew === 1 || props.editModel === 1) {
                if (item.addPosition && item.addPosition == 'top') {
                    return (
                        <div
                            onClick={() => deleteListItem(i, item, list)}
                            style={{ marginLeft: 15, cursor: 'pointer', color: '#ff4d4f', display: list.length == 1 ? 'none' : i == 0 ? '' : 'none' }}
                        >[ 删除本节点 ]</div>
                    )
                } else {

                    return (
                        <div
                            onClick={() => deleteListItem(i, item, list)}
                            style={{ marginLeft: 15, cursor: 'pointer', color: '#ff4d4f', display: i == 0 ? 'none' : (i + 1) !== list.length ? 'none' : '' }}
                        >[ 删除本节点 ]</div>
                    )
                }
            }
        }
    }


    const findAllValues = (data: any) => {
        let returnData: any = []
        if (data.children) {
            data.children.forEach((v: any) => {
                if (v.type == "group") {
                    let _data = findAllValues(v)
                    returnData = [...returnData, ..._data]
                } else {
                    returnData.push(v)
                }
            });
        }
        return returnData
    }

    //list题型删除某一项
    const deleteListItem = (index: any, item: any, list: any) => {
        deleteListItemFlag = false
        if (item.addPosition && item.addPosition == 'top') {
            index = list.length - 1 + globalHideCount
        }
        let { _data, _list } = tool.deleteListItem(JSON.parse(JSON.stringify(formData)), item.id, index)
        
        _list = findAllValues(_list)
        if (_list) {
            let _savedValues = savedValues
            _list.forEach((_item: any) => {
                let Index = _savedValues.findIndex((v: any) => v.dataCode == _item.dataCode)
                if (Index > -1) {
                    _savedValues.splice(Index, 1)
                }
            })
         
            let _deleteFormData: any = _list.map((v: any) => ({
                dataCode: v.dataCode,
                deCode: v.deCode,
            }))

            let params = {
                formId: props.formId,
                values: _deleteFormData
            }
            // 判断是否能够删除
            isDisableDelete(params).then((res: any) => {
                if(res.code == 0) {
                    if(res.data){
                        setFormData(_data);
                        setSavedValues(_savedValues)
                        deleteFormData(params).then((res: any) => { })
                    }else{
                        return  message.error("该纪录已审核通过，不可删除！")
                    }
                }
            })
         
            let localStore_formData = sessionStorage.getItem('localStore_formData');
            if (localStore_formData) {
                let formDataArr = JSON.parse(localStore_formData);
                let formData = formDataArr.find((item: any) => {
                    return item.formId === props.formId
                })
                if (formData) {
                    let _formDataValues = formData.values
                    _list.forEach((_item: any) => {
                        let Index = _formDataValues.findIndex((v: any) => v.dataCode == _item.dataCode)
                        if (Index > -1) {
                            _formDataValues.splice(Index, 1)
                        }
                    })

                    let _Index = formDataArr.findIndex((item: any) => item.formId === props.formId)
                    if (_Index > -1) {
                        formDataArr[_Index].values = _formDataValues
                    }
                    sessionStorage.setItem('localStore_formData', JSON.stringify(formDataArr));
                }
            }
        }
    }


    /**
     * 判断list子项是不是符合条件控制显示
     * @param item     
     * @returns 显示返回true 
     */
    const dealFildShow = (item: any) => {
        //isAdd代表是新增的list子项

        if (item.isAdd) {
            return 'true'
        }
        if (item.displays) {
            if (item.displays instanceof Array) {
                let judgeArr: any = [];
                item.displays.forEach((displayItem: any) => {
                    if (displayItem.type === 'ValueCascade') {
                        if (displayItem.condition === 'andEq') {
                            let childJudgeArr = [];
                            for (let i = 0; i < displayItem.target.length; i++) {
                                let findedItem = savedValues.find((valueItem: any) => {
                                    return valueItem.dataCode === displayItem.target[i]
                                });
                                if (findedItem) {
                                    if (findedItem.value instanceof Array) {
                                        if (findedItem.value.indexOf(displayItem.rule[i]) === -1) {
                                            childJudgeArr.push(false);
                                        }
                                        else {
                                            childJudgeArr.push(true);
                                        }
                                    }
                                    else {
                                        if (findedItem.value !== displayItem.rule[i]) {
                                            childJudgeArr.push(false);
                                        }
                                        else {
                                            childJudgeArr.push(true);
                                        }
                                    }
                                }
                                else {
                                    childJudgeArr.push(false)
                                }
                            }
                            if (childJudgeArr.every(item => {
                                if (item === true) {
                                    return true
                                }
                            })) {
                                judgeArr.push(true)
                            }
                        } else if (displayItem.condition === 'orEq') {
                            let childJudgeArr = [];
                            for (let i = 0; i < displayItem.target.length; i++) {
                                let findedItem = savedValues.find((valueItem: any) => {
                                    return valueItem.dataCode === item.display.target[i]
                                });
                                if (findedItem) {
                                    if (findedItem.value instanceof Array) {
                                        if (findedItem.value.indexOf(displayItem.rule[i]) === -1) {
                                            childJudgeArr.push(false);
                                        }
                                        else {
                                            childJudgeArr.push(true);
                                        }
                                    }
                                    else {
                                        if (findedItem.value !== displayItem.rule[i]) {
                                            childJudgeArr.push(false);
                                        }
                                        else {
                                            childJudgeArr.push(true);
                                        }
                                    }
                                }
                                else {
                                    childJudgeArr.push(false)
                                }
                            }
                            if (childJudgeArr.indexOf(true) !== -1) {
                                judgeArr.push(true)
                            }
                        } else {

                            let currentItem = savedValues.find((i: any) => {
                                return i.dataCode == displayItem.target
                            });
                            if (currentItem) {
                                if (displayItem.condition === 'eq') {
                                    if (currentItem.value && currentItem.value == displayItem.rule) {
                                        judgeArr.push(true)
                                    } else {
                                        judgeArr.push(false)
                                    }
                                } else if (displayItem.condition === 'lt') {
                                    if (currentItem.value && currentItem.value < displayItem.rule) {
                                        judgeArr.push(true)
                                    } else {
                                        judgeArr.push(false)
                                    }
                                } else if (displayItem.condition === 'lte') {
                                    if (currentItem.value && currentItem.value <= displayItem.rule) {
                                        judgeArr.push(true)
                                    } else {
                                        judgeArr.push(false)
                                    }
                                } else if (displayItem.condition === 'gt') {
                                    if (currentItem.value && currentItem.value > displayItem.rule) {
                                        judgeArr.push(true)
                                    } else {
                                        judgeArr.push(false)
                                    }
                                } else if (displayItem.condition === 'gte') {
                                    if (currentItem.value && currentItem.value >= displayItem.rule) {
                                        judgeArr.push(true)
                                    } else {
                                        judgeArr.push(false)
                                    }
                                }
                            } else {
                                judgeArr.push(false)
                            }

                        }
                    }
                })
                if (judgeArr.indexOf(true) !== -1) {
                    return 'true'
                }
            }
        } else if (item.hides) {
            if (item.hides instanceof Array) {
                let judgeArr: any = [];
                item.hides.forEach((displayItem: any) => {
                    if (displayItem.type === 'ValueCascade') {
                        let currentItem = savedValues.find((i: any) => {
                            return i.dataCode == displayItem.target
                        });
                        if (currentItem) {
                            if (displayItem.condition === 'eq') {
                                if (currentItem.value && currentItem.value == displayItem.rule) {
                                    judgeArr.push(true)
                                } else {
                                    judgeArr.push(false)
                                }
                            } else if (displayItem.condition === 'lt') {
                                if (currentItem.value && currentItem.value < displayItem.rule) {
                                    judgeArr.push(true)
                                } else {
                                    judgeArr.push(false)
                                }
                            } else if (displayItem.condition === 'lte') {
                                if (currentItem.value && currentItem.value <= displayItem.rule) {
                                    judgeArr.push(true)
                                } else {
                                    judgeArr.push(false)
                                }
                            } else if (displayItem.condition === 'gt') {
                                if (currentItem.value && currentItem.value > displayItem.rule) {
                                    judgeArr.push(true)
                                } else {
                                    judgeArr.push(false)
                                }
                            } else if (displayItem.condition === 'gte') {
                                if (currentItem.value && currentItem.value >= displayItem.rule) {
                                    judgeArr.push(true)
                                } else {
                                    judgeArr.push(false)
                                }
                            }
                        } else {
                            judgeArr.push(true)
                        }
                    }
                })
                if (judgeArr.indexOf(true) == -1) {
                    return 'true'
                }
            }

        } else if (item.display) {
            // ValueCascade
            if (item.display.type === 'ValueCascade') {
                // eq
                if (item.display.condition === 'eq') {
                    //当前选中表单已存的value
                    let currentItem = savedValues.find((i: any) => {
                        return i.dataCode == item.display.target
                    });
                    if (currentItem) {
                        if ((currentItem.value && currentItem.value.indexOf(item.display.rule) !== -1) || (currentItem.value && currentItem.value === item.display.rule)) {
                            return 'true'
                        }
                    }
                }
                else if (item.display.condition === 'andEq') {
                    let judgeArr = [];
                    for (let i = 0; i < item.display.target.length; i++) {
                        let findedItem = savedValues.find((valueItem: any) => {
                            return valueItem.dataCode === item.display.target[i]
                        });
                        if (findedItem) {
                            if (findedItem.value instanceof Array) {
                                if (findedItem.value.indexOf(item.display.rule[i]) === -1) {
                                    judgeArr.push(false);
                                }
                                else {
                                    judgeArr.push(true);
                                }
                            }
                            else {
                                if (findedItem.value !== item.display.rule[i]) {
                                    judgeArr.push(false);
                                }
                                else {
                                    judgeArr.push(true);
                                }
                            }
                        }
                        else {
                            judgeArr.push(false)
                        }
                    }
                    if (judgeArr.every(item => {
                        if (item === true) {
                            return true
                        }
                    })) {
                        return 'true'
                    }
                }
                else if (item.display.condition === 'orEq') {
                    let judgeArr = [];
                    for (let i = 0; i < item.display.target.length; i++) {
                        let findedItem = savedValues.find((valueItem: any) => {
                            return valueItem.dataCode === item.display.target[i]
                        });
                        if (findedItem) {
                            if (findedItem.value instanceof Array) {
                                if (findedItem.value.indexOf(item.display.rule[i]) === -1) {
                                    judgeArr.push(false);
                                }
                                else {
                                    judgeArr.push(true);
                                }
                            }
                            else {
                                if (findedItem.value !== item.display.rule[i]) {
                                    judgeArr.push(false);
                                }
                                else {
                                    judgeArr.push(true);
                                }
                            }
                        }
                        else {
                            judgeArr.push(false)
                        }
                    }
                    if (judgeArr.indexOf(true) !== -1) {
                        return 'true'
                    }
                }

            } else if (item.display.type === 'RemindsCascade') {
                if (item.display.condition === 'alarm') {
                    let judgeArr: any = [];
                    item.display.rule.forEach((v: any) => {
                        if (v.site) {
                            if (v.site == formCenterCode) {
                                let currentItem = savedValues.find((i: any) => {
                                    return i.dataCode == item.display.target
                                })
                                if (currentItem && currentItem.value) {
                                    judgeArr = verify.checkRemindValueByRange(v.condition, v.rule, currentItem.value)
                                }

                            } else if (v.site == '*') {
                                let currentItem = savedValues.find((i: any) => {
                                    return i.dataCode == item.display.target
                                })
                                if (currentItem && currentItem.value) {
                                    judgeArr = verify.checkRemindValueByRange(v.condition, v.rule, currentItem.value)
                                }

                            }
                        } else {
                            let currentItem = savedValues.find((i: any) => {
                                return i.dataCode == item.display.target
                            })
                            if (currentItem && currentItem.value) {
                                judgeArr = verify.checkRemindValueByRange(v.condition, v.rule, currentItem.value)
                            }
                        }
                    })

                    if (judgeArr.indexOf(true) !== -1) {
                        return 'true'
                    }
                }
            } else if (item.display.type === 'eqOuter') {
                if (item.display.condition === 'eq') {
                    let outerTarget = item.display.target
                    if (outerTarget.indexOf('#') !== -1) {
                        outerTarget = outerTarget.split('#')[0]
                    }
                    //当前选中表单已存的value
                    let currentItem = savedValues.find((i: any) => {
                        return i.dataCode == outerTarget
                    });
                    if (currentItem) {
                        if ((currentItem.value && currentItem.value.indexOf(item.display.rule) !== -1) || (currentItem.value && currentItem.value === item.display.rule)) {
                            return 'true'
                        }
                    }
                }
            }
        } else {
            if (item.type != 'hide') {
                return 'true'
            }
        }
    }

    let itemShowList: any = []

    const dealListItemShow = (data: any) => {
        if (data.children) {
            data.children.forEach((item: any) => {
                dealListItemShow(item)
            });
        } else {
            let reult = dealFildShow(data)
            if (reult) {
                itemShowList.push('true')
            }
        }
        return itemShowList

    }


    //当前list隐藏的子项  删除的时候要加上这个
    let globalHideCount = 0

    //处理list类表单
    const handleList = (item: any, index: any) => {
        let data = []
        if (item.children) {
            data = JSON.parse(JSON.stringify(item.children))
            if (item.addPosition && item.addPosition == 'top') {
                data = data.reverse()
            }
        }

        let deepData: any = []
        let hideCount = 0
        data.forEach((itemData: any) => {
            dealIsAddFlag(itemData);
            if (isAddFlag) {
                itemData = handleIsAdd(itemData)
            }

            let list = dealListItemShow(itemData)

            if (list && list.length > 0) {
                itemShowList = []
                deepData.push(itemData)
            } else {
                hideCount = hideCount + 1
            }

            isAddFlag = false
        });
        globalHideCount = hideCount

        return (
            <React.Fragment>
                {item.label ? <div className="title">
                    &ensp;{item.label}
                </div> : null}
                {
                    item.addPosition && item.addPosition == 'top' && isAddMore(item)
                }
                <div className={`list#${index}`}>
                    {
                        deepData.map((field: any, i: any) => {
                            return (
                                <div style={{ width: '100%' }} key={i}>
                                    <div style={{ width: '100%', display: 'flex', alignItems: 'baseline' }}>

                                        {
                                            item.children.length >= 1 ?
                                                <Badge offset={[0, 10]} style={{ backgroundColor: '#6287EC', color: '#ffffff' }} count={i + 1} overflowCount={item.maxNumber}>
                                                </Badge>
                                                : null
                                        }
                                        {
                                            isShowDelete(i, item, deepData)
                                        }
                                    </div>
                                    {switchField(field)}
                                </div>
                            )
                        })
                    }
                </div>
                {
                    item.addPosition !== 'top' && isAddMore(item)
                }
            </React.Fragment>
        )
    }


    let isAddFlag = false

    /**判断是不是当前表单新增的List子项 */
    const dealIsAddFlag = (data: any) => {
        if (data.children) {
            data.children.forEach((item: any) => {
                dealIsAddFlag(item)
            });
        } else {
            let currentItem = savedValues.find((i: any) => {
                return i.dataCode == data.dataCode
            });
            if (currentItem && currentItem.extendAttribute && currentItem.extendAttribute.includes('isAdd=true')) {
                isAddFlag = true
            }
        }
    }

    /**给所有子项都加上isAdd */
    const handleIsAdd = (data: any) => {
        if (data.children) {
            data.children.forEach((item: any) => {
                handleIsAdd(item)
            });
        } else {
            data.isAdd = true
        }
        return data
    }


    // 当鼠标移动到表单上
    const onMouseOverField = (id: any, item?: any) => {

        let ele = document.getElementById(`ActionView#${id}`);
        if (ele) {
            ele.style.visibility = "visible";
            
        }
        if (item.background) {
            return false
        }
        let formComEle = document.getElementById(`formCom#${id}`);
        if (formComEle) {
            formComEle.style.backgroundColor = "#ebf1f7";
        }
    }

    // 当鼠标移出
    const onMouseOutField = (id: any, item?: any) => {
        let backgroundColor = item.background;
        let ele = document.getElementById(`ActionView#${id}`);
        if (ele) {
            ele.style.visibility = "hidden";
        }
        let formComEle = document.getElementById(`formCom#${id}`);
        if (formComEle) {
            if (backgroundColor) {
                formComEle.style.backgroundColor = backgroundColor;
            } else {
                formComEle.style.backgroundColor = "#fbfbfb";
            }
        }
    }

    // 获取划词icon信息
    const getOcrIcon = (val: any) => {
        PubSub.publish('ocrData', val);
    }
    
    //各类表单
    const formField = (item: any, index: any) => {
        const type = item.type;
        // label位置
        let labelPosition = item.labelPosition ? item.labelPosition : "left";
        // label宽度
        let labelWidth = item.labelWidth ? item.labelWidth : "120px";

        if (labelPosition === "top") {
            switch (type) {
                case 'map':
                    return (
                        <>
                            <div  id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)}  className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                                <div className="label">
                                    {validationIsNull(item)}
                                    <div className="enterocr-wrap">
                                        <span>{item.label}</span>

                                        <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                            {
                                                <ActionView fromCode={props.data?.formCode}  formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer} tenantCode={props.tenantCode}  ></ActionView>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form_field">
                                    <InputCom
                                        formId={formId}
                                        formCenterCode={formCenterCode}
                                        formIsRead={formIsRead}
                                        savedValues={savedValues}
                                        dataActions={dataActions}
                                        propsData={props.data}
                                        data={item}
                                        updateFun={getCurrentValues}
                                        modifyUpdateFun={updateData}
                                        startTimerFun={startTimer}
                                        triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                        cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                        deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                        ocrLists={ocrLists}
                                        getOcrIcon={getOcrIcon}
                                    ></InputCom>
                                </div>
                                <>{renderQuestion(item)}</>
                            </div>
                            <div style={{ "color": 'red' }}>* 提示：请在小程序完成该项填写和修改</div>
                        </>
                    )
                //选择
                case 'select':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>
                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer} tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <SelectCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer} ></SelectCom>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                case 'region-select':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>
                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer} tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <RegionSelectCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues}/>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //只读文本
                case 'text':
                    return (
                        <div className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div>
                                    <span>{item.label}</span>
                                </div>
                            </div>
                            <div className="form_field">
                                <TextCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></TextCom>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //输入框
                case 'input':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>

                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <InputCom
                                    propsData={props.data}
                                    formId={formId}
                                    formCenterCode={formCenterCode}
                                    formIsRead={formIsRead}
                                    savedValues={savedValues}
                                    dataActions={dataActions}
                                    data={item}
                                    updateFun={getCurrentValues}
                                    modifyUpdateFun={updateData}
                                    startTimerFun={startTimer}
                                    triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                    cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                    deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                    ocrLists={ocrLists}
                                    getOcrIcon={getOcrIcon}
                                ></InputCom>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //输入框
                case 'number':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>

                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <NumberCom
                                    formId={formId}
                                    formCenterCode={formCenterCode}
                                    formIsRead={formIsRead}
                                    savedValues={savedValues}
                                    dataActions={dataActions}
                                    data={item}
                                    updateFun={getCurrentValues}
                                    modifyUpdateFun={updateData}
                                    startTimerFun={startTimer}
                                    triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                    cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                    deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                    ocrLists={ocrLists}
                                    getOcrIcon={getOcrIcon}
                                ></NumberCom>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                case 'unknownDate':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>

                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <UnknownDateCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}  globalValues={props.data?.globalValues}></UnknownDateCom>
                                {/* <DateCom type={type} formId={formId} formIsRead={formIsRead} savedValues={savedValues} visitInfoList={visitInfoList} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer} ></DateCom> */}
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //时间表单
                case 'date':
                case 'datetime':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>

                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <DateCom type={type} formId={formId} formIsRead={formIsRead} savedValues={savedValues} visitInfoList={visitInfoList} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer} ></DateCom>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //文本域
                case 'textarea':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>

                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <TextareaCom
                                    formId={formId}
                                    formIsRead={formIsRead}
                                    savedValues={savedValues}
                                    dataActions={dataActions}
                                    textAreaMaxLen={props.textAreaMaxLen}
                                    data={item}
                                    updateFun={getCurrentValues}
                                    modifyUpdateFun={updateData}
                                    startTimerFun={startTimer}
                                    triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                    cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                    deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                    ocrLists={ocrLists}
                                    getOcrIcon={getOcrIcon}
                                ></TextareaCom>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //photo
                case 'photo':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>

                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <PhotoCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer} ></PhotoCom>

                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //photo-oss
                case 'photo-oss':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleThree" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <PhotoOssCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></PhotoOssCom>

                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                // ocr-autofill
                case 'ocr-autofill':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>

                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView  autoOcr={props.autoOcr} fromCode={props.data?.formCode}   formConfig={formData} formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <OCRAutofillCom autoOcr={props.autoOcr} formConfig={formData} formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues}></OCRAutofillCom>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )

                //camera
                case 'camera':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleThree" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <CameraCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></CameraCom>

                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                case 'file':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleThree" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <FileCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></FileCom>

                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //video
                case 'video':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>

                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <VideoCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer} ></VideoCom>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //checkbox
                case 'checkbox':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>

                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <CheckboxCom
                                    formId={formId}
                                    formIsRead={formIsRead}
                                    savedValues={savedValues}
                                    dataActions={dataActions}
                                    data={item}
                                    updateFun={getCurrentValues}
                                    modifyUpdateFun={updateData}
                                    startTimerFun={startTimer}
                                    ocrLists={ocrLists}
                                    triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                    cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                    deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                ></CheckboxCom>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //input-select
                case 'input-select':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>

                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <InputSelectCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer} ></InputSelectCom>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //decimal
                case 'decimal':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                {validationIsNull(item)}
                                <div className="enterocr-wrap">
                                    <span>{item.label}</span>

                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_field">
                                <DecimalCom
                                    formId={formId}
                                    formCenterCode={formCenterCode}
                                    formIsRead={formIsRead}
                                    savedValues={savedValues}
                                    dataActions={dataActions}
                                    propsData={props.data}
                                    data={item}
                                    updateFun={getCurrentValues}
                                    modifyUpdateFun={updateData}
                                    startTimerFun={startTimer}
                                    triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                    cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                    deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                    ocrLists={ocrLists}
                                    getOcrIcon={getOcrIcon}
                                ></DecimalCom>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //hide
                case 'hide':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="form_field">
                                <HideCom
                                    formId={formId}
                                    formCenterCode={formCenterCode}
                                    savedValues={savedValues}
                                    propsData={props.data}
                                    data={item}
                                    updateFun={getCurrentValues}
                                    modifyUpdateFun={updateData}
                                ></HideCom>
                            </div>
                        </div>
                    )
                //多个表单组
                case 'group':
                    return (
                        handleGroup(item)
                    )
                //list类型
                case 'list':
                    return (
                        handleList(item, index)
                    )
                default:
                    break;
            }
        }
        else if (labelPosition === "left") {
            switch (type) {
                case 'map':
                    return (
                        <>
                            <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                                <div className="label">
                                    <div style={{ width: labelWidth }}>
                                        {validationIsNull(item)}
                                        {item.label ? `${item.label}:` : null}
                                    </div>
                                </div>
                                <div className="form_field">
                                    <div style={{ width: `calc(100% - 80px)` }}>
                                        <InputCom
                                            propsData={props.data}
                                            formId={formId}
                                            formCenterCode={formCenterCode}
                                            formIsRead={false}
                                            savedValues={savedValues}
                                            dataActions={dataActions}
                                            data={item}
                                            updateFun={getCurrentValues}
                                            modifyUpdateFun={updateData}
                                            startTimerFun={startTimer}
                                            triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                            cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                            deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                            ocrLists={ocrLists}
                                            getOcrIcon={getOcrIcon}
                                        ></InputCom>
                                    </div>
                                    <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                        {
                                            <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                        }
                                    </div>
                                </div>
                                <>{renderQuestion(item)}</>

                            </div>
                            <div style={{ "color": 'red' }}>* 提示：请在小程序完成该项填写和修改</div>
                        </>
                    )
                //选择
                case 'select':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} style={{ backgroundColor: item.background ? item.background : '' }} className="formCom" onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <SelectCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></SelectCom>
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                case 'region-select':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} style={{ backgroundColor: item.background ? item.background : '' }} className="formCom" onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <RegionSelectCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues}/>
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //只读文本
                case 'text':
                    return (
                        <div className="formCom" style={{ backgroundColor: item.background ? item.background : '' }}>
                            <div className="label">
                                <div style={{ width: labelWidth, textAlign: 'left' }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <TextCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></TextCom>
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //输入框
                case 'input':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <InputCom
                                        propsData={props.data}
                                        formId={formId}
                                        formCenterCode={formCenterCode}
                                        formIsRead={formIsRead}
                                        savedValues={savedValues}
                                        dataActions={dataActions}
                                        data={item}
                                        updateFun={getCurrentValues}
                                        modifyUpdateFun={updateData}
                                        startTimerFun={startTimer}
                                        triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                        cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                        deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                        ocrLists={ocrLists}
                                        getOcrIcon={getOcrIcon}
                                    ></InputCom>
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //输入框
                case 'number':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <NumberCom
                                        formId={formId}
                                        formCenterCode={formCenterCode}
                                        formIsRead={formIsRead}
                                        savedValues={savedValues}
                                        dataActions={dataActions}
                                        data={item}
                                        updateFun={getCurrentValues}
                                        modifyUpdateFun={updateData}
                                        startTimerFun={startTimer}
                                        triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                        cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                        deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                        ocrLists={ocrLists}
                                        getOcrIcon={getOcrIcon}
                                    ></NumberCom>
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                case 'unknownDate':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <UnknownDateCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer} globalValues={props.data?.globalValues}></UnknownDateCom>
                                    {/* <DateCom type={type} formId={formId} formIsRead={formIsRead} savedValues={savedValues} visitInfoList={visitInfoList} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></DateCom> */}
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //时间表单
                case 'date':
                case 'datetime':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <DateCom type={type} formId={formId} formIsRead={formIsRead} savedValues={savedValues} visitInfoList={visitInfoList} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></DateCom>
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //文本域
                case 'textarea':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <TextareaCom
                                        formId={formId}
                                        formIsRead={formIsRead}
                                        savedValues={savedValues}
                                        dataActions={dataActions}
                                        textAreaMaxLen={props.textAreaMaxLen}
                                        data={item}
                                        updateFun={getCurrentValues}
                                        modifyUpdateFun={updateData}
                                        startTimerFun={startTimer}
                                        triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                        cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                        deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                        ocrLists={ocrLists}
                                        getOcrIcon={getOcrIcon}
                                    ></TextareaCom>
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //photo
                case 'photo':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <PhotoCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></PhotoCom>

                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //photo-oss
                case 'photo-oss':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <PhotoOssCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></PhotoOssCom>

                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                case 'ocr-autofill':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <OCRAutofillCom autoOcr={props.autoOcr} formConfig={formData} formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues}></OCRAutofillCom>
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView  autoOcr={props.autoOcr}  fromCode={props.data?.formCode}   formConfig={formData} formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                // 只支持拍照
                case 'camera':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <CameraCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></CameraCom>

                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                case 'file':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <FileCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></FileCom>

                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //video
                case 'video':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <VideoCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></VideoCom>
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //checkbox
                case 'checkbox':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <CheckboxCom
                                        formId={formId}
                                        formIsRead={formIsRead}
                                        savedValues={savedValues}
                                        dataActions={dataActions}
                                        data={item}
                                        updateFun={getCurrentValues}
                                        modifyUpdateFun={updateData}
                                        startTimerFun={startTimer}
                                        ocrLists={ocrLists}
                                        triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                        cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                        deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                    ></CheckboxCom>
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //input-select
                case 'input-select':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <InputSelectCom formId={formId} formIsRead={formIsRead} savedValues={savedValues} dataActions={dataActions} data={item} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></InputSelectCom>
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //decimal
                case 'decimal':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <DecimalCom
                                        ocrLists={ocrLists}
                                        formId={formId}
                                        formCenterCode={formCenterCode}
                                        formIsRead={formIsRead}
                                        savedValues={savedValues}
                                        propsData={props.data}
                                        dataActions={dataActions} data={item}
                                        updateFun={getCurrentValues}
                                        modifyUpdateFun={updateData}
                                        startTimerFun={startTimer}
                                        triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                        cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                        deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                        getOcrIcon={getOcrIcon}
                                    />
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView fromCode={props.data?.formCode}   formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}tenantCode={props.tenantCode} ></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //hide
                case 'hide':
                    return (
                        <div id={`formCom#${item.dataCode}`}   onClick={()=>showMark(item)} className="formCom_styleTwo" hidden={true}>
                            <div className="form_field">
                                <HideCom
                                    formId={formId}
                                    formCenterCode={formCenterCode}
                                    savedValues={savedValues}
                                    dataActions={dataActions}
                                    propsData={props.data}
                                    data={item}
                                    updateFun={getCurrentValues}
                                    modifyUpdateFun={updateData}
                                ></HideCom>
                            </div>
                        </div>
                    )
                // 支持远程搜索
                case 'autocomplete':
                    return (
                        <div id={`formCom#${item.dataCode}`} className="formCom" style={{ backgroundColor: item.background ? item.background : '' }} onMouseOver={() => { onMouseOverField(item.dataCode, item) }} onMouseOut={() => { onMouseOutField(item.dataCode, item) }}>
                            <div className="label">
                                <div style={{ width: labelWidth }}>
                                    {validationIsNull(item)}
                                    {item.label ? `${item.label}:` : null}
                                </div>
                            </div>
                            <div className="form_field">
                                <div style={{ width: `calc(100% - 80px)` }}>
                                    <AutocompleteCom
                                        formId={formId}
                                        formCenterCode={formCenterCode}
                                        formIsRead={formIsRead}
                                        savedValues={savedValues}
                                        dataActions={dataActions}
                                        data={item}
                                        globalValues={props.data.globalValues}
                                        updateFun={getCurrentValues}
                                        modifyUpdateFun={updateData}
                                        startTimerFun={startTimer}
                                        triggerDrawAreaFunc={props.triggerDrawAreaFunc}
                                        cancelDrawAreaFunc={props.cancelDrawAreaFunc}
                                        deleteDrawAreaFunc={props.deleteDrawAreaFunc}
                                        ocrLists={ocrLists}
                                        getOcrIcon={getOcrIcon}
                                    />
                                </div>
                                <div className="ActionView_content" id={`ActionView#${item.dataCode}`}>
                                    {
                                        <ActionView formId={formId} data={item} dataActions={dataActions} formActions={formActions} savedValues={savedValues} updateFun={getCurrentValues} modifyUpdateFun={updateData} startTimerFun={startTimer}></ActionView>
                                    }
                                </div>
                            </div>
                            <>{renderQuestion(item)}</>
                        </div>
                    )
                //输入框
                //多个表单组
                case 'group':
                    return (
                        handleGroup(item)
                    )
                //list类型
                case 'list':
                    return (
                        handleList(item, index)
                    )
                default:
                    break;
            }
        }
    }



    //根据表单类型选择表单域
    const switchField = (item: any, index?: any) => {
        //isAdd 代表是新增的list子项
        //isAdd为true走display，不走hides、displays。isAdd为false或者非list的，有配置hides、displays其中一个的，不走display
        if (!item) {
            return
        }
        if (item.isAdd) {
            if (item.display) {
                // ValueCascade
                if (item.display.type === 'ValueCascade') {
                    // eq
                    if (item.display.condition === 'eq') {
                        //当前选中表单已存的value
                        let currentItem = savedValues.find((i: any) => {
                            return i.dataCode == item.display.target
                        });
                        if (currentItem) {
                            if ((currentItem.value && currentItem.value.indexOf(item.display.rule) !== -1) || (currentItem.value && currentItem.value === item.display.rule)) {
                                return formField(item, index)
                            }
                        }
                    }
                    else if (item.display.condition === 'andEq') {
                        let judgeArr = [];
                        for (let i = 0; i < item.display.target.length; i++) {
                            let findedItem = savedValues.find((valueItem: any) => {
                                return valueItem.dataCode === item.display.target[i]
                            });
                            if (findedItem) {
                                if (findedItem.value instanceof Array) {
                                    if (findedItem.value.indexOf(item.display.rule[i]) === -1) {
                                        judgeArr.push(false);
                                    }
                                    else {
                                        judgeArr.push(true);
                                    }
                                }
                                else {
                                    if (findedItem.value !== item.display.rule[i]) {
                                        judgeArr.push(false);
                                    }
                                    else {
                                        judgeArr.push(true);
                                    }
                                }
                            }
                            else {
                                judgeArr.push(false)
                            }
                        }
                        if (judgeArr.every(item => {
                            if (item === true) {
                                return true
                            }
                        })) {
                            return formField(item, index)
                        }
                    }
                    else if (item.display.condition === 'orEq') {
                        let judgeArr = [];
                        for (let i = 0; i < item.display.target.length; i++) {
                            let findedItem = savedValues.find((valueItem: any) => {
                                return valueItem.dataCode === item.display.target[i]
                            });
                            if (findedItem) {
                                if (findedItem.value instanceof Array) {
                                    if (findedItem.value.indexOf(item.display.rule[i]) === -1) {
                                        judgeArr.push(false);
                                    }
                                    else {
                                        judgeArr.push(true);
                                    }
                                }
                                else {
                                    if (findedItem.value !== item.display.rule[i]) {
                                        judgeArr.push(false);
                                    }
                                    else {
                                        judgeArr.push(true);
                                    }
                                }
                            }
                            else {
                                judgeArr.push(false)
                            }
                        }
                        if (judgeArr.indexOf(true) !== -1) {
                            return formField(item, index)
                        }
                    }

                } else if (item.display.type === 'RemindsCascade') {
                    if (item.display.condition === 'alarm') {
                        let judgeArr: any = [];
                        item.display.rule.forEach((v: any) => {
                            if (v.site) {
                                if (v.site == formCenterCode) {
                                    let currentItem = savedValues.find((i: any) => {
                                        return i.dataCode == item.display.target
                                    })
                                    if (currentItem && currentItem.value) {
                                        judgeArr = verify.checkRemindValueByRange(v.condition, v.rule, currentItem.value)
                                    }

                                } else if (v.site == '*') {
                                    let currentItem = savedValues.find((i: any) => {
                                        return i.dataCode == item.display.target
                                    })
                                    if (currentItem && currentItem.value) {
                                        judgeArr = verify.checkRemindValueByRange(v.condition, v.rule, currentItem.value)
                                    }

                                }
                            } else {
                                let currentItem = savedValues.find((i: any) => {
                                    return i.dataCode == item.display.target
                                })
                                if (currentItem && currentItem.value) {
                                    judgeArr = verify.checkRemindValueByRange(v.condition, v.rule, currentItem.value)
                                }
                            }
                        })

                        if (judgeArr.indexOf(true) !== -1) {
                            return formField(item, index)
                        }
                    }
                } else if (item.display.type === 'eqOuter') {
                    if (item.display.condition === 'eq') {
                        let outerTarget = item.display.target
                        if (outerTarget.indexOf('#') !== -1) {
                            outerTarget = outerTarget.split('#')[0]
                        }
                        //当前选中表单已存的value
                        let currentItem = savedValues.find((i: any) => {
                            return i.dataCode == outerTarget
                        });
                        if (currentItem) {
                            if ((currentItem.value && currentItem.value.indexOf(item.display.rule) !== -1) || (currentItem.value && currentItem.value === item.display.rule)) {
                                return formField(item, index)
                            }
                        }
                    }
                }
            } else {
                return formField(item, index)
            }
        } else {
            if (item.displays) {
                if (item.displays instanceof Array) {
                    let judgeArr: any = [];
                    item.displays.forEach((displayItem: any) => {

                        if (displayItem.type === 'ValueCascade') {

                            if (displayItem.condition === 'andEq') {
                                let childJudgeArr = [];
                                for (let i = 0; i < displayItem.target.length; i++) {
                                    let findedItem = savedValues.find((valueItem: any) => {
                                        return valueItem.dataCode === displayItem.target[i]
                                    });
                                    if (findedItem) {
                                        if (findedItem.value instanceof Array) {
                                            if (findedItem.value.indexOf(displayItem.rule[i]) === -1) {
                                                childJudgeArr.push(false);
                                            }
                                            else {
                                                childJudgeArr.push(true);
                                            }
                                        }
                                        else {
                                            if (findedItem.value !== displayItem.rule[i]) {
                                                childJudgeArr.push(false);
                                            }
                                            else {
                                                childJudgeArr.push(true);
                                            }
                                        }
                                    }
                                    else {
                                        childJudgeArr.push(false)
                                    }
                                }
                                if (childJudgeArr.every(item => {
                                    if (item === true) {
                                        return true
                                    }
                                })) {
                                    judgeArr.push(true)
                                }
                            } else if (displayItem.condition === 'orEq') {
                                let childJudgeArr = [];
                                for (let i = 0; i < displayItem.target.length; i++) {
                                    let findedItem = savedValues.find((valueItem: any) => {
                                        return valueItem.dataCode === item.display.target[i]
                                    });
                                    if (findedItem) {
                                        if (findedItem.value instanceof Array) {
                                            if (findedItem.value.indexOf(displayItem.rule[i]) === -1) {
                                                childJudgeArr.push(false);
                                            }
                                            else {
                                                childJudgeArr.push(true);
                                            }
                                        }
                                        else {
                                            if (findedItem.value !== displayItem.rule[i]) {
                                                childJudgeArr.push(false);
                                            }
                                            else {
                                                childJudgeArr.push(true);
                                            }
                                        }
                                    }
                                    else {
                                        childJudgeArr.push(false)
                                    }
                                }
                                if (childJudgeArr.indexOf(true) !== -1) {
                                    judgeArr.push(false)
                                }
                            } else {
                                let currentItem = savedValues.find((i: any) => {
                                    return i.dataCode == displayItem.target
                                });
                                if (currentItem) {
                                    if (displayItem.condition === 'eq') {
                                        if (currentItem.value && currentItem.value == displayItem.rule) {
                                            judgeArr.push(true)
                                        } else {
                                            judgeArr.push(false)
                                        }
                                    } else if (displayItem.condition === 'lt') {
                                        if (currentItem.value && currentItem.value < displayItem.rule) {
                                            judgeArr.push(true)
                                        } else {
                                            judgeArr.push(false)
                                        }
                                    } else if (displayItem.condition === 'lte') {
                                        if (currentItem.value && currentItem.value <= displayItem.rule) {
                                            judgeArr.push(true)
                                        } else {
                                            judgeArr.push(false)
                                        }
                                    } else if (displayItem.condition === 'gt') {
                                        if (currentItem.value && currentItem.value > displayItem.rule) {
                                            judgeArr.push(true)
                                        } else {
                                            judgeArr.push(false)
                                        }
                                    } else if (displayItem.condition === 'gte') {
                                        if (currentItem.value && currentItem.value >= displayItem.rule) {
                                            judgeArr.push(true)
                                        } else {
                                            judgeArr.push(false)
                                        }
                                    }
                                } else {
                                    judgeArr.push(false)
                                }
                            }

                        }
                    })
                    if (judgeArr.indexOf(true) !== -1) {
                        return formField(item, index)
                    }
                }
            } else if (item.hides) {
                if (item.hides instanceof Array) {
                    let judgeArr: any = [];
                    item.hides.forEach((displayItem: any) => {
                        if (displayItem.type === 'ValueCascade') {

                            if (displayItem.condition === 'andEq') {
                                let childJudgeArr = [];
                                for (let i = 0; i < displayItem.target.length; i++) {
                                    let findedItem = savedValues.find((valueItem: any) => {
                                        return valueItem.dataCode === displayItem.target[i]
                                    });
                                    if (findedItem) {
                                        if (findedItem.value instanceof Array) {
                                            if (findedItem.value.indexOf(displayItem.rule[i]) === -1) {
                                                childJudgeArr.push(false);
                                            }
                                            else {
                                                childJudgeArr.push(true);
                                            }
                                        }
                                        else {
                                            if (findedItem.value !== displayItem.rule[i]) {
                                                childJudgeArr.push(false);
                                            }
                                            else {
                                                childJudgeArr.push(true);
                                            }
                                        }
                                    }
                                    else {
                                        childJudgeArr.push(false)
                                    }
                                }
                                if (childJudgeArr.every(item => {
                                    if (item === true) {
                                        return true
                                    }
                                })) {
                                    judgeArr.push(true)
                                }
                            } else if (displayItem.condition === 'orEq') {
                                let childJudgeArr = [];
                                for (let i = 0; i < displayItem.target.length; i++) {
                                    let findedItem = savedValues.find((valueItem: any) => {
                                        return valueItem.dataCode === item.display.target[i]
                                    });
                                    if (findedItem) {
                                        if (findedItem.value instanceof Array) {
                                            if (findedItem.value.indexOf(displayItem.rule[i]) === -1) {
                                                childJudgeArr.push(false);
                                            }
                                            else {
                                                childJudgeArr.push(true);
                                            }
                                        }
                                        else {
                                            if (findedItem.value !== displayItem.rule[i]) {
                                                childJudgeArr.push(false);
                                            }
                                            else {
                                                childJudgeArr.push(true);
                                            }
                                        }
                                    }
                                    else {
                                        childJudgeArr.push(false)
                                    }
                                }
                                if (childJudgeArr.indexOf(true) !== -1) {
                                    judgeArr.push(false)
                                }
                            } else {
                    
                                let currentItem = savedValues.find((i: any) => {
                                    return i.dataCode == displayItem.target
                                });
                                if (currentItem) {
                                    if (displayItem.condition === 'eq') {
                                        if (currentItem.value && currentItem.value == displayItem.rule) {
                                            judgeArr.push(true)
                                        } else {
                                            judgeArr.push(false)
                                        }
                                    } else if (displayItem.condition === 'lt') {
                                        if (currentItem.value && currentItem.value < displayItem.rule) {
                                            judgeArr.push(true)
                                        } else {
                                            judgeArr.push(false)
                                        }
                                    } else if (displayItem.condition === 'lte') {
                                        if (currentItem.value && currentItem.value <= displayItem.rule) {
                                            judgeArr.push(true)
                                        } else {
                                            judgeArr.push(false)
                                        }
                                    } else if (displayItem.condition === 'gt') {
                                        if (currentItem.value && currentItem.value > displayItem.rule) {
                                            judgeArr.push(true)
                                        } else {
                                            judgeArr.push(false)
                                        }
                                    } else if (displayItem.condition === 'gte') {
                                        if (currentItem.value && currentItem.value >= displayItem.rule) {
                                            judgeArr.push(true)
                                        } else {
                                            judgeArr.push(false)
                                        }
                                    }
                                } else {
                                    judgeArr.push(true)
                                }

                            }
                        }
                    })
                    if (judgeArr.indexOf(true) == -1) {
                        return formField(item, index)
                    }
                }


            } else if (item.display) {
                // ValueCascade
                if (item.display.type === 'ValueCascade') {
                    // eq
                    if (item.display.condition === 'eq') {
                        //当前选中表单已存的value
                        let currentItem = savedValues.find((i: any) => {
                            return i.dataCode == item.display.target
                        });
                        if (currentItem) {
                            if ((currentItem.value && currentItem.value.indexOf(item.display.rule) !== -1) || (currentItem.value && currentItem.value === item.display.rule)) {
                                return formField(item, index)
                            }
                        }
                    }
                    else if (item.display.condition === 'andEq') {
                        let judgeArr = [];
                        for (let i = 0; i < item.display.target.length; i++) {
                            let findedItem = savedValues.find((valueItem: any) => {
                                return valueItem.dataCode === item.display.target[i]
                            });
                            if (findedItem) {
                                if (findedItem.value instanceof Array) {
                                    if (findedItem.value.indexOf(item.display.rule[i]) === -1) {
                                        judgeArr.push(false);
                                    }
                                    else {
                                        judgeArr.push(true);
                                    }
                                }
                                else {
                                    if (findedItem.value !== item.display.rule[i]) {
                                        judgeArr.push(false);
                                    }
                                    else {
                                        judgeArr.push(true);
                                    }
                                }
                            }
                            else {
                                judgeArr.push(false)
                            }
                        }
                        if (judgeArr.every(item => {
                            if (item === true) {
                                return true
                            }
                        })) {
                            return formField(item, index)
                        }
                    }
                    else if (item.display.condition === 'orEq') {
                        let judgeArr = [];
                        for (let i = 0; i < item.display.target.length; i++) {
                            let findedItem = savedValues.find((valueItem: any) => {
                                return valueItem.dataCode === item.display.target[i]
                            });
                            if (findedItem) {
                                if (findedItem.value instanceof Array) {
                                    if (findedItem.value.indexOf(item.display.rule[i]) === -1) {
                                        judgeArr.push(false);
                                    }
                                    else {
                                        judgeArr.push(true);
                                    }
                                }
                                else {
                                    if (findedItem.value !== item.display.rule[i]) {
                                        judgeArr.push(false);
                                    }
                                    else {
                                        judgeArr.push(true);
                                    }
                                }
                            }
                            else {
                                judgeArr.push(false)
                            }
                        }
                        if (judgeArr.indexOf(true) !== -1) {
                            return formField(item, index)
                        }
                    }

                } else if (item.display.type === 'RemindsCascade') {
                    if (item.display.condition === 'alarm') {
                        let judgeArr: any = [];
                        item.display.rule.forEach((v: any) => {
                            if (v.site) {
                                if (v.site == formCenterCode) {
                                    let currentItem = savedValues.find((i: any) => {
                                        return i.dataCode == item.display.target
                                    })
                                    if (currentItem && currentItem.value) {
                                        judgeArr = verify.checkRemindValueByRange(v.condition, v.rule, currentItem.value)
                                    }

                                } else if (v.site == '*') {
                                    let currentItem = savedValues.find((i: any) => {
                                        return i.dataCode == item.display.target
                                    })
                                    if (currentItem && currentItem.value) {
                                        judgeArr = verify.checkRemindValueByRange(v.condition, v.rule, currentItem.value)
                                    }

                                }
                            } else {
                                let currentItem = savedValues.find((i: any) => {
                                    return i.dataCode == item.display.target
                                })
                                if (currentItem && currentItem.value) {
                                    judgeArr = verify.checkRemindValueByRange(v.condition, v.rule, currentItem.value)
                                }
                            }
                        })

                        if (judgeArr.indexOf(true) !== -1) {
                            return formField(item, index)
                        }
                    }
                } else if (item.display.type === 'eqOuter') {
                    if (item.display.condition === 'eq') {
                        let outerTarget = item.display.target
                        if (outerTarget.indexOf('#') !== -1) {
                            outerTarget = outerTarget.split('#')[0]
                        }
                        //当前选中表单已存的value
                        let currentItem = savedValues.find((i: any) => {
                            return i.dataCode == outerTarget
                        });
                        if (currentItem) {
                            if ((currentItem.value && currentItem.value.indexOf(item.display.rule) !== -1) || (currentItem.value && currentItem.value === item.display.rule)) {
                                return formField(item, index)
                            }
                        }
                    }
                }
            } else {
                return formField(item, index)
            }

        }
    }
    
    // 渲染质疑
    const renderQuestion = (item: any) => {
        const children: React.ReactNode[] = []
        const found = savedValues.find(({ dataCode }: any) => dataCode === item.dataCode);

        if (found) {
            // 备注图标
            if (found.annotation) {
                children.push(
                    <Tooltip title={found.annotationText}>
                        <ExclamationCircleFilled style={{color: "#52c41a"}}/>
                    </Tooltip>
                )
            }

            if (Array.isArray(found.questions)) {
                let s = 0
                let times = 0
                for (let { state } of found.questions) {
                    if (state === 'Create' || state === 'Reopen') {
                        s = -2
                        break
                    }
                    if (state === 'Answer') {
                        s += 1
                    }
                    if (state === 'Closed') {
                        times += 1
                    }
                }

                if (times === found.questions.length) {
                    // 微信端全部关闭显示绿图标
                    // children.push(<WarningOutlined style={{color: '#63ad00'}}/>)
                } else {
                    if (s === -2) {
                        children.push(<WarningOutlined style={{color: '#f7b51e'}}/>)
                    } else if (s > 0) {
                        children.push(<WarningOutlined style={{color: '#497391'}}/>)
                    }
                }
            }

        }
        return children
    }

    useEffect(() => {
        return () => {
            deleteListItemFlag = true
        };
    }, []);
    // 点击ocr标记
    const showMark = (item : any)=>{
        props.showOcrMark && props.showOcrMark(item)
    }

    return (
        <ControlProvider createUserCode={props.createUserCode} workbench={props.workbench || false}>
            <div className="control">
                {
                    formData && formData.map((item: any, index: any) => {
                        return (
                            <React.Fragment key={index}  >
                                {switchField(item)} 
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </ControlProvider>
    )
}


export default forwardRef(Control);
