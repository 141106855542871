import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { FabricImage, Canvas, Group, FabricObject, PatternBrush, Point } from 'fabric';

import Toolbar from "./toolbar";
import ContextMenu from "./context-menu";
import HelpModal from "./help-modal";
import Slider from "./slider";
import Spin from "./spin";

import modStyl from './index.module.scss'
import { ActionEnum, CommandEnum, compressImage, dataURLToFile, defaultBrushValue, defaultColorValue, defaultZoomValue, loadRemoteImage, takeFileName } from "./utils";
import { mouseDownHandle, mouseMoveHandle, mouseUpHandle } from "./rect-utils";

import { handleClearMark, handleOcrMark } from "./polygon-mark";
export interface MosaicEditorRef {
  takeFiles: ()=> Promise<Map<string, File>>
  clearSnapshot: (onlyInitBrush?: boolean)=> void
}

export interface  OcrMark {
  location: Object
  dataCode: string,
  deCode: string,
  url: string,
  formId : string
}
interface Props {
  url: string
  preview?: boolean,
  ocrMarkData?: OcrMark[],
  ocrSinglelMarkData?: OcrMark[],
  isOcrMark: boolean,
  mainRef: any
}

// 当前图片初始zoom
let zoom = 1

let clearing = false;

let canvas: Canvas;

const ll = (tagName: string, ...rest: unknown[])=> {
  console.log(`?canvas:${tagName}`, ...rest)
}

export function unbind(canvas: Canvas) {
  canvas.selection = true
  // 从矩形打码切到其它功能时移除矩形绘制事件
  canvas.off({
    'mouse:down': mouseDownHandle,
    'mouse:move': mouseMoveHandle,
    'mouse:up': mouseUpHandle,
  })
}

const lockObject = (o: FabricObject)=> {
  o.selectable = false
  o.lockMovementX = true
  o.lockMovementY = true
  o.hoverCursor = 'crosshair'
  o.moveCursor = 'crosshair'
}

const unlockObject = (o: FabricObject)=> {
  o.selectable = true
  o.lockMovementX = false
  o.lockMovementY = false
  o.hoverCursor = 'default'
  o.moveCursor = 'default'
}

const makeGroup = (hasControls=true, objects: FabricObject[] = [])=> {
  const g = new Group(objects, {
    cornerColor: defaultColorValue,
    borderColor: defaultColorValue,
    cornerStyle: 'circle',
    transparentCorners: false,
    objectCaching: false,
    hasControls,
  })

  // 隐藏控制按钮
  g.setControlVisible('ml', false)
  g.setControlVisible('mt', false)
  g.setControlVisible('mr', false)
  g.setControlVisible('mb', false)

  return g
}

// 编组处理
const makeGroupOfClone = async()=> {
  const group = makeGroup(true, [])
  // group.cornerColor = 'red'
  for(const o of canvas.getObjects()) {
    if (o.type === 'group') {
      // 清除高亮数据
      (o as Group)?.getObjects().forEach(e=>{
        if(e.type == 'polygon'){
          (o as Group)?.remove(e)
        }
      })
     
      return o as Group
    }
    group.add(o)
  }
  return group
}

// 创建快照
const makeSnapshot = async(url: string)=> {
  const state = await makeGroupOfClone()
  if (state.getObjects().length > 0) {
    const zoom = canvas.getZoom()
    window.__mosaic.set(url, {
      state,
      zoom,
    })
  }
}

/**
 * `ImageEditor` 组件是一个基于 Fabric.js 的图片编辑器，提供了图片加载、缩放、画笔绘制、对象移动、清空画布等功能。
 * 
 * @param {Object} props - 组件属性
 * @param {boolean} [props.debug=false] - 是否开启调试模式
 * @param {boolean} [props.preview=false] - 是否为预览模式
 * @param {string} props.url - 要编辑的图片 URL
 * @param {React.RefObject<MosaicEditorRef>} ref - 反向引用，用于父组件访问编辑器实例的方法
 * 
 * @returns {JSX.Element} 返回编辑器的 JSX 元素
 * 
 * @example
 * // 使用示例
 * <ImageEditor url="path/to/image.jpg" />
 */
const ImageEditor = forwardRef<MosaicEditorRef, Props>(({ preview = false, url, ocrMarkData, ocrSinglelMarkData, isOcrMark, mainRef }, ref) => {

  // 缩放比例
  const [zoomValue, setZoomValue] = useState(defaultZoomValue)
  // 笔刷粗细
  const [brushValue, setBrushValue] = useState(defaultBrushValue)

  // 右键时选择的对象
  const [currentPoint, setCurrentPoint] = useState<Point>()
  const [currentTarget, setCurrentTarget] = useState<FabricObject>()

  // 工具栏当前操作
  const [action, setAction] = useState<ActionEnum>(ActionEnum.Move);

  // 显示帮助
  const [visibleHelp, setVisibleHelp] = useState(false)

  // 画布
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // 加载状态
  const [loading, setLoading] = useState(false)

  // 是否初始化图片渲染完成
  const [isImageRender, setIsImageRender] = useState(false)
  const makePatternBrash = ()=> {
    const source = new Image()
    source.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPLkyX8GPEBYWBifNAPjqAHDIgx27dqFNx3IysriTwejBjAwDv0wAADzZztR4yZAZQAAAABJRU5ErkJggg=='
    const mosaicPatternBrash = new PatternBrush(canvas);
    mosaicPatternBrash.source = source
    mosaicPatternBrash.width = brushValue
    mosaicPatternBrash.strokeLineCap = 'square'
    return mosaicPatternBrash
  }

  const watchUrlChange = async ()=> {
    let group: Group

    zoom = 1

    // 新图片加载
    if (!window.__mosaic.has(url)) {
      // 加载远程图片
      const im = await loadRemoteImage(url, setLoading)
      // 创建fabricImage
      const image = new FabricImage(im, {
        width: im.width,
        height: im.height,
        hasControls: false,
        // 默认true 以中心点旋转
        // centeredRotation: true,
        // 默认true 以中心点缩放
        // centeredScaling: true,
      })
      if(!canvasRef.current) return 
      // 缩放到合适大小
      let { width, height } = canvasRef.current!

      if(width && height){
        width -= 20
        height -= 20
  
        // 图片大于当前画布时缩小
        if (im.width > width || im.height > height) {
          // 图片宽度大于高度 以宽度为准
          if (im.width > im.height) {
            zoom = Math.floor(width / im.width * 100) / 100
          } else if (im.height > height) {
              zoom = Math.floor(height / im.height * 100) / 100
          }
        }
      }
      group = makeGroup(false === preview, [image])
    } else {
      // 从缓存加载
      const snapshot = window.__mosaic.get(url)!
      // 设置画布
      zoom = snapshot.zoom
      group = snapshot.state as Group

      group.hasControls = false === preview
    }
    
    setZoomValue(Math.round(zoom * 100))

    // 先清空
    canvas.getObjects().forEach((o)=> {
      canvas.remove(o)
    })

    // 再设置
    canvas.setZoom(zoom)
    canvas.add(group)
    canvas.setActiveObject(group)
    canvas.viewportCenterObject(group);
    canvas.renderAll()

    // 高亮场景下，初始化图片-高亮渲染
    setIsImageRender(true)
    // if(preview){
    //   // handleClearMark(canvas)
    //   // handleOcrMark(canvas, isOcrMark, ocrMarkData, group)
    // }
  }


  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    // 使用 ResizeObserver 监听 .main 容器的变化
      // 设置画布尺寸与父级相同
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentBoxSize) {
          const contentBoxSize = entry.contentBoxSize[0];
          canvas.setDimensions({
            width: contentBoxSize.inlineSize,
            height: canvas.height // 保持原始高度或设置新的高度
        });
          canvas.renderAll();
        }
      }
    });

    if (mainRef.current) {
      resizeObserver.observe(mainRef.current);
    }

    return () => {
      if (mainRef.current) {
        resizeObserver.unobserve(mainRef.current);
      }
    };
  }, []);

  useEffect(()=> {
     setIsImageRender(false)
    // 监听url变化
    setTimeout(watchUrlChange, 0)

    return ()=> {
      // 建立快照 clearSnapshot时强制清除不进行缓存
      if (false === clearing) {
        makeSnapshot(url)
      }
      // 重置
      clearing = false
      // 初始为移动操作
      setAction(ActionEnum.Move)
      // 初始画笔大小为10
      setBrushValue(defaultBrushValue)
      //
      canvas.isDrawingMode = false
      //
      canvas.freeDrawingBrush!.width = defaultBrushValue

      unbind(canvas)
    }
  }, [url])

  useEffect(()=>{
    // 防止初始化cavas还没渲染完成。preview触发打码模式的开关
    if(isImageRender){
      handleCenterAction()
    }
    if (preview) {
      // 高亮模式,防止canvas没有渲染成功
      handleClearMark(canvas)
      handleOcrMark(canvas, isOcrMark, ocrMarkData, null)
    }
  }, [preview])

  useEffect(()=>{
    if (ocrMarkData?.length && preview && isImageRender) {
      // 清空标记
      handleClearMark(canvas)
      // 高亮数据标记
      handleOcrMark(canvas, isOcrMark, ocrMarkData, null)
    }
  },[ocrMarkData, isImageRender])

  useEffect(()=>{
    if (ocrSinglelMarkData?.length && preview ) {
      // 清空标记
      if(!isOcrMark){
        handleClearMark(canvas)
      }
      // 高亮数据标记
      handleOcrMark(canvas, isOcrMark, ocrSinglelMarkData, null, true)
    }
  },[ocrSinglelMarkData])

  useEffect(()=>{
    // 高亮按钮触发
    handleClearMark(canvas)
    if(isOcrMark){
      handleOcrMark(canvas, isOcrMark, ocrMarkData, null)
    }
  },[isOcrMark])

  // 初始化fabric
  //
  useEffect(()=> {
    if (!canvasRef.current) {
      return;
    }

    // 初始化
    window.__mosaic = new Map()
    
    // 设置画布尺寸与父级相同
    const parentEl = canvasRef.current.parentElement
    if (parentEl) {
      canvasRef.current.width = parentEl.clientWidth;
      canvasRef.current.height = parentEl.clientHeight;
    }

    // 默认背景
    canvas = new Canvas(canvasRef.current, {
      backgroundColor: '#f6f7fc',
      enableRetinaScaling: true
    })
    
    // 设置画笔
    canvas.freeDrawingBrush = makePatternBrash()

    // 鼠标滚轮事件实现绽放图片
    canvas.on('mouse:wheel', function({ e }) {
      const zoom = canvas.getZoom() * 0.999 ** e.deltaY;
      const value = Math.min(Math.max(zoom, 0.1), 2)
      canvas.setZoom(value);
      
      setZoomValue(Math.floor(value * 100))

      e.preventDefault();
    })

    // 强制关闭右键菜单
    canvas.on('mouse:up', ()=> {
      setCurrentPoint(undefined)
      setCurrentTarget(undefined)
    })

    // 鼠标右键菜单
    canvas.on('contextmenu', ({e, target}: any)=> {
      setCurrentPoint(undefined)
      setCurrentTarget(undefined)
      if (target && canvas.getObjects().length > 1 && false === preview) {
        console.log('?target', target)
        setCurrentTarget(target);
        setCurrentPoint(new Point(e.pageX, e.pageY));
      }
      e.preventDefault();
    })

    return ()=> {
      canvas.destroy()
    }
  }, [])
 //
  // 初始化fabric结束
  //

  // 画笔笔刷宽度
  const handleSliderChanged = (value: number)=> {
    setBrushValue(value)
    if (canvas.freeDrawingBrush) {
      canvas.freeDrawingBrush.width = value;
    }
  }

  // 放大尺寸
  const handleZoomChanged = (value: number)=> {
    setZoomValue(value)
    canvas.setZoom(value / 100)
  }

  const handleMoveAction = ()=> {
    setAction(ActionEnum.Move)

    canvas.isDrawingMode = false;

    unbind(canvas)

    const group = makeGroup(true)
    for (const o of canvas.getObjects()) {
      if (o.type === 'group') {
        canvas.setActiveObject(o)
        break;
      }
      group.add(o);
      canvas.remove(o)
    }

    if (group.getObjects().length > 0) {
      canvas.add(group)
      canvas.setActiveObject(group)
    }
    canvas.renderAll()
  }

  const handleRectAction = ()=> {

    setAction(ActionEnum.Rect)

    canvas.selection = false
    canvas.isDrawingMode = false

    //
    canvas.on({
      'mouse:down': mouseDownHandle,
      'mouse:move': mouseMoveHandle,
      'mouse:up': mouseUpHandle,
    })

    // 取消编组
    const [first, ...objects] = canvas.getObjects()

    if (first.type === 'group') {
      (first as Group).getObjects().forEach((o)=> {
        lockObject(o)
        canvas.add(o);
      })
      canvas.remove(first)
    } else {
      lockObject(first)
      objects.forEach((o)=> lockObject(o))
    }
  }

  const handlePencilAction = ()=> {
    
    setAction(ActionEnum.Pencil)

    canvas.isDrawingMode = true;

    unbind(canvas)

    // 取消编组
    const [first, ...objects] = canvas.getObjects()

    if (first.type === 'group') {
      (first as Group).getObjects().forEach((o)=> {
        unlockObject(o)
        canvas.add(o);
      })
      canvas.remove(first)
      // canvas.renderAll();
    } else {
      unlockObject(first)
      objects.forEach((o)=> unlockObject(o))
    }
  }

  // 清空画布
  const handleClearAction = async (resetAngle = false)=> {
    const [first, ...objects] = canvas.getObjects()
    // 当前处于移动状态
    if (first.type === 'group') {
      
      const [image] = (first as Group).getObjects()
      
      if (resetAngle) {
        image.rotate(0)
        image.setCoords()
        first.rotate(0)
        first.setCoords()
      }

      // 移除组内Path
      canvas.remove(first)
      const group = makeGroup()
      group.add(image)
      canvas.add(group)
      canvas.setActiveObject(group)
    } else {
      // 编辑模式下直接删除Path
      for(const obj of objects) {
        canvas.remove(obj);
      }
    }
    canvas.renderAll()
  }

  // 重置画布
  const handleCenterAction = ()=> {
    setZoomValue(zoom * 100)
    // 切到移动模式
    handleMoveAction()
    handleClearAction(true)
    canvas.setZoom(zoom)
    canvas.viewportCenterObject(canvas.getActiveObject()!);
  }
  
  // 画板主菜单
  const handleToolbarCommand = (value: ActionEnum)=> {
    if (action === ActionEnum.Rect) {
      // 点击矩形时不做处理
      if (value === ActionEnum.Rect) {
        return
      }
    }
    
    if (value === ActionEnum.Question) {
      setVisibleHelp(true)
      return
    }

    if (value === ActionEnum.Download) {
      handleDonwloadAction();
      return
    }

    // 马克笔
    if (value === ActionEnum.Pencil) {
      handlePencilAction()
      return
    }

    // 矩形
    if (value === ActionEnum.Rect) {
      handleRectAction()
      return
    }

    // 移动到原点
    if (value === ActionEnum.Focus) {
      handleCenterAction()
      return
    }

    // 清除所有覆盖物
    if (value === ActionEnum.Reset) {
      handleClearAction()
      return
    }

    // 选择移动按钮时对象自动编组
    if (value === ActionEnum.Move) {
      handleMoveAction()
      return
    }
  }

  const handleContextMenuCommand = (value: CommandEnum)=> {
    if (value === CommandEnum.Delete) {
      if (currentTarget) {
        canvas.remove(currentTarget);
        setCurrentTarget(undefined)
        setCurrentPoint(undefined)
      }
      return
    }

    setCurrentPoint(undefined)

    if (value === CommandEnum.Clear) {
      handleClearAction()
      return
    }
    
    if (value === CommandEnum.Close) {
      return
    }
  }

  // 下载图片
  const handleDonwloadAction = async ()=> {
    const group = await makeGroupOfClone()
    const format = url.split('.').pop() as string
    const dataURL = group.toDataURL({
      quality: 1,
      format: ['jpeg', 'jpg'].includes(format) ? 'jpeg' : 'png',
      // enableRetinaScaling: true,
      // withoutTransform: true,
    });

    const file = dataURLToFile(dataURL, 'image.png')

    const ff = await compressImage(file, Number(sessionStorage.quality || 0.8))

    const reader = new FileReader()
    
    reader.addEventListener('load', ()=> {
      const a = document.createElement('a');
      a.href = reader.result as string
      a.download = `image.${format}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })

    reader.readAsDataURL(ff)
  }

  useImperativeHandle(ref, ()=> ({
    clearSnapshot() {
      handleMoveAction()
      clearing = true
      window.__mosaic.clear()
    },
    // 获取打码文件
    takeFiles: async()=> {
      const fileMap = new Map<string, File>()
      
      // 再次建立快照
      await makeSnapshot(url)

      const files = window.__mosaic as Map<string, MosaicSnapshot<Group>>

      for(const [key, value] of Array.from(files)) {
        // > 1 保证有打码痕迹
        if (value.state.getObjects().length > 1) {
          const { filename, format } = takeFileName(key)

          // 图片格式
          const dataURL = value.state.toDataURL({
            format,
            // enableRetinaScaling: true,
            // withoutTransform: true,
          })
          fileMap.set(key, await compressImage(dataURLToFile(dataURL, filename)))
        }
      }
      return fileMap
    }
  }))

  return (
    <div className={modStyl.editor}>
      {/* 画布 */}
      <canvas ref={canvasRef} className={modStyl.canvas}></canvas>
      {/* 工具条 */}
      {
        preview === false && (
          <Toolbar value={action} onCommand={handleToolbarCommand}/>
        )
      }
      {/* 缩放比例 */}
      {
        ActionEnum.Move === action && (
          <Slider value={zoomValue} min={10} max={200} suffix="%" onChange={handleZoomChanged}/>
        )
      }
      {/* 画笔粗细 */}
      {
        ActionEnum.Pencil === action && (
          <Slider value={brushValue} min={defaultBrushValue} max={60} onChange={handleSliderChanged}/>
        )
      }
      {/* 编辑模式和移动模式右键菜单 */}
      {
        currentTarget && currentPoint && (
          <ContextMenu point={currentPoint} showDelete={currentTarget.type !== 'image' && [ActionEnum.Rect, ActionEnum.Pencil].includes(action) } onCommand={handleContextMenuCommand}/>
        )
      }
      {/* 帮助信息 */}
      {
        visibleHelp && (
          <HelpModal onClose={()=> setVisibleHelp(false)}/>
        )
      }
      {/* 加载中... */}
      {
        loading && (
          <Spin/>
        )
      }
    </div>
  )
})

export default ImageEditor;