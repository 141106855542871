import { useState } from "react"
import { getTaskDistributeCenterList, getTaskDistributeProjectList } from "../../../../services/dct/DctService";
import { getVisitTreeList } from "../../../../services/dct/visit";


interface projectRecord {
  projectName: string
  projectCode: string
}
interface ProjectResult {
  code: number;
  message?: string;
  data: projectRecord[];
}

interface Option {
  label: string
  value: string
}

export const useFetchProject = ()=> {
  const [projectFetching, setProjectFetching] = useState(false)
  const [projectOptions, setProjectOptions] = useState<Option[]>([])

  const fetchProjectData = async(): Promise<string>=> {
    return new Promise(async(resolve)=> {
      setProjectFetching(true)
      const { code, data }: ProjectResult = await getTaskDistributeProjectList()
      setProjectFetching(false)
    
      if(code === 0) {
        setProjectOptions(
          data.map(({projectName, projectCode})=> ({
            label: projectName,
            value: projectCode
          }))
        )
        if (data.length === 1) {
          resolve(data[0].projectCode)
        }
      }
      resolve('')
    })
  }

  return {
    fetchProjectData,
    projectFetching,
    projectOptions,
  }
}

interface CenterRecord {
  code: string
  title: string
}
interface CenterResult {
  code: number;
  message?: string;
  data: CenterRecord[];
}
export const useFetchCenter = ()=> {
  const [centerFetching, setCenterFetching] = useState(false)
  const [centerOptions, setCenterOptions] = useState<Option[]>([])

  const fetchCenterData = async()=> {
    setCenterFetching(true)
    const { code, data }: CenterResult = await getTaskDistributeCenterList()
    setCenterFetching(false)
  
    if(code === 0) {
      setCenterOptions(
        data.map(({title, code})=> ({
          label: title,
          value: code
        }))
      )
    }
  }

  return {
    fetchCenterData,
    centerFetching,
    centerOptions,
  }
}

interface FormRecord {
  formCode: string
  formName: string
}
interface VisitRecord {
  visitName: string
  formNames: FormRecord[]
}
interface VisitResult {
  code: number;
  message?: string;
  data: VisitRecord[];
}
interface VisitTreeOption extends FormRecord {
  children : FormRecord[]
}
export const useFetchVisitTree = ()=> {
  const [visitFetching, setVisitFetching] = useState(false)
  const [visitTreeOptions, setVisitTreeOptions] = useState<VisitTreeOption[]>([])

  const fetchVisitTreeData = async(projectCode: string)=> {
    setVisitFetching(true)
    const { code, data }: VisitResult = await getVisitTreeList(projectCode)
    setVisitFetching(false)
  
    if(code === 0) {
      setVisitTreeOptions(
        data.map((item)=> ({
          children: item.formNames,
          formName: item.visitName,
          formCode: item.visitName,
        }))
      )
    }
  }

  return {
    fetchVisitTreeData,
    visitFetching,
    visitTreeOptions,
    setVisitTreeOptions,
  }
}

export const useFetchStatus = ()=> {
  const statusOptions = [
    {label: '全部', value: ''},
    {label: '待回复', value: '1'},
    {label: '已回复', value: '2'},
    {label: '已关闭', value: '3'},
  ]

  const statusMap = new Map(statusOptions.map(({label, value})=> [value, label]))

  return {
    statusOptions,
    statusMap,
  }
}
