import { Cascader } from "antd"
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react"
import { filterOptions } from "./utils"
import formAction from "../../utils/formAction"
import { SimpleValueItem } from "../../utils/checkField"

type DataAction = 'DataActionUpdate' | 'DataActionAnnotation' | 'DataActionRemark' | 'DataActionAnswer'

interface ValueItem {
  dataCode: string
  deCode: string
  inputState?: string // Runing
  projectCode?: string
  question?: boolean
  subjectCode?: string // GYVR100005
  value: string
}

export interface Properties {
  // 区域之间的分隔符
  separator?: string
  // 选择地区层级 1 - 省，2 - 市，3 - 区
  level?: 1 | 2 | 3
  // 是否显示已禁用地区（默认显示为禁用状态）
  display?: boolean
  // 包含以下地区（填写省份则整个省份下的城市可选，2者同时配置优先includes）
  includes?: string[][]
  // 排除以下地区（填写省份则整个省份下的城市不可选择）
  excludes?: string[][]
}

export type RegionSelectActions = {
  getModifyValue: (value?: string)=> void
}

interface RegionSelectProps {
  formId: string
  formIsRead: boolean
  savedValues: ValueItem[]
  dataActions: DataAction[]
  updateFun?: (item?: SimpleValueItem)=> void
  data: {
    col?: string
    isAdd?: boolean
    dataCode: string
    deCode: string
    label: string
    type: string
    sort?: any
    placeholder?: string
    properties?: Properties
    validationRules?: any[]
    logicOperations?: any
    remind?: any
    reminds?: any[]
  }
}

const RegionSelect = forwardRef<RegionSelectActions, RegionSelectProps>(({ formId, updateFun, formIsRead, savedValues, data }, ref)=> {

  const [value, setValue] = useState<string[]>([])

  const { properties = {} } = data

  const options = useMemo(()=> filterOptions(properties), [])

  const { separator = '/' } = properties


  const takeFieldValues = (current?: string)=> {
    return {
      dataCode: data.dataCode,
      deCode: data.deCode,
      formId: formId,
      value: current ?? value.join('-'),
    } as SimpleValueItem
  }

  const handleChange = (values: (string | number)[])=> {
    setValue(values as string[])

    const params = {
      formId: formId,
      submit: false,
      values: [
        takeFieldValues(values.join(separator))
      ]
    }

    formAction.saveData(params);
    updateFun?.(
      takeFieldValues(params.values[0].value)
    )
  }

  useEffect(()=> {
    if (Array.isArray(savedValues)) {
      const current = savedValues.find((item: ValueItem) => item.dataCode === data.dataCode)

      if (current) {
        setValue(current.value.split(separator))
      }
    }
  }, [savedValues])

  // 暴露给父组件的方法
  useImperativeHandle(ref, () => ({
    getModifyValue: ()=> takeFieldValues(),
  }));

  return (
    <Cascader
      value={value}
      disabled={!formIsRead}
      onChange={handleChange}
      style={{width: '100%'}}
      fieldNames={{label: 'label', value: 'label'}}
      placeholder={data.placeholder || `请选择${data.label}`}
      options={options}
      displayRender={(labels: string[])=> labels.join(separator)}
      showSearch/>
  )
})

export default RegionSelect