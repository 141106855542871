/**
 * 活动报名
 */
 import { requestPost, requestGet, requestPostRes, requestGetRes } from '../EdcService';

//  文档地址: http://106.14.197.93:6060/doc.html#/ocr/OCR%E8%87%AA%E5%8A%A8%E8%AF%86%E5%88%AB/ocrContentUsingPOST
//  const urlPrefix = "/ocr/ocr/img";
 const urlPrefix = "/ocr";
//  const urlPrefix = "http://192.168.7.34:6060/ocr/img";

// OCR划词 (获取屏幕划词)
export const GetOcrDelimitation = (params) => {
    const url = `${urlPrefix}/delimitation`;
    // const url = `http://192.168.7.34/ocr/delimitation`;
    return requestPostRes(url, params)
};

// OC划词 记录 (图片上绿色矩形标注反显)
export const GetOcrDelimitationRecord = (params) => {
    const url = `${urlPrefix}/delimitation/field`;
    return requestPostRes(url, params)
};

// OCR敏感信息 识别敏感字段
export const GetOcrSensitive = (params) => {
    const url = `${urlPrefix}/sensitive`;
    return requestPostRes(url, params)
};

// OCR敏感信息 记录
export const GetOcrSensitiveRecord = (params) => {
    const url = `${urlPrefix}/sensitive/record`;
    return requestPostRes(url, params)
};

// OCR 自动识别
export const GetOcrAutomatic = (params) => {
    const url = `${urlPrefix}/automatic`;
    return requestPostRes(url, params)
};

// OCR 自动识别 记录
export const GetOcrAutomaticRecord = (params) => {
    const url = `${urlPrefix}/automatic/record`;
    return requestPostRes(url, params)
};

// OCR
export const GetOcrLocation = (params) => {
    const url = `${urlPrefix}/location`;
    return requestPostRes(url, params)
};
