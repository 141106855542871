import React, { useEffect, useState } from "react"
import moment, { Moment } from "moment"
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd"
import locale from "antd/es/date-picker/locale/zh_CN"
import modStyl from './index.module.scss'
import utils from "../../../../../util/utils"
import { useForm } from "antd/lib/form/Form"
import { ReportScalePayload } from "../../../../../services/data/report"

interface Body extends Omit<ReportScalePayload, 'startTime' | 'endTime'> {
  betweenAt: [Moment, Moment]
}

const colCols = {
  span: 6
}
const fieldCols = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 18
  }
}

type ConditionProps = {
  onSearch: (params: Partial<ReportScalePayload>)=> void
}
const Condition: React.FC<ConditionProps> = ({ onSearch })=> {

  const [form] = useForm<Body>()

  const makeQuery = (): ReportScalePayload=> {
    const { betweenAt, ...fd } = form.getFieldsValue()

    const at: Pick<ReportScalePayload, 'endTime'| 'startTime'> = {}

    if (betweenAt) {
      const [a, b] = betweenAt.map((moment)=> moment.format('YYYY-MM-DD'))
      at.startTime = a
      at.endTime = b
    }

    return {
      ...fd,
      ...at,
    }
  }

  const submitControl = ()=> {
    onSearch(makeQuery())
  }

  const resetControl = ()=> {
    form.resetFields()
    onSearch({})
  }

  const exportControl = ()=> {
    utils.exportPostFun('data/report/scale/list/downData', makeQuery())
  }
  
  return (
    <div className={modStyl.wrapper}>
      <Form form={form}>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="name" label="患者">
            <Input placeholder="患者编号或姓名缩写" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="doctorName" label="研究者姓名">
            <Input placeholder="请输入研究者姓名" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="centerCode" label="研究中心">
              <Input placeholder="研究中心编号或研究中心名称" allowClear/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="bindState" label="是否注册">
              <Select options={[{value: '0', label: '未注册'}, {value: '1', label: '已注册'}]} placeholder="请选择" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="betweenAt" label="注册时间">
              <DatePicker.RangePicker style={{width: '100%'}} locale={locale} allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}></Col>
          <Col {...colCols}>
            <Button onClick={submitControl} type="primary">搜索</Button>
            <Button onClick={resetControl} style={{marginLeft: 10}}>重置</Button>
            <Button onClick={exportControl} style={{marginLeft: 10}} type="link">导出数据</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Condition