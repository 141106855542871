import { Button, Form, Input, message as messager, Modal } from "antd"
import React, { useEffect, useMemo } from "react"
import { updateMobile } from "../../../../../../../services/data/patientSelfReported"
import { useForm } from "antd/lib/form/Form";
import { InfoCircleOutlined } from "@ant-design/icons";
import TenantConfiguration from "../../../../../../../util/tenantConfiguration";

const rules = {
  name: [
    {required: true, message: '请输入姓名缩写'},
    {pattern: /^([A-Z]|-){4,10}$/, message: '仅支持4-10位大写字母格式或者“-”'}
  ],
  mobile: [
    {required: true, message: '请输入手机号'},
    {pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号'}
  ]
}

//#region 姓名缩写提示
const TooltipContent = (
  <div>
      姓名缩写填写规则：支持4-10位大写字母格式填写姓名缩写；<br/>
      两字姓名填写两字拼音前两个大写字母<br/>
      举例：张悦ZHYU;<br/>
      三字姓名填写三字首字母及第三字第二字母<br/>
      举例：刘书明LSMI<br/>
      四字姓名填写每一个字的首字母<br/>
      举例：上官文朔SGWS<br/>
      如果按以上规则仍无法取得四位姓名缩写时，则用“-”在最后补齐四位姓名缩写<br/>
      举例：王丽娥WLE-<br/>
      四字以上姓名可以填写每一个字的首字母（最多支持十位）<br/>
      举例：博尔济吉特琦善BEJJTQS。
  </div>
);
//#endregion

interface Payload {
  subjectCode: string
  name: string
  mobile?: string
}

interface Props {
  info: Payload
  visible: boolean
  onClose: (changed: boolean, name: string)=> void
}

const SubjectModal: React.FC<Props> = ({ visible, info, onClose })=> {

  const [form] = useForm()

  const label = useMemo(()=> TenantConfiguration.read('label') as string, [])

  const handleOk = async (payload: Payload)=>{
    const { code , message } = await updateMobile({
      ...payload,
      subjectCode: info.subjectCode
    })
    if(code == 0){
      messager.success(message, 1)
      onClose(true, payload.name)
    } else {
      messager.error(message, 1)
    }
  }

  useEffect(()=> {
    form.setFieldsValue({
      name: info.name,
      mobile: info.mobile
    })
  }, [])

  return (
    <Modal
      className={'workModal'}
      open={visible}
      title={`${label}信息修改`}
      onCancel={()=> onClose(false, '')}
      width={420}
      centered
      destroyOnClose
      maskClosable
      footer={null} >
        <Form 
          name="nest-messages"
          form={form}
          layout="vertical"
          onFinish={handleOk}>
          {
            info.mobile && (
              <Form.Item label="手机号" name='mobile' rules={rules.mobile}>
                <Input placeholder="请输入手机号" maxLength={11}/>
              </Form.Item>
            )
          }
          <Form.Item
            label="姓名缩写"
            name='name'
            rules={rules.name}
            tooltip={{
              title: TooltipContent,
              icon: <InfoCircleOutlined/>
            }}>
            <Input placeholder="请输入姓名缩写" maxLength={10}/>
          </Form.Item>
          <Form.Item label=" " colon={false}>
            <Button htmlType="submit" type="primary">确认</Button>
          </Form.Item>
        </Form>
      </Modal>
  )
}

export default SubjectModal