import React, { PropsWithChildren, useMemo, useState } from "react";
import { Button, Descriptions } from "antd";
import TenantConfiguration from "../../../../../util/tenantConfiguration";
import { EditFilled } from "@ant-design/icons";
import SubjectModal from "../../../../data/workbench/all/subjects/subjectInfo/subject-modal";

interface Props<T> {
  data: T;
  showApproveTime?: boolean
  imageCheckEntry?: boolean
}

const Current = <T extends {[name: string]: unknown}>({ data, imageCheckEntry = false, showApproveTime = false }: PropsWithChildren<Props<T>>)=> {

  const label = useMemo(()=> TenantConfiguration.read('label') as string, [])

  const [visible, setVisible] = useState(false)
  const [subjectName, setSubjectName] = useState(data.subjectName)

  const handleRefresh = (changed: boolean, name: string)=> {
    setVisible(false)
    if (changed) {
      setSubjectName(name)
    }
  }

  return (
    <>
      <Descriptions title={data.formName} bordered size="small" column={3} style={{marginBottom: 10}}>
        <Descriptions.Item label="所属项目">{data.projectName}</Descriptions.Item>
        <Descriptions.Item label="所属中心">{data.centerName}</Descriptions.Item>
        <Descriptions.Item label="访视">{data.visitName}</Descriptions.Item>
        <Descriptions.Item label="表单">{data.formName}</Descriptions.Item>
        <Descriptions.Item label={label}>
          <span>{subjectName} ({data.subjectCode})</span>
          {
            imageCheckEntry && (
              <Button onClick={()=> setVisible(true)} title={`编辑${label}缩写`} style={{marginLeft: 5}} size="small" type="primary" shape="circle" icon={<EditFilled/>}></Button>
            )
          }
        </Descriptions.Item>
        <Descriptions.Item label="采集人">{data.collector}{data.approveTime && showApproveTime ? `(${data.approveTime})` : ''}</Descriptions.Item>
      </Descriptions>
      {/* 修改患者缩写 */}
      <SubjectModal onClose={handleRefresh} visible={visible} info={{subjectCode: data.subjectCode as string, name: data.subjectName as string}}/>
    </>
  )
}

export default Current;