import detailStyl from './DetailModal.module.scss'

import React, { useEffect, useRef, useState, useCallback } from 'react'

import { message as messager, Button, Modal, Switch } from 'antd';
import { DataType } from '..';
import { GetDctApprovalOperation, GetDctApprovalOperationPreCheck, GetFormDataById, getTaskDistributeImageDetail, RevDctApprovalImage } from '../../../../services/dct/DctService';
import {  StatusEnum } from './Condition';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { GetOcrLocation } from '../../../../services/ocr/OcrService';
import Current from '../../crc/dataCheck/components/Current';
import Control from '../../../crf_form/control/Control';
import FullModal from '../../../full-modal';
import alioss from "ali-oss";
import { makeUploadName, makeUploadOptions } from '../../../crf_form/components/ocr-autofill/utils';
import { batchMosaic, BatchMosaicDataFile } from '../../../../services/file';
import TextArea from 'antd/lib/input/TextArea';
import ImageEditor, { MosaicEditorRef, OcrMark } from '../../../mosaic-editor';
import AlbumSlider from './album-slider';
import { useFetchStatus } from '../repo';

type DetailModalProps = {
  data: DataType[];
  onClose: (refresh: boolean)=> void;
}


const DetailModal: React.FC<DetailModalProps> = ({ onClose, data = []})=> {

  const {statusMap} = useFetchStatus()

  // 加载状态
  const [loading, setLoading] = useState(false)
  // 是否需要在关闭详情时刷新列表
  const [refresh, setRefresh] = useState(false)
  // 当前某条记录
  const [current, setCurrent] = useState<DataType>()
  // 当前数据在records中的索引
  const [index, setIndex] = useState(-1)
  //
  const [records, setRecords] = useState<DataType[]>([])

  // 详情信息
  const [formSet, setFormSet] = useState<any>()

  const [thumb, setThumb] = useState<DataType>()
  const [thumbs, setThumbs] = useState<DataType[]>([])
  const [db, setDb] = useState<any>()

  // ocr标记数据
  const [ocrMarkData, setOcrMarkData] =  useState<OcrMark[]>();
  // 实际标记数据, 兼容点击标记
  const [ocrSinglelMarkData, setOcrSinglelMarkData] =  useState<OcrMark[]>();
  // 是否高亮开关
  const [isOcrMark, setIsOcrMark ] = useState<boolean>(true);
  // 是否开启打码
  const [ isOpenCode, setIsOpenCode ] = useState<boolean>(true);

  // 左右伸缩
  const [isResizing, setIsResizing] = useState(false);
  const mainRef = useRef<any>(null);

  const formRef = useRef()

  // 是否需要二次确认
  const needConfirmSubmitRef = useRef(true)
  // 二次确认缓存数据
  const confirmSubmitMapRef = useRef<Map<string, File>>(new Map())

  const editorRef = useRef<MosaicEditorRef>(null)

  const sliderRef = useRef<any>(null)

  useEffect(()=> {
    setIndex(0)
    setRecords(data)
    setCurrent(data[0])
    sliderRef.current.slickGoTo(0)
  }, [])

  useEffect(()=>{
    // 获取所有ocr标记
    setIsOpenCode(true)
    if(current && thumb){
      getAllOcrLocation()
    }
  }, [thumb, current])

  
  // 获取详情信息
  const fetchDetail = async(id: number)=> {
    const {code, data} = await getTaskDistributeImageDetail(id)
    if(code === 0 && data.length > 0) {
      setThumbs(data)
      setThumb(data[0])
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0); // 重置到第一项
      }
    }
  }

  // 获取表单数据
  const fetchForm = async (id: number, dataTenantCode: string) => {
    const {code, data} = await GetFormDataById({
      id,
      dataTenantCode
    })

    if(code === 0) {
      data.formActions = [
        "FormActionRead",
      ]
      data.dataActions = [
        // 仅图片组件可以发起质疑
        "DataActionQuestion=photo-oss,ocr-autofill",
      ]
      setFormSet(data)
    }
  }

  // 1. 获取表单相关数据
  // 2. 获取详情当前表单所有图片列表
  // 3. 清空拒绝数据
  useEffect(()=> {
    if(records[index]) {
      if(current) {
        const { formId, tenantCode } = records[index]
        fetchDetail(formId)
        fetchForm(formId, tenantCode)
      }
    }
  }, [index])

  // 处理上一条下一条数据
  const recordControl = async (step: number)=> {
    sliderRef.current.slickGoTo(0)
    
    // 第1条
    if(step === -1 && index === 1) {
      messager.warn('当前受试者第1张需要审核的图片')
    }
    // 最后一条
    if(step === 1 && index === records.length - 2) {
      messager.warn('当前受试者已经没有需要审核的图片了')
    }

    let currentIndex = index + step;

    // 清空编辑器快照数据
    editorRef.current!.clearSnapshot()
    // 未涉及到翻页
    if(records[currentIndex]) {
      setIndex(currentIndex)
      setCurrent(records[currentIndex])
    }
  }

  const afterChange =(i: number)=> {
    setThumb(thumbs[i])
  }

  const confirmSubmit = ()=> {
    Modal.confirm({
      title: '操作确认',
      content: '请确认当前所有照片是否需要打码处理？',
      okText: '已确认且不需要处理',
      cancelText: '取消',
      onOk: ()=> {
        needConfirmSubmitRef.current = false
        submitForm()
      }
    })
  }

  const onEditorChange = ()=> {
    needConfirmSubmitRef.current = true
  }

  const batchUploadImage = async (imgStack: Map<string, File>)=> {
    /// 上传相关
    const uploadOptions = await makeUploadOptions(messager.error);

    if (!uploadOptions) {
      messager.error("初始化上传参数失败");
      return false;
    }

    const [prefix, options] = uploadOptions;

    const client = new alioss(options);

    const reqStack: Promise<BatchMosaicDataFile>[] = [];

    for(const t of thumbs) {
      const file = imgStack.get(t.url as string)
      if(file) {
        reqStack.push(new Promise(async (resolve, reject) => {
          const pathname = makeUploadName(prefix, file);

          if (!pathname) {
            messager.error("上传缺少必要参数");
            return;
          }
      
          // 上传图片到OSS
          const result = await client.put(pathname, file, {
            timeout: 600 * 1000,
            // headers: makeUploadHeaders(0),
          });
      
          if (result.res.status !== 200) {
            messager.error("上传失败，请重试！");
            reject(null)
            return
          }

          resolve({
            deCode: t.deCode,
            dataCode: t.dataCode,
            oldUrl: t.url as string,
            newUrl: result.url,
          })
        }))
      }
    }

    try {
      // 无图片需要上传时直接返回
      if (reqStack.length === 0) {
        return true
      }

      const files = await Promise.all(reqStack);

      // console.log('?results', results)
      await batchMosaic({
        formId: current!.formId,
        files,
      })

      return true
    } catch (error) {
      return false
    }
  }

  const imageDes = async (): Promise<boolean>=> {
    //
    if(!current || !editorRef.current) {
      return false
    }

    // 已二次提示过用户
    if (false === needConfirmSubmitRef.current) {
      // 无打码数据
      if (confirmSubmitMapRef.current.size === 0) {
        return true
      }
      // 使用缓存数据直接提交
      return await batchUploadImage(confirmSubmitMapRef.current)
    }

    // 从编辑器中获取打码图片
    const imgStack = await editorRef.current.takeFiles()

    // console.log('?imgStack', imgStack)

    // 无打码提示用户审核通过提醒
    if(imgStack.size === 0) {
      confirmSubmitMapRef.current.clear()
      confirmSubmit()
      return false
    }

    // 数量不匹配则视为打码不完整 有未打码的图片
    if (imgStack.size !== thumbs.length) {
      confirmSubmitMapRef.current = imgStack
      confirmSubmit()
      return false;
    }

    // console.log('???uploaded', imgStack.keys())
    // return true

    return await batchUploadImage(imgStack)
  }

  // 通过和取消通过
  const submitForm = async()=> {
    if(!current) {
      return
    }
   
    setLoading(true)

    const re = await GetDctApprovalOperationPreCheck({
      id: thumbs.map(({id})=> id).join(','),
      approveStatus: 'AGREE'
    });

    if (re.code != 0) {
      setLoading(false)
      return
    }

    const ok = await imageDes()
    if(ok === false) {
      setLoading(false)
      return
    }

    needConfirmSubmitRef.current = true
    confirmSubmitMapRef.current.clear()

    setLoading(false)

    // 更新列表数据中的状态
    const {code, message} = await GetDctApprovalOperation({
      id: thumbs.map(({id})=> id).join(','),
      approveStatus: 'AGREE'
    });

    if (code === 0) {
      records[index].approveStatus = 'AGREE'
      setRecords([...records])
      setRefresh(true)
      recordControl(1)
      messager.success(message)
    }
  }

  const resetReasonRef = useRef('')

  // 取消通过
  const revSubmit = async()=> {
    if(!current) {
      return
    }

    Modal.confirm({
      icon: null,
      title: '操作确认',
      content: <TextArea maxLength={200} onChange={(e)=> resetReasonRef.current = e.target.value} placeholder="请输入撤销原因 不超过200个字" autoSize={{minRows: 3}}/>,
      okText: '确定撤回',
      cancelText: '取消',
      onOk: async()=> {
        return new Promise(async (resolve, reject)=> {
          if (resetReasonRef.current === '') {
            messager.error('请输入撤销原因')
            reject()
            return
          }
          if (resetReasonRef.current.length > 200) {
            messager.error('撤销原因不超过200个字')
            reject()
            return
          }
          
          setLoading(true)
          const {code, message} = await RevDctApprovalImage({
            formId: current.formId,
            ids: thumbs.map(({id})=> id),
            subjectCode: current.subjectCode,
            resetReason: resetReasonRef.current,
          });
  
          setLoading(false)
  
          if(code === 0) {
            resolve(true)
            // 更新列表数据中的状态
            records[index].approveStatus = 'RESET'
            setRecords([...records])
            setRefresh(true)
            recordControl(1)
            messager.success(message)
          // } else {
          //   messager.error(message)
          }
        })
      }
    })
  }

  const refreshControlData = async()=> {
    const { formId, tenantCode } = current as DataType
    fetchForm(formId, tenantCode)
  }

  const getAllOcrLocation = async ()=>{
    const { formId } = current as DataType
    const res = await  GetOcrLocation({
      formId,
      url: thumb?.url
      
    })
    const { code , data } = res
    if(code == 0){
      setOcrMarkData(data)
    }
  }
   // 获取ocr标记参数
   const getOcrMarkParams = (item : any) =>{
    // 后端拿不到dataCode, 统一使用的deCode，忽略list结构
    setOcrSinglelMarkData(ocrMarkData?.filter(e=>e.deCode === item.deCode))
  }
  // ocr标记开关
  const isMarkHandle = (checked: boolean)=>{
    setIsOcrMark(checked)
  }
  // 左右两边拉伸
  const onMouseDown = useCallback(() => {
    setIsResizing(true);
  }, []);
  
  const onMouseMove = useCallback(
    (event) => {
      if (!isResizing || !mainRef.current) return;
      const containerWidth = mainRef.current.parentElement.offsetWidth;
      const newMainWidth = Math.max(400, Math.min(event.clientX, containerWidth - 400));
      mainRef.current.style.width = `${newMainWidth}px`;
    },
    [isResizing]
  );

  const onMouseUp = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [isResizing, onMouseMove, onMouseUp]);

  return (
    <FullModal onClose={()=> onClose(refresh)} title="图片审核">
      <div className={`${detailStyl.wrapper} imageDetailWrapper`}>
        <div className={detailStyl.main} ref={mainRef}>
          <div className={detailStyl.mainTop}>
            <div className={detailStyl.thumbs}>
              <AlbumSlider thumbs={thumbs} ref={sliderRef} onChange={afterChange}/>
            </div>
          
            <div  className={detailStyl.mark}>
               { current?.approveStatus !== 'AGREE' && (<>打码模式 <Switch  defaultChecked={true} checked={isOpenCode} onChange={(checked)=>{setIsOpenCode(checked); setOcrSinglelMarkData([]);}} ></Switch></>)}
               { (<>&nbsp;&nbsp;高亮 <Switch disabled={isOpenCode && current?.approveStatus !== 'AGREE'} defaultChecked={true} onChange={isMarkHandle} ></Switch></>) }
              
            </div>
          </div>
          <div className={detailStyl.canvas}>
            {
              thumb && current && (
                <ImageEditor mainRef={mainRef} ref={editorRef} preview={current.approveStatus === 'AGREE' || !isOpenCode} url={thumb.url as string} 
                 isOcrMark={isOcrMark}  ocrMarkData={ocrMarkData} ocrSinglelMarkData={ocrSinglelMarkData}/>
              )
            }
          </div>
          <div className={detailStyl.steps}>
            <Button disabled={index === 0} onClick={()=> recordControl(-1)} shape="round">
              <ArrowLeftOutlined/>
              <span>上一条</span>
            </Button>
            <Button disabled={index === records.length - 1} onClick={()=> recordControl(1)} shape="round" style={{marginLeft: 20}}>
              <span>下一条</span>
              <ArrowRightOutlined/>
            </Button>
          </div>
        </div>
        <div className={detailStyl.resizer} onMouseDown={onMouseDown}></div>
        <div className={detailStyl.east}>
          {thumb && <Current data={thumb} imageCheckEntry showApproveTime/>}
          <div className={detailStyl.info}>
            {
              formSet && current &&  (
                <Control showOcrMark={getOcrMarkParams} updateSelfData={refreshControlData} tenantCode={current.tenantCode} formId={current.formId} data={formSet} ref={formRef} isDct={true}></Control>
              )
            }
          </div>
          <div className={detailStyl.foot}>
            {
              current && current.approveStatus === 'AGREE' ? (
                <Button onClick={revSubmit} loading={loading} type="ghost" danger shape="round" style={{marginRight: 10}}>撤销审核</Button>
              ) : (
                <Button onClick={submitForm} loading={loading} type="primary" shape="round" style={{marginRight: 10}}>审核通过</Button>
              )
            }
          </div>
          {
            current && (
              <span data-status={current.approveStatus} className={detailStyl.status}>{statusMap.get(current.approveStatus)}</span>
            )
          }
        </div>
      </div>
    </FullModal>
  )
}

export default DetailModal