import '../../../crc/dataCheck/components/DetailModal.less'
import modStyl from './detail-modal.module.scss'

import React, { useEffect, useMemo, useRef, useState } from 'react'

import { message as messager, Button, Modal, Timeline, Descriptions } from 'antd';
import { DataResult, DataType } from '..';
import { CloseImageQuestion, GetQueryList, GetQueryListDetails } from '../../../../../services/dct/DctService';

import { ArrowLeftOutlined, ArrowRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Slider from "react-slick";

import Current from '../../../crc/dataCheck/components/Current';
import storageX, { Engine } from '../../../../../util/storageX';
import Form from './form';

export interface Payload {
  // 所属项目
  projectCode?: string
  // 所属中心
  centerCode?: string[]
  // 访视&表单
  visit?: {name: string, formName: string[]}[]
  // 采集人
  createUser?: string
  // 患者编号
  subjectCode?: string
  // 状态
  questionStatus?: string
}

export type Options = {
  index: number;
  // 0:是第1条, 1:最后一条, -1:既不是最后也不是第1条 
  last: 0 | 1 | -1;
  condition: Payload;
  pageNum: number;
  pageSize: number;
  // 总条数
  total: number;
  // 当前翻页数据
  records: DataType[];
}

type DetailType = {
  id: null | string
  centerName: string
  collector: string
  formName: string
  projectName: string
  questionStatus: string
  questionActions: string[]
  questionText: string
  questionTime: string
  questionUserCode: string
  questionUserName: string
  replyContent: string
  replyTime: string
  replyUserName: string
  subjectCode: string
  subjectName: string
  url: string
  visitName: string
  list: {
      replyContent: string
      replyTime: string
      replyUserCode: string
      replyUserName: string
      type: string
    }[]
}

const takeStyl = (userName: string, collector: string, item: DetailType['list'][0])=> {
  const color = item.replyUserName == userName ? '#4E88F6' : '#F79800'
  const background = item.replyUserName == collector ? '#643697' : color
  return {
    background
  }
}

export const makeLast = (index: number, total: number, pageNum: number, pageSize: number): (0 | 1 | -1)=> {
  let linenumber = index + 1;
  let num = Math.abs(pageNum * pageSize - pageSize + linenumber);
  // 是否是最后一条
  if (num >= total) {
    return 1
  } else if(num <= 1) {
    return 0
  }
  return -1;
}

export const fetcChallList = async (pageNum: number, pageSize: number, params: Payload): Promise<[number, DataType[]]>=> {
  const {code, data}: DataResult = await GetQueryList({
    ...params,
    page: pageNum,
    pageSize: pageSize
  })
  if(code === 0) {
    return [data.total, data.rows]
  }
  return [0, []]
}

//#region 
type TitleProps = {
  userName: string
  collector: string
  item: DetailType['list'][0]
}
// item.replyUserName
const Block: React.FC<TitleProps> = ({collector, userName, item})=> {
  const t = {
    create: '发起质疑',
    Answer: '回复质疑',
    Supplement: '补充质疑'
  }
  type T = keyof typeof t
  return (
    <div className={modStyl.block}>
      <div className={modStyl.inner}>
      <div className={modStyl.wt} style={takeStyl(userName, collector, item)}>{item.replyUserName}</div>
        <div className={modStyl.md}>
            <div className={modStyl.title}>
              <span>{item.replyUserName}</span>
              <span>{t[item.type as T] || '关闭质疑'}</span>
            </div>
            <div className={modStyl.content}>{item.replyContent}</div>
            <div className={modStyl.time}>{item?.replyTime?.replace('T', ' ')}</div>
        </div>
      </div>
    </div>
  )
}
//#endregion

type DetailModalProps = {
  options: Options;
  onClose: (refresh: boolean)=> void;
}
const DetailModal: React.FC<DetailModalProps> = (props)=> {
  // 加载状态
  const [loading, setLoading] = useState(false)
  // 翻页查询中 0未在请求 1在请求下一页 -1在请求上一页
  const [queryLoading, setQueryLoading] = useState(0)
  
  // 当前状态
  const [status, setStatus] = useState<string>()
  // 当前某条记录
  const [current, setCurrent] = useState<DataType>()
  // 当前数据在records中的索引
  const [index, setIndex] = useState(-1)
  // 是否是最后一条 1:是最后一条 0:是第1条 -1:除最后和第1条外的
  const [last, setLast] = useState(0)
  // 页码
  const [pageNum, setPageNum] = useState(1)
  // 总记录条数
  const [total, setTotal] = useState(0)
  // 翻页数据
  const [records, setRecords] = useState<DataType[]>([])
  // 参数
  const [options, setOptions] = useState({} as Options)  

  // 详情信息
  const [formSet, setFormSet] = useState<DetailType>()

  const [thumb, setThumb] = useState<string>()
  const [thumbs, setThumbs] = useState<string[]>([])

  const [visible, setVisible] = useState(false)

  const userName = useMemo(()=> storageX(Engine.Session).user().userName, [])

  // 是否需要在关闭详情时刷新列表
  const refresh = useRef(false)

  useEffect(()=> {
    if(!props.options) {
      return;
    }

    const { options } = props;

    const item = options.records[options.index]

    setLast(options.last)
    setIndex(options.index)
    setPageNum(options.pageNum)
    setTotal(options.total)
    setRecords(options.records)
    setOptions(options)
    setCurrent(item)
    setStatus(item.questionStatus)
  }, [props.options])


  // 获取详情信息
  const fetchDetail = async(id: string)=> {
    const {code, data} = await GetQueryListDetails({ id })
    if(code === 0 && data) {
      if (typeof data.url === 'string' && data.url !== 'null') {
        const urls = data.url.split(',')
        setThumbs(urls)
        setThumb(urls[0])
      } else {
        setThumb('')
        setThumbs([])
      }
      setFormSet(data)
    }
  }

  useEffect(()=> {
    if(records[index]) {
      if(current) {
        fetchDetail(records[index].id)
      }
    }
  }, [index])

  // 处理上一条下一条数据
  const recordControl = async (step: number)=> {
    // 第1条
    if(step === -1 && last === 0) {
      return
    }
    // 最后一条
    if(step === 1 && last === 1) {
      return
    }

    let currentIndex = index + step;

    // 未涉及到翻页
    if(records[currentIndex]) {
      setIndex(currentIndex)
      setCurrent(records[currentIndex])
      setLast(makeLast(currentIndex, total, pageNum, options.pageSize))      
      return
    }

    setQueryLoading(step)

    const pageNo = pageNum + step

    // 当前索引
    currentIndex = step === 1 ? 0 : options.pageSize - 1
    const [a, b] = await fetcChallList(pageNo, options.pageSize, options.condition)
    
    setQueryLoading(0)
    setTotal(a)
    setRecords(b)
    setCurrent(b[currentIndex])
    
    setPageNum(pageNo)
    setIndex(currentIndex)
    setLast(makeLast(step === 1 ? currentIndex : 0-currentIndex, a, pageNo, options.pageSize))    
  }

  const afterChange =(i: number)=> {
    setThumb(thumbs[i])
  }

  const onConfirm = ()=> {
    Modal.confirm({
      title: '确定关闭质疑',
      icon: <ExclamationCircleOutlined />,
      content: '关闭质疑后不可再重新质疑，当前如有输入内容会被放弃，是否确认关闭？',
      okText: '确认',
      cancelText: '取消',
      onOk: async function() {
        if (!current) {
          return
        }
        let params = {
            id: current.id,
            rejectReason: ""
        }
        const { code } = await CloseImageQuestion(params)

        if (code === 0) {
          setCurrent({
            ...current,
            questionStatus: '3',
          })
          fetchDetail(current.id)
  
          refresh.current = true
  
          messager.success('已关闭质疑！')
        }
      },
    });
  }
 
  return (
    <Modal
      onCancel={()=> props.onClose(refresh.current)}
      destroyOnClose
      title={current?.label}
      width="90%"
      bodyStyle={{padding: 0}}
      open={true}
      footer={null}
      maskClosable={false}>
      <div className={modStyl.wrapper}>
        <div className={modStyl.main}>
          <div className={modStyl.thumbs}>
            <Slider
              arrows={true}
              className="datacheck-detail-modal-slider"
              slidesToShow={8}
              swipeToSlide={true}
              focusOnSelect={true}
              dots={false}
              infinite={false}
              adaptiveHeight={false}>
                {
                  thumbs.map((url, i)=> (
                    <img onClick={()=> afterChange(i)} key={i} src={url+'?x-oss-process=image/resize,w_82,h_50,limit_0,m_fill'} alt={url}/>
                  ))
                }
            </Slider>
          </div>
          <div className={modStyl.preview}>
            {
              thumb ? <img src={thumb} alt={thumb}/> : <span>该质疑无图片</span>
            }
          </div>
          <div className={modStyl.steps}>
            <Button loading={queryLoading === -1} disabled={last === 0} onClick={()=> recordControl(-1)} shape="round"><ArrowLeftOutlined/>上一条</Button>
            <Button loading={queryLoading === 1} disabled={last === 1} onClick={()=> recordControl(1)} shape="round" style={{marginLeft: 20}}>下一条<ArrowRightOutlined/></Button>
          </div>
        </div>
        <div className={modStyl.east}>
          {
            formSet && current && (
              <div className={modStyl.info}>
                <Current data={formSet}/>
                <Descriptions title="质疑信息" bordered size="small" column={2} style={{marginBottom: 10}}>
                  <Descriptions.Item label="质疑文本">{formSet.questionText}</Descriptions.Item>
                  <Descriptions.Item label="质疑人">{formSet.questionUserName}</Descriptions.Item>
                  <Descriptions.Item label="质疑时间">{formSet?.questionTime?.replace('T', ' ')}</Descriptions.Item>
                  <Descriptions.Item label="质疑状态">
                    <span data-status={current.questionStatus} className={modStyl.status}>{formSet.questionStatus}</span>
                  </Descriptions.Item>
                </Descriptions>
                <h2>操作记录</h2>
                <div className={modStyl.records} style={{height: current.questionStatus === '3' ? 360 : 315}}>
                  {
                    formSet && (
                      <Timeline reverse={true}>
                        {
                          formSet.list.map((item, index) => (
                            <Timeline.Item key={index} color="gray">
                              <Block userName={userName} collector={formSet.collector} item={item} />
                            </Timeline.Item>
                          ))
                        }
                        {
                          visible && (
                            <Timeline.Item key={index} color="gray">
                              <Form id={current.id} onCancel={(r: boolean)=> {
                                setVisible(false)
                                refresh.current = r
                              }} />
                            </Timeline.Item>
                          )
                        }
                      </Timeline>
                    )
                  }
                </div>
              </div>
            )
          }
          {
            formSet && formSet.questionStatus !== '3' && formSet.questionActions.length > 0 && (
              <div className={modStyl.foot}>
                {
                  formSet.questionActions.includes('REJECT_REPLYING') && (
                    <Button onClick={()=> setVisible(true)} loading={loading} shape="round" style={{marginRight: 10}}>补充质疑</Button>
                  )
                }
                {
                  formSet.questionActions.includes('COLSE_QUESTION') && (
                    <Button onClick={onConfirm} loading={loading} type="primary" shape="round" style={{marginRight: 10}}>关闭质疑</Button>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </Modal>
  )
}

export default DetailModal