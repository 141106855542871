
export const useFetchStatus = ()=> {
  const statusOptions = [
    {label: '全部', value: ''},
    {label: '待审核', value: 'RUNNING'},
    {label: '已通过', value: 'AGREE'},
    {label: '质疑待回复', value: 'REJECT_REPLYING'},
    {label: '质疑待确认', value: 'REJECT_COMPLETING'},
    {label: '审核撤销', value: 'RESET'},
  ]

  const statusMap = new Map(statusOptions.map(({label, value})=> [value, label]))

  return {
    statusOptions,
    statusMap,
  }
}
