/**
 * 数据中心服务接口
 * meixin
 */
import {
  requestPostRes,
  requestGetRes,
  requestPatchRes,
  requestPostFileRes,
  requestPostFile,
} from "../EdcService";

/**
 * 患者上传记录查询
 * @param {} params
 */
export const getReportedById = (params) =>
  requestGetRes(`/data/report/record/findByCodeAndVisitId`, params);

/**
 * 患者绑定信息查询
 * @param {} params
 */
export const getSubjectBindState = (subjectCode) =>
  requestGetRes(`/data/report/subject/binding/info/${subjectCode}`);

/**
 * 患者触发绑定前置动作
 * @param {} params
 */
export const getSubjectBinding = (data) =>
  requestPostRes(`/data/report/subject/scancode/binding`, data);

/**
 * 患者解绑
 * @param {} params
 */
export const subjectUnbind = (subjectCode) =>
  requestGetRes(`/data/report/subject/unbind`, { subjectCode });

/**
 * 根据量表code和受试者编码，获取随访list
 * @param {} params
 */
export const getVisitsByScaleCode = ({ scaleCode, subjectCode }) =>
  requestPostRes(
    `/data/report/subject/scale/visit?scaleCode=${scaleCode}&subjectCode=${subjectCode}`
  );

// 自报提醒
export const remindBySms = (data) => requestPostRes(`/data/report/remind/sms`, data);

// 重报提醒
export const restatement = (data) => requestPostRes(`/data/report/record/restatement`, data);

// 扫码填写问卷
export const scanExamCode = (data) => requestPostRes(`/data/report/scanCode/url`, data);

// 修改受试者信息
export const updateMobile = (data) => requestPostRes(`/data/report/subject/updateMobile`, data);

// 检查提醒、用药提醒
export const remindSubject = (data) => requestPostRes(`data/report/remindSubject/sms`, data);

// 标记量表已查看
export const updateReadStatus = (scaleId, subjectCode)=> requestPostRes(`data/report/View/scale/${scaleId}?subjectCode=${subjectCode}`)

//
export const getUnbindRecord = (subjectCode)=> requestGetRes(`data/report/unbind/record?subjectCode=${subjectCode}`)

// 上传量表图片
export const scaleUpload = (data)=> requestPostRes(`data/report/scale/upload`, data);

