import React from "react"
import locale from "antd/es/date-picker/locale/zh_CN"
import { Button, Cascader, Col, DatePicker, Form, Input, Row, Select } from "antd"
import { useEffect, useState } from "react"
import modStyl from './Condition.module.scss'
import { Moment } from "moment"
import utils from "../../../../util/utils"
import { useFetchStatus } from "../repo"
import { transformVisitData } from "../../project/cra/utils"
import { useFetchCenter, useFetchProject, useFetchVisitTree } from "../../project/cra/repo"

export const StatusEnum = {
  "": "全部",
  RUNNING: '待审核',
  AGREE: '已通过',
  REJECT_REPLYING: '质疑待回复',
  REJECT_COMPLETING: '质疑待确认',
  RESET: '审核撤销',
}

export interface Payload {
  projectCode?: string
  centerName?: string
  subjectCode?: string
  createUser?: string
  approveStatus?: string
  startTime?: string
  endTime?: string
  // 访视&表单
  visit?: {name: string, formName: string[]}[]
}


const colCols = {
  span: 6
}
const fieldCols = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 19
  }
}

type ConditionProps = {
  statusLabel?: string
  betweenAtLabel?: string
  statusOptions: {label: string, value: string}[]
  onMoreChange?: (value: boolean)=> void
  onSearch: (params?: Payload)=> void
}

// 图片审核和数据采集共用筛选条件
const Condition: React.FC<ConditionProps> = ({ statusOptions = [], statusLabel='审核状态', betweenAtLabel = '审核时间', onSearch, onMoreChange })=> {

  const [ form ] = Form.useForm()

  const [moreCondition, showMoreCondition] = useState(false)

  // 访视&表单
  const [disabledCascader, setDisabledCascader] = useState(true)
  const { fetchVisitTreeData, visitFetching, visitTreeOptions, setVisitTreeOptions} = useFetchVisitTree()

  // 中心列表
  const { fetchCenterData, centerFetching, centerOptions } = useFetchCenter()
  
  // 项目列表
  const { fetchProjectData, projectFetching, projectOptions } = useFetchProject()

  const targetName = sessionStorage.getItem('targetName') || '受试者'

  const handleProjectChange = (value?: string)=> {
    form.setFieldValue('visit', undefined)
    setVisitTreeOptions([])
    setDisabledCascader(typeof value === 'undefined')
  }

  const takePayload = (): Payload=> {
    const { visit, betweenAt, ...rest } = form.getFieldsValue()
    const parsed = transformVisitData(visit)

    const part = {
      startTime: '', 
      endTime: ''
    }
    if (Array.isArray(betweenAt)) {
      const [startTime, endTime] = betweenAt as Moment[]
      part.startTime = startTime.format('YYYY-MM-DD')
      part.endTime = endTime.format('YYYY-MM-DD')
    }

    return {
      ...rest,
      ...part,
      visit: parsed,
    }
  }

  const submitControl = ()=> {
    onSearch(takePayload())
  }

  const resetControl = ()=> {
    form.resetFields()
    if (projectOptions.length === 1) {
      form.setFieldValue('projectCode', projectOptions[0].value)
    }
    setVisitTreeOptions([])
    setDisabledCascader(true)
    onSearch()
  }

  const handleFetchCenterData = (open: boolean)=> {
    if (open && centerOptions.length === 0) {
      fetchCenterData()
    }
  }

  const handleFetchVisitTree = (open: boolean)=> {
    if (open && visitTreeOptions.length === 0) {
      fetchVisitTreeData(form.getFieldValue('projectCode'))
    }
  }

  const exportControl = ()=> {
    utils.exportPostFun('dct/taskDistribute/image/downLoadImagetList', takePayload())
  }
  
  useEffect(()=> {
    fetchProjectData().then((projectCode)=> {
      if ('' !== projectCode) {
        setDisabledCascader(false)
        form.setFieldValue('projectCode', projectCode)
      }
      onSearch({
        projectCode,
      })
    })
  }, [])

  return (
    <div className={modStyl.wrapper}>
      <Form onFinish={submitControl} form={form}>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="projectCode" label="所属项目">
              <Select
                onChange={handleProjectChange}
                loading={projectFetching}
                options={projectOptions}
                optionFilterProp="label"
                placeholder="请选择"
                showSearch
                allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="centerName" label="所属中心">
              <Select
                onDropdownVisibleChange={handleFetchCenterData}
                loading={centerFetching}
                options={centerOptions}
                maxTagCount={1}
                optionFilterProp="label"
                placeholder="请选择"
                showSearch
                allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
          <Form.Item {...fieldCols} name="visit" label="访视/表单">
            <Cascader
              onDropdownVisibleChange={handleFetchVisitTree}
              options={visitTreeOptions}
              disabled={disabledCascader}
              loading={visitFetching}
              fieldNames={{label: 'formName', value: 'formName'}}
              placeholder="请选择"
              maxTagCount={1}
              multiple
              allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Button htmlType="submit" type="primary">搜索</Button>
            <Button onClick={resetControl} style={{marginLeft: 10}}>重置</Button>
            <Button onClick={exportControl} style={{marginLeft: 10}} type="link">导出数据</Button>
            <Button onClick={()=> {
              showMoreCondition(!moreCondition)
              onMoreChange?.(!moreCondition)
            }} type="link">更多</Button>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="createUser" label="　采集人">
              <Input placeholder="请输入采集人" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="subjectCode" label={targetName}>
              <Input placeholder={'请输入'+targetName+'姓名缩写/编号查找'} allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="approveStatus" label={statusLabel}>
              <Select options={statusOptions} placeholder="请选择"/>
            </Form.Item>
          </Col>
        </Row>
        {
          moreCondition && (
            <Row gutter={16}>
              <Col {...colCols}>
                <Form.Item {...fieldCols} name="betweenAt" label={betweenAtLabel}>
                  <DatePicker.RangePicker style={{width: '100%'}} locale={locale} allowClear/>
                </Form.Item>
              </Col>
              <Col {...colCols}></Col>
            </Row>
          )
        }
      </Form>
    </div>
  )
}

export default Condition