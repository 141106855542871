import modStyl from './subjectInfo.module.scss'

import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button, FormInstance, message as messager } from 'antd';
import { EditFilled, InfoOutlined } from '@ant-design/icons';
import { getSubjectBindState, updateMobile } from '../../../../../../services/data/patientSelfReported'
import storageX, { Engine } from '../../../../../../util/storageX';
import TenantConfiguration from '../../../../../../util/tenantConfiguration';
import SubjectModal from './subject-modal';

//#region 
type EditButtonProps = {
  subjectCode: string;
  onClick: ()=> void;
}
const EditButton: React.FC<EditButtonProps> = ({subjectCode, onClick})=> {

  const [state, setState] = useState()
  
  const fetchInfo = async ()=> {
    const { data, code } = await getSubjectBindState(subjectCode);

    if(0 === code) {
      setState(data.bindState)
    }
  }
  
  const isShow = useMemo(()=> {
    const store = storageX(Engine.Session)
    const roleCode = store.get('roleCode') as string
    const projectRoleCode = store.get('projectRoleCode') as string
    // 未绑定并且为EDC_CRC角色账号有权限
    return state == 0  &&  roleCode ==  'EDC_CRC' && projectRoleCode !=='ReadOnly' 
  }, [state])

  useEffect(()=> {
    fetchInfo()
  }, [])
  
  if (isShow) {
    return (
      <Button size="small" type="primary" icon={<EditFilled/>} onClick={onClick}>修改</Button>
    )
  }

  return null
}
//#endregion

type Props = {
  mobile: string;
  subjectName: string;
  subjectCode: string;
  updateSubjectData: ()=> void;
}

// 手机号脱敏
const maskNumber = (num: string) =>{
  if(num){
    return num.replace(/(1\d{2})(\d{4})(\d{4})/, '$1****$3')
  }
  return '暂无'
}

const SubjectInfo:React.FC<Props> = ({mobile, subjectName, subjectCode, updateSubjectData})=> {

  const [visible, setVisible] = useState(false)
  const formRef = useRef<FormInstance>(null)

  const label = useMemo(()=> TenantConfiguration.read('label') as string, [])

  // 修改受试者信息
  const visibleModal = ()=>{
    setVisible(true)
    setTimeout(()=>{
      if(formRef.current) {
        formRef.current.setFieldsValue({ name: subjectName, mobile })
      }
    }, 200)
  }
  
  const handleClose = (changed: boolean)=> {
    setVisible(false)
    if (changed) {
      updateSubjectData()
    }
  }

  return (
    <>
      <div className={modStyl.container}>
        <div className={modStyl.header}>
            <div className={modStyl.west}>
              <InfoOutlined/>
              {label}信息
            </div>
            <div className={modStyl.east}>
              <EditButton onClick={visibleModal} subjectCode={subjectCode}/>
            </div>
        </div>
        <div className={modStyl.content}>
          <dl>
            <dt>手机号：</dt>
            <dd>{maskNumber(mobile)}</dd>
          </dl>
          <dl>
            <dt>姓名缩写：</dt>
            <dd>{subjectName ? subjectName : '---'}</dd>
          </dl>
        </div>
      </div>

      {/* 修改患者缩写 */}
      <SubjectModal onClose={handleClose} visible={visible} info={{subjectCode, name: subjectName, mobile}} />
    </>
  )
}

export default SubjectInfo
