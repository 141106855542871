import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import './index.less'
import { Button, Col, message, Pagination, Row, Tabs, Modal, Spin } from 'antd';
import { GetFormDataById, GetFormDataByIdInTenant, saveFormData, updateFormState, saveCrfForm, updateCrfData, GetApproveRecordForm } from '../../services/data/DataService';
import Control from './control/Control';
import OcrView from './com/ocrView/ocrView';
import { createFromIconfontCN, EllipsisOutlined, ExclamationCircleOutlined, WarningFilled } from '@ant-design/icons';
import localStorageUtil from '../../comm/localStorage';
import utils from './utils/utils';
import verify, { replaceFieldRule } from './utils/verify';
import CRFComponent from '../../components/crf_form/crf/crf';

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_yqqxbp19ayp.js',
    ],
});

const { TabPane } = Tabs;

const { confirm } = Modal;

interface Props {
    // 新编辑模式
    editNew?: boolean
    // 工作台入口
    workbench?: boolean
}

function Form(props: any, ref: any) {
    //word选中状态
    const [isWord, setIsWord] = useState<boolean>(true);
    //ocr选中状态
    const [isOcr, setIsOcr] = useState<boolean>(false);
    //表单组件
    const formControlRef = useRef<any>(null);
    //tabs
    const [tabs, setTabs] = useState<any>([]);
    //设置当前选择的tab id
    const [selectTabId, setSelectTabId] = useState<string>();
    //当前tabs的索引
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
    //form数据
    const [formData, setFormData] = useState<any>();
    //formActions
    const [formActions, setFormActions] = useState<any>([]);
    //dataActions
    const [dataActions, setDataActions] = useState<any>([]);
    //是否显示操作按钮
    const [showFormBtn, setShowFormBtn] = useState<boolean>(false);
    //OCR图片
    const [ocrArr, setOcrArr] = useState<any>([]);
    //是否为提交中
    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    //暂存事件
    const [savedTime, setSavedTime] = useState<number>(60000);
    //loading状态
    const [loading, setLoading] = useState<boolean>(false);
    // 保存按钮状态
    const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);

    let subjectsState = sessionStorage.getItem('subjectsState');

    // crf 表单初始化
    const [crfParams, setCrfParams] = useState<any>({});
    // crf 表单只读s
    const [crfReadonly, setCrfReadonly] = useState<any>(false);
    // crf 表单保存
    const [crfSaveData, setCrfSaveData] = useState<any>(null);
    const CrfRef = useRef<any>(null);
    let crfSaveType: any = '';

    //初始化
    useEffect(() => {
        let localStorage_forms = localStorage.getItem('localStorage_forms');
        if (localStorage_forms) {
            let forms = JSON.parse(localStorage_forms);
            let tabs = forms.forms;
            let activeId = forms.activeId;
            let state = forms.state;
            
            setTabs(tabs);
            setSelectTabId(activeId);
            //设置当前tab的索引
            setCurrentTabIndex(tabs.findIndex((item: any) => {
                return item.id === activeId;
            }))
     
            if (props.crfParams && state == 'Complete') {
                setCrfReadonly(true)
            }

            
        }

        // crf表单
        if (props.crfParams) {
            setCrfParams(props.crfParams)
        }

    }, [])

    //当tab的id发生改变时
    useEffect(() => {
        updateData();
        // setSaveBtnDisabled(true);
    }, [selectTabId])


    //通过表单id更新数据
    const updateData = () => {
        if (selectTabId) {
            let params = { id: selectTabId }
            setLoading(true);
            let getFormDataFunc = GetFormDataById;
            // 超级项目经理，查看其他租户的信息
            if (props.fromCaseAudit) {
                // @ts-ignore
                params = { id: selectTabId, tenantCode: props.tenantCode };
                getFormDataFunc = GetFormDataByIdInTenant;
            }
            // 审核操作记录
            if (props.fromApproveRecord) {
                // @ts-ignore
                params = { id: selectTabId, tenantCode: props.tenantCode };
                getFormDataFunc = GetApproveRecordForm;
            }

            getFormDataFunc(params).then(({data, code}) => {
                setLoading(false);
                if (code !== 0 || !data) {
                    setFormData({})
                    setShowFormBtn(false);
                    return
                }
                
                //如果是数据审计菜单打开的crf，表单内容不可修改
                if (props.crfUseType == 'dataAudit' || props.fromCaseAudit) {
                    data.formActions = ['FormActionRead']
                    // res.data.dataActions = []  字段行为控制由接口获取，拒绝手动操作置空
                    data.ocrFieldList = []
                }

                // 如果新模式的编辑不包含提交按钮，一律加上
                const formActionsCopy = data.formActions || []
                if((props.editNew === 1 || props.editModel === 1) && !formActionsCopy.includes('FormActionSubmit')){
                    data.formActions = [...formActionsCopy, 'FormActionSubmit'];
                }
                
                setFormData(data);

                data.formActions && setFormActions(data.formActions);
                data.ocrFiles && setOcrArr(data.ocrFiles);

                // fix 君实表单 入组筛选失败
                if (sessionStorage.getItem('forceReadonly')) {
                    setShowFormBtn(false)
                    return
                }

                let formActions = data.formActions || [];

                if (Array.isArray(data.formActions) && data.formActions.length === 0) {
                    setShowFormBtn(false);
                    return
                }
                
                setFormActions(formActions);
                
                let filterArr = ['FormActionSubmit', 'FormActionQuestion', 'FormActionExamine', 'FormActionFreeze', 'FormActionAutograph', 'FormActionLock', 'FormActionCancelQuestion', 'FormActionCancelExamine', 'FormActionCancelFreeze', 'FormActionCancelAutograph', 'FormActionCancelLock'];

                //判断是否存在需要操作的函数
                let judgeFun = () => {
                    for (let item of formActions) {
                        if(filterArr.includes(item)) {
                            return true
                        }
                    }
                    return false
                }
                setShowFormBtn(judgeFun());
            })
        }
    }

    //当tab切换时
    const onTabChange = (key: any) => {

        // crf
        if (props.crfParams) {
            CrfRef.current.unloadApp()
            crfParams.recordCode = tabs[key].id
            crfParams.crfCode = tabs[key].formCode
            setCrfParams(crfParams)
        }

        //重置状态
        resetState()
        let localStore_formData = sessionStorage.getItem('localStore_formData');
        let currentRole = sessionStorage.getItem('roleCode');
        let projectRoleCode = sessionStorage.getItem('projectRoleCode');
        if (localStore_formData) {
            let formDataArr = JSON.parse(localStore_formData);
            let formData = formDataArr.find((item: any) => {
                return item.formId === selectTabId
            })
            if (formData) {
                if (formData.init === false && !(subjectsState === 'Stop' || subjectsState === 'Pause') && !(props.crfUseType == 'dataAudit' || props.fromCaseAudit) && projectRoleCode != 'ReadOnly' && currentRole != 'EDC_READ') {
                    confirm({
                        title: '您有未保存的表单数据！',
                        icon: <ExclamationCircleOutlined />,
                        content: '是否暂存该表单数据？',
                        cancelText: "取消",
                        okText: "确定",
                        centered: true,
                        onOk() {
                            if (formControlRef.current) {
                                const {body, ...params} = formControlRef.current.getSavedForm();
                                params.submit = false;
                                setLoading(true);
                                saveFormData(params).then(res => {
                                    if (res.code === 0) {
                                        setFormData(null);
                                        //设置当前tab索引
                                        setCurrentTabIndex(parseInt(key));
                                        //设置当前tab的id
                                        setSelectTabId(tabs[key].id);
                                        //删除该表单的缓存数据
                                        deleteFormData()
                                    }
                                    setIsSubmit(false);
                                    setLoading(false);
                                })
                            }
                        },
                        onCancel() {
                            setFormData(null);
                            //设置当前tab索引
                            setCurrentTabIndex(parseInt(key));
                            //设置当前tab的id
                            setSelectTabId(tabs[key].id);
                            //删除该表单的缓存数据
                            deleteFormData()
                        },
                    });
                }
                else {
                    setFormData(null);
                    //设置当前tab索引
                    setCurrentTabIndex(parseInt(key));
                    //设置当前tab的id
                    setSelectTabId(tabs[key].id);
                }
            }
            else {
                setFormData(null);
                //设置当前tab索引
                setCurrentTabIndex(parseInt(key));
                //设置当前tab的id
                setSelectTabId(tabs[key].id);
            }
        }
        else {
            setFormData(null);
            //设置当前tab索引
            setCurrentTabIndex(parseInt(key));
            //设置当前tab的id
            setSelectTabId(tabs[key].id);
        }
    }

    //当分页切换时
    const onPageChange = (i: any) => {
        //重置状态
        resetState()
        let localStore_formData = sessionStorage.getItem('localStore_formData');
        let currentRole = sessionStorage.getItem('roleCode');
        let projectRoleCode = sessionStorage.getItem('projectRoleCode');
        if (localStore_formData) {
            let formDataArr = JSON.parse(localStore_formData);
            let formData = formDataArr.find((item: any) => {
                return item.formId === selectTabId
            })
            if (formData) {
                if (formData.init === false && !(subjectsState === 'Stop' || subjectsState === 'Pause') && !(props.crfUseType == 'dataAudit' || props.fromCaseAudit) && projectRoleCode != 'ReadOnly' && currentRole != 'EDC_READ') {
                    confirm({
                        title: '您有未保存的表单数据！',
                        icon: <ExclamationCircleOutlined />,
                        content: '是否暂存该表单数据？',
                        cancelText: "取消",
                        okText: "确定",
                        centered: true,
                        onOk() {
                            if (formControlRef.current) {
                                const {body, ...params} = formControlRef.current.getSavedForm();
                                // 如果是特殊编辑
                                if((props.editNew === 1 || props.editModel == 1) && formActions.includes("FormActionSubmit")){
                                    params.submit = true;
                                }else{
                                    params.submit = false;
                                }
                                setLoading(true);
                                saveFormData(params).then(res => {
                                    if (res.code === 0) {
                                        setFormData(null);
                                        //设置当前tab索引
                                        setCurrentTabIndex(i - 1);
                                        //设置当前tab的id
                                        setSelectTabId(tabs[i - 1].id);
                                        //删除该表单的缓存数据
                                        deleteFormData()
                                    }
                                    setIsSubmit(false);
                                    setLoading(false);
                                })
                            }
                        },
                        onCancel() {
                            setFormData(null);
                            //设置当前tab索引
                            setCurrentTabIndex(i - 1);
                            //设置当前tab的id
                            setSelectTabId(tabs[i - 1].id);
                            //删除该表单的缓存数据
                            deleteFormData()
                        },
                    });
                }
                else {
                    setFormData(null);
                    //设置当前tab索引
                    setCurrentTabIndex(i - 1);
                    //设置当前tab的id
                    setSelectTabId(tabs[i - 1].id);
                }
            }
            else {
                setFormData(null);
                //设置当前tab索引
                setCurrentTabIndex(i - 1);
                //设置当前tab的id
                setSelectTabId(tabs[i - 1].id);
            }
        }
        else {
            setFormData(null);
            //设置当前tab索引
            setCurrentTabIndex(i - 1);
            //设置当前tab的id
            setSelectTabId(tabs[i - 1].id);
        }
    }

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        closeModal: () => {
            closeModal();
        },
    }));

    // 关闭弹框
    const closeModal = () => {
        let currentRole = sessionStorage.getItem('roleCode');
        let projectRoleCode = sessionStorage.getItem('projectRoleCode');
        // crf表单
        if (props.crfParams) {
            if (!crfReadonly && projectRoleCode != 'ReadOnly' && currentRole != 'EDC_READ') {
                confirm({
                    title: '您有未保存的表单数据！',
                    icon: <ExclamationCircleOutlined />,
                    content: '是否确定关闭？',
                    cancelText: "取消",
                    okText: "确定",
                    centered: true,
                    onOk() {
                        props.closeModal();
                    },
                });
                return
            }

            // 卸载crf微应用
            CrfRef.current.unloadApp()
            props.closeModal();
            return
        }

        //重置状态
        resetState();
        let localStore_formData = sessionStorage.getItem('localStore_formData');
        if (localStore_formData) {
            let formDataArr = JSON.parse(localStore_formData);
            let formData = formDataArr.find((item: any) => {
                return item.formId === selectTabId
            })
            if (formData) {
                if (formData.init === false && !(subjectsState === 'Stop' || subjectsState === 'Pause') && !(props.crfUseType == 'dataAudit' || props.fromCaseAudit) && projectRoleCode != 'ReadOnly' && currentRole != 'EDC_READ') {
                    confirm({
                        title: '您有未保存的表单数据！',
                        icon: <ExclamationCircleOutlined />,
                        content: '是否暂存该表单数据？',
                        cancelText: "取消",
                        okText: "确定",
                        centered: true,
                        onOk() {
                            if (formControlRef.current) {
                                const {body, ...params} = formControlRef.current.getSavedForm();
                                // 如果是特殊编辑，包含提交按钮
                                if((props.editNew === 1 || props.editModel === 1) && formActions.includes("FormActionSubmit")){
                                    params.submit = true;
                                }else{
                                    params.submit = false;
                                }
                                setLoading(true);
                                saveFormData(params).then(res => {
                                    if (res.code === 0) {
                                        //删除该表单的缓存数据
                                        props.closeModal();
                                        sessionStorage.removeItem('localStore_formData');

                                    }
                                    setIsSubmit(false);
                                    setLoading(false);
                                })
                            }
                        },
                        onCancel() {
                            //删除该表单的缓存数据
                            props.closeModal();
                            sessionStorage.removeItem('localStore_formData');
                        },
                    });
                }
                else {
                    sessionStorage.removeItem('localStore_formData');
                    props.closeModal();
                }
            }
            else {
                sessionStorage.removeItem('localStore_formData');
                props.closeModal();
            }
        }
        else {
            sessionStorage.removeItem('localStore_formData');
            props.closeModal();
        }
    }


    //处理group类型规则
    const dealValidationRulesGroup = (data: any) => {
        let validationRules: any = []
        data.forEach((item: any) => {
            if (item.children) {
                let _data = dealValidationRulesGroup(item.children)
                validationRules = [...validationRules, ..._data]
            } else {
                validationRules.push(item)
            }
        });
        return validationRules
    }

    const filterNoVaildData = (validationRulesAll: any, savedValues: any) => {
        const vaildDataArr: any = [];
        validationRulesAll.forEach((item: any) => {
            if (item.display && item.display.type === 'ValueCascade') {

                if (item.display.condition === 'eq') {
                    //当前选中表单已存的value
                    let currentItem = savedValues.find((i: any) => {
                        return i.dataCode == item.display.target
                    });
                    if (currentItem) {
                        if ((currentItem.value && currentItem.value.indexOf(item.display.rule) !== -1) || (currentItem.value && currentItem.value === item.display.rule)) {
                            vaildDataArr.push(item)
                        }
                    }
                }
                // else if (item.display.condition === 'andEq') {
                //     let judgeArr = [];
                //     for (let i = 0; i < item.display.target.length; i++) {
                //         let findedItem = savedValues.find((valueItem: any) => {
                //             return valueItem.dataCode === item.display.target[i]
                //         });
                //         if (findedItem) {
                //             if (findedItem.value instanceof Array) {
                //                 if (findedItem.value.indexOf(item.display.rule[i]) === -1) {
                //                     judgeArr.push(false);
                //                 }
                //                 else {
                //                     judgeArr.push(true);
                //                 }
                //             }
                //             else {
                //                 if (findedItem.value !== item.display.rule[i]) {
                //                     judgeArr.push(false);
                //                 }
                //                 else {
                //                     judgeArr.push(true);
                //                 }
                //             }
                //         }
                //         else {
                //             judgeArr.push(false)
                //         }
                //     }
                //     if (judgeArr.every(item => {
                //         if (item === true) {
                //             return true
                //         }
                //     })) {
                //           vaildDataArr.push(item)
                //     }
                // }
                // else if (item.display.condition === 'orEq') {
                //     let judgeArr = [];
                //     for (let i = 0; i < item.display.target.length; i++) {
                //         let findedItem = savedValues.find((valueItem: any) => {
                //             return valueItem.dataCode === item.display.target[i]
                //         });
                //         if (findedItem) {
                //             if (findedItem.value instanceof Array) {
                //                 if (findedItem.value.indexOf(item.display.rule[i]) === -1) {
                //                     judgeArr.push(false);
                //                 }
                //                 else {
                //                     judgeArr.push(true);
                //                 }
                //             }
                //             else {
                //                 if (findedItem.value !== item.display.rule[i]) {
                //                     judgeArr.push(false);
                //                 }
                //                 else {
                //                     judgeArr.push(true);
                //                 }
                //             }
                //         }
                //         else {
                //             judgeArr.push(false)
                //         }
                //     }
                //     if (judgeArr.indexOf(true) !== -1) {
                //          vaildDataArr.push(item)
                //     }
                // }

            } else {
                vaildDataArr.push(item)
            }
        });

        return vaildDataArr;
    }

    //当提交时
    const onSubmit = () => {

        // 范围值非空处理 !!!以下校验有效：范围组件同一表单只能存在1个有效
        // let inputrange_data = sessionStorage.getItem('inputrange_data');
        // if (inputrange_data) {
        //     let inputrangeData = JSON.parse(inputrange_data);
        //     let l = inputrangeData.label;
        //     let v = inputrangeData.values;
        //     if (v && v.length > 0) {
        //         if (v[0] == '' && v[1] == '' && v[2] == '') {
        //             message.error(`请输入${l}！`)
        //             return
        //         }
        //         else if (v[0] == '') {
        //             message.error(`请输入${l}最小值！`)
        //             return
        //         }
        //         else if (v[1] == '') {
        //             message.error(`请输入${l}最大值！`)
        //             return
        //         }
        //         else if (v[2] == '') {
        //             message.error(`请输入${l}单位名称！`)
        //             return
        //         }
        //     }
        // }

        // crf
        if (props.crfParams) {
            crfSaveType = 'crf_submit'
            CrfRef.current.handleSubmit()
            return
        }

        //重置状态
        resetState();
        if (formControlRef.current) {
            const {body, ...params} = formControlRef.current.getSavedForm();
            // 提交时进行校验
            let validationRules: any = [];
            body.forEach((element: any) => {
                validationRules.push(element.children);
                // todo children可能有多层
            });
            
            // 铺平
            let validationRulesAll: any = [];
            validationRules.forEach((outVal: any) => {
                outVal.forEach((inVal: any) => {
                    validationRulesAll.push(inVal);
                });
            });

            validationRulesAll = dealValidationRulesGroup(validationRulesAll);

            //过滤到不需要校验的数据，一些不显示的数据二级数据
            validationRulesAll = filterNoVaildData(validationRulesAll, params.values);

            // feature: 处理number字段和number字段数值比较
            validationRulesAll.forEach(({ validationRules }: any)=> {
                replaceFieldRule(validationRules, params.values)
            })

            const notValid = validationRulesAll.some((val: any) => {
                // 匹配的数据
                let value = (params.values.find((v: any) => v.dataCode == val.dataCode));
                if (!value) {
                    return;
                }

                let judge = verify.checkValueByRange(value.value, val);
                let judge1 = verify.checkValueByRep(value.value, val);
                if (!judge || !judge1) {
                    if (!judge1) {
                        message.info('请输入正确的格式！', 1)
                    }
                    return true;
                }
                let isEmpty = verify.isEmpty(value.value, val);
                if (isEmpty) {
                    message.info(val.label + '需填写或选择完整', 1);
                    return true;
                }
            })
            if (notValid) {
                return;
            }
            
            setIsSubmit(true);
            saveFormData(params).then(res => {
                if (res.code === 0) {
                    setFormData(null);
                    if (tabs && tabs.length === 1) {
                        props.closeModal(true);
                    }
                    else if (tabs && tabs.length > 1) {
                        let nextTabIndex = currentTabIndex + 1;
                        if (nextTabIndex === tabs.length) {
                            props.closeModal(true);
                        }
                        else if (nextTabIndex < tabs.length) {
                            setSelectTabId(tabs[nextTabIndex].id);
                            setCurrentTabIndex(nextTabIndex);
                        }
                    }
                    //删除该表单的缓存数据
                    deleteFormData()
                    setIsSubmit(false);

                
                    return
                }else{
                    // 提交失败刷新页面
                    // setLoading(true);
                    // let params = { id: selectTabId }
                    // GetFormDataById(params).then(res => {
                    //     if (res.code === 0 && res.data) {
                    //         setFormData(res.data);
                    //     }
                    //     setLoading(false);
                    // })
                    // setLoading(false);
                }

                // 跳过错误类型 该受试者信息已存在，重复受试者编码为：[QLXH200015]
                if(res.code === 50031411) {
                    setIsSubmit(false);
                    return
                }
            
                // let localStore_formData = sessionStorage.getItem('localStore_formData');
                // if (localStore_formData) {
                //     let formDataArr = JSON.parse(localStore_formData);
                //     let formData = formDataArr.find((item: any) => {
                //         return item.formId === selectTabId
                //     })
                //     if (formData) {
                //         formData.init = true;
                //         sessionStorage.setItem('localStore_formData', JSON.stringify(formDataArr));
                //         let params = {
                //             formId: formData.formId,
                //             submit: false,
                //             values: formData.values
                //         }
                //         //暂存
                //         setLoading(true);
                //         saveFormData(params).then(res => {
                //             //更新
                //             setLoading(true);
                //             let params = { id: selectTabId }
                //             GetFormDataById(params).then(res => {
                //                 if (res.code === 0 && res.data) {
                //                     setFormData(res.data);
                //                 }
                //                 setLoading(false);
                //             })
                //             setLoading(false);
                //         })
                //     }
                // }
                 setIsSubmit(false);
            })
        }
    }

    //删除本地缓存为该formID的表单缓存
    const deleteFormData = () => {
        let localStore = sessionStorage.getItem('localStore_formData');
        if (localStore) {
            let formDataArr = JSON.parse(localStore);
            if (formDataArr.length > 1) {
                let index = formDataArr.findIndex((item: any) => {
                    return item.formId === selectTabId
                })
                if (index !== -1) {
                    formDataArr.splice(index, 1)
                }
                sessionStorage.setItem('localStore_formData', JSON.stringify(formDataArr))
            }
            else {
                sessionStorage.removeItem('localStore_formData');
            }
        }
    }

    //通过人工核查
    const onQuestion = () => {
        let params = {
            action: 'FormActionQuestion',
            formId: selectTabId
        }
        updateFormState(params).then(res => {
            if (res.code === 0) {
                updateData();
                message.success('通过人工核查成功！', 1);
            } else {
                if (res.message) {
                    message.error(res.message, 1)
                }
                else {
                    message.error('通过人工核查失败！', 1)
                }
            }
        })
    }

    //取消人工核查
    const onCancelQuestion = () => {
        let params = {
            action: 'FormActionCancelQuestion',
            formId: selectTabId
        }
        updateFormState(params).then(res => {
            if (res.code === 0) {
                updateData();
                message.success('取消人工核查成功！', 1);
            } else {
                if (res.message) {
                    message.error(res.message, 1)
                }
                else {
                    message.error('取消人工核查失败！', 1)
                }
            }
        })
    }

    //数据核查
    const onExamine = () => {
        let params = {
            action: 'FormActionExamine',
            formId: selectTabId
        }
        updateFormState(params).then(res => {
            if (res.code === 0) {
                updateData();
                message.success('通过数据核查', 1);
            } else {
                if (res.message) {
                    message.error(res.message, 1)
                }
                else {
                    message.error('通过数据核查失败！', 1)
                }
            }
        })
    }

    //取消数据核查
    const onCancelExamine = () => {
        let params = {
            action: 'FormActionCancelExamine',
            formId: selectTabId
        }
        updateFormState(params).then(res => {
            if (res.code === 0) {
                updateData();
                message.success('取消数据核查成功', 1);
            } else {
                if (res.message) {
                    message.error(res.message, 1)
                }
                else {
                    message.error('取消数据核查失败！', 1)
                }
            }
        })
    }

    //冻结
    const onFreeze = () => {
        let params = {
            action: 'FormActionFreeze',
            formId: selectTabId
        }
        updateFormState(params).then(res => {
            if (res.code === 0) {
                updateData();
                message.success('冻结成功', 1);
            } else {
                if (res.message) {
                    message.error(res.message, 1)
                }
                else {
                    message.error('冻结失败！', 1)
                }
            }
        })
    }

    //取消冻结
    const onCancelFreeze = () => {
        let params = {
            action: 'FormActionCancelFreeze',
            formId: selectTabId
        }
        updateFormState(params).then(res => {
            if (res.code === 0) {
                updateData();
                message.success('取消冻结成功', 1);
            } else {
                if (res.message) {
                    message.error(res.message, 1)
                }
                else {
                    message.error('取消冻结失败！', 1)
                }
            }
        })
    }

    //签名
    const onAutograph = () => {
        let params = {
            action: 'FormActionAutograph',
            formId: selectTabId
        }
        updateFormState(params).then(res => {
            if (res.code === 0) {
                updateData();
                message.success('签名成功', 1);
            } else {
                if (res.message) {
                    message.error(res.message, 1)
                }
                else {
                    message.error('签名失败！', 1)
                }
            }
        })
    }

    //取消签名
    const onCancelAutograph = () => {
        let params = {
            action: 'FormActionCancelAutograph',
            formId: selectTabId
        }
        updateFormState(params).then(res => {
            if (res.code === 0) {
                updateData();
                message.success('取消签名成功', 1);
            } else {
                if (res.message) {
                    message.error(res.message, 1)
                }
                else {
                    message.error('取消签名失败！', 1)
                }
            }
        })
    }

    //锁定
    const onLock = () => {
        let params = {
            action: 'FormActionLock',
            formId: selectTabId
        }
        updateFormState(params).then(res => {
            if (res.code === 0) {
                updateData();
                message.success('锁定成功', 1);
            } else {
                if (res.message) {
                    message.error(res.message, 1)
                }
                else {
                    message.error('锁定失败！', 1)
                }
            }
        })
    }

    //取消锁定
    const onCancelLock = () => {
        let params = {
            action: 'FormActionCancelLock',
            formId: selectTabId
        }
        updateFormState(params).then(res => {
            if (res.code === 0) {
                updateData();
                message.success('解锁成功', 1);
            } else {
                if (res.message) {
                    message.error(res.message, 1)
                }
                else {
                    message.error('解锁失败！', 1)
                }
            }
        })
    }

    //触发暂存定时器
    const startTimer = () => {
        //重置状态
        resetState();
        let ele = document.getElementById('countdown_dom');
        if (ele) {
            ele.innerHTML = `修改 未保存`
        }
        setSaveBtnDisabled(false);
        //定时开启
        let timer = setTimeout(() => {
            if (formControlRef.current) {
                const {body, ...params} = formControlRef.current.getSavedForm();
                params.submit = false;
                saveFormData(params).then(res => {
                    if (res.code === 0) {
                        console.log('暂存成功！', res);
                        setSaveBtnDisabled(true);

                        let savedTime = new Date();
                        sessionStorage.setItem('local_savedTime', JSON.stringify(savedTime));
                        startGetTime();

                        let localStore_formData = sessionStorage.getItem('localStore_formData');
                        if (localStore_formData) {
                            let formDataArr = JSON.parse(localStore_formData);
                            let formData = formDataArr.find((item: any) => {
                                return item.formId === selectTabId
                            })
                            if (formData) {
                                formData.init = true;
                            }
                            sessionStorage.setItem('localStore_formData', JSON.stringify(formDataArr))
                        }
                    }
                    else {
                        message.error("自动保存失败！", 1);
                    }
                })
            }
        }, savedTime);
        sessionStorage.setItem('savedTimer', JSON.stringify(timer))
    }

    //触发计时定时器
    const startGetTime = () => {
        let timer = setInterval(() => {
            let local_savedTime = sessionStorage.getItem('local_savedTime');
            if (local_savedTime) {
                let savedTime = JSON.parse(local_savedTime);
                let ele = document.getElementById('countdown_dom');
                if (ele) {
                    ele.innerHTML = `${utils.get_time_diff(savedTime)}前自动保存`
                }
            }
        }, 1000)
        sessionStorage.setItem('local_getTimeTimer', JSON.stringify(timer));
    }


    //清除定时器，重置状态
    const resetState = () => {
        let local_timer = sessionStorage.getItem('savedTimer');
        if (local_timer) {
            let timer = JSON.parse(local_timer);
            if (timer) {
                clearTimeout(timer)
            }
        }
        let local_getTimeTimer = sessionStorage.getItem('local_getTimeTimer');
        if (local_getTimeTimer) {
            let timer = JSON.parse(local_getTimeTimer);
            if (timer) {
                clearInterval(timer)
            }
        }
        let ele = document.getElementById('countdown_dom');
        if (ele) {
            ele.innerHTML = ``
        }
    }

    // 点击保存按钮
    const onClickSaveBtn = () => {
        // crf
        if (props.crfParams) {
            crfSaveType = ''
            CrfRef.current.handleSave()
            return
        }

        if (formControlRef.current) {
            const {body, ...params} = formControlRef.current.getSavedForm();
            params.submit = false;
            setLoading(true);
            saveFormData(params).then(res => {
                if (res.code === 0) {
                    let localStore_formData = sessionStorage.getItem('localStore_formData');
                    if (localStore_formData) {
                        let formDataArr = JSON.parse(localStore_formData);
                        let formData = formDataArr.find((item: any) => {
                            return item.formId === selectTabId
                        })
                        if (formData) {
                            formData.init = true;
                        }
                        sessionStorage.setItem('localStore_formData', JSON.stringify(formDataArr))
                    }
                    resetState();
                    // setSaveBtnDisabled(true);
                }
                else {
                    let ele = document.getElementById('countdown_dom');
                    if (ele) {
                        ele.innerHTML = `保存失败！`
                    }
                }
                setLoading(false);
            })
        }
    }

    //switch 操作按钮
    const switchActionBtn = (item: any) => {
        let currentRole = sessionStorage.getItem('roleCode');
        let projectRoleCode = sessionStorage.getItem('projectRoleCode');
        // 君实特殊表单控制填写
        if (projectRoleCode == 'ReadOnly' || currentRole == 'EDC_READ' 
            || (currentRole !== 'CALL_CENTER'  && tabs[currentTabIndex]?.formCode === 'FMJSNSCLC01CC01') 
            || (currentRole === 'CALL_CENTER'  && tabs[currentTabIndex]?.formCode !== 'FMJSNSCLC01CC01')) {
            return null
        }
        switch (item) {
            //查看
            case 'FormActionRead':
                return null
            //保存
            case 'FormActionSave':
                return (
                    <Button onClick={onClickSaveBtn} disabled={saveBtnDisabled} style={{ backgroundColor: saveBtnDisabled ? "#f5f5f5" : "#ffffff" }} className="savedBtn" type="primary">保存</Button>
                )
            //提交
            case 'FormActionSubmit':
                return (
                    <Button hidden={subjectsState === 'Stop' || subjectsState === 'Pause'} disabled={isSubmit} className="positiveBtn" onClick={onSubmit} type="primary">提交</Button>
                )
            //通过人工核查
            case 'FormActionQuestion':
                return (
                    <Button className="positiveBtn" onClick={onQuestion} type="primary">通过人工核查</Button>
                )
            //取消人工核查
            case 'FormActionCancelQuestion':
                return (
                    <Button className="reverseBtn" onClick={onCancelQuestion} type="primary">取消人工核查</Button>
                )
            //数据核查
            case 'FormActionExamine':
                return (
                    <Button className="positiveBtn" onClick={onExamine} type="primary">通过数据核查</Button>
                )
            //取消数据核查
            case 'FormActionCancelExamine':
                return (
                    <Button className="reverseBtn" onClick={onCancelExamine} type="primary">取消数据核查</Button>
                )
            //冻结
            case 'FormActionFreeze':
                return (
                    <Button className="positiveBtn" onClick={onFreeze} type="primary">冻结</Button>
                )
            //解冻
            case 'FormActionCancelFreeze':
                return (
                    <Button className="reverseBtn" onClick={onCancelFreeze} type="primary">解冻</Button>
                )
            //签名
            case 'FormActionAutograph':
                return (
                    <Button className="positiveBtn" onClick={onAutograph} type="primary">签名</Button>
                )
            //取消签名
            case 'FormActionCancelAutograph':
                return (
                    <Button className="reverseBtn" onClick={onCancelAutograph} type="primary">取消签名</Button>
                )
            //锁定
            case 'FormActionLock':
                return (
                    <Button className="positiveBtn" onClick={onLock} type="primary">锁定</Button>
                )
            //取消锁定
            case 'FormActionCancelLock':
                return (
                    <Button className="reverseBtn" onClick={onCancelLock} type="primary">解锁</Button>
                )
            default:
                break;
        }
    }

    //处理页面渲染
    const handleView = () => {
        if (isWord && isOcr && ocrArr.length > 0) {
            return (
                <div className="formViewContent">
                    <Row justify="start">
                        <Col span={10}>
                            <OcrView formId={selectTabId} data={ocrArr} updateFun={updateData}></OcrView>
                        </Col>
                        <Col span={14}>
                            <div style={{ marginLeft: '10px', paddingLeft: '20px', backgroundColor: '#fbfbfb' }}>
                                <Spin tip="Loading..." spinning={loading}>
                                    <Control createUserCode={props.createUserCode} workbench={props.workbench} autoOcr={props.autoOcr} editNew={props.editNew} editModel={props.editModel} textAreaMaxLen={props.textAreaMaxLen} isOcr={isOcr} formId={selectTabId} data={formData} ref={formControlRef}
                                        // startTimerFun={startTimer}
                                        startTimerFun={() => { }} updateSelfData={updateData}  tenantCode={props.tenantCode}
                                    ></Control>
                                </Spin>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (isOcr && ocrArr.length > 0) {
            return (
                <div className="formViewContent">
                    <Row justify="start">
                        <Col span={24}>
                            <OcrView formId={selectTabId} data={ocrArr} updateFun={updateData}></OcrView>
                        </Col>
                    </Row>
                </div>
            )
        }
        else {
            return (
                <div className="formViewContent">
                    <Row justify="start">
                        <Col span={24}>
                            <Spin tip="Loading..." spinning={loading}>
                                <Control createUserCode={props.createUserCode} workbench={props.workbench} autoOcr={props.autoOcr} editNew={props.editNew} editModel={props.editModel} textAreaMaxLen={props.textAreaMaxLen} formId={selectTabId} data={formData} ref={formControlRef}
                                    // startTimerFun={startTimer}
                                    startTimerFun={() => { }} updateSelfData={updateData} tenantCode={props.tenantCode}
                                ></Control>
                            </Spin>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    // crf 保存成功
    const onSaveSuccess = (values: any) => {
        if (values) {
            setCrfSaveData(values)

            let localStorage_forms = localStorage.getItem('localStorage_forms');
            let formId: any = null;
            if (localStorage_forms) {
                let forms = JSON.parse(localStorage_forms);
                formId = forms.activeId
            }

            // 提交
            if (crfSaveType == 'crf_submit') {
                let params = {
                    formId,
                    submit: true,
                    values: []
                }

                saveCrfForm(params).then(res => {
                    if (res.code === 0) {
                        message.success('保存成功！')
                        CrfRef.current.unloadApp()
                        props.closeModal();
                    }
                    else {
                        console.log(res.message);
                        message.error(res.message)
                    }
                    setLoading(false);
                })
                return
            }

            // 修改
            if (crfSaveType == 'crf_update') {
                let params = {
                    formId,
                    dataCode: '',
                    deCode: '',
                    value: []
                }
                updateCrfData(params).then(res => {
                    if (res.code === 0) {
                        message.success('修改成功！')
                        CrfRef.current.unloadApp()
                        props.closeModal();
                    }
                    else {
                        message.error(res.message)
                    }
                    setLoading(false);
                })
                return
            }
        }
    }

    // 超级项目经理账号 crf 保存
    const onSaveCrfForm = () => {
        crfSaveType = 'crf_update'
        CrfRef.current.handleSubmit()
    }

    return (
        <div className="form">
            <div className="formModalCloseBtn" onClick={() => { closeModal() }}>
                <IconFont type="iconguanbi"></IconFont>
            </div>
            <div className="form_content">
                <Tabs defaultActiveKey="1" activeKey={`${currentTabIndex}`} tabPosition={'top'} onChange={onTabChange} moreIcon={<span style={{ fontSize: '12px', whiteSpace: 'nowrap', cursor: 'pointer' }}>查看更多<EllipsisOutlined /></span>}>

                    {tabs && tabs.map((item: any, index: any) => {
                            // 给管理端的表单添加访视信息和表单填写状态
                            let inputStateName = "";
                            switch (item.inputState) {
                                case "Complete":
                                    inputStateName ="已完成";
                                    break;
                                case "Running":
                                    inputStateName ="进行中";
                                    break;
                                case "Question":
                                    inputStateName ="有质疑";
                                    break;
                                case "Answer":
                                    inputStateName ="质疑已回复";
                                    break;   
                                case "Wait":
                                    inputStateName ="未开始";
                                    break; 
                                case "VOID":
                                    inputStateName ="已作废";
                                break;
                                default:
                                    inputStateName ="状态未知"
                                break     
                                    
                            }
                            return (
                                <TabPane tab={item.visitName?`【${item?.visitName}】${item.title}（${inputStateName}）`:item.title} key={index}>
                                    {/* 重复提醒 */}
                                    {
                                        props.repeatCodes && props.repeatCodes.length > 0 &&
                                        <div className="repeat_msg"><WarningFilled twoToneColor="rgb(234, 180, 38)" /> 重复提醒，疑似重复：
                                            {props.repeatCodes.map((item: any, index: any) => <>{index == 0 ? '' : '、'}{item}</>)}
                                        </div>
                                    }
        
                                    {
                                        selectTabId && item.id === selectTabId ?
                                            props.crfParams ?
                                                <div className="crf_form_content">
                                                    <CRFComponent
                                                        ref={CrfRef}
                                                        params={crfParams}
                                                        onSaveSuccess={(v: any) => onSaveSuccess(v)}
                                                        readonly={crfReadonly}
                                                    />
                                                </div>
                                                : handleView()
                                            : null
                                    }
                                </TabPane>
                            )
                        
                    }  )}
                </Tabs>
            </div>
            <div className="footer">
                <Pagination simple defaultPageSize={1} current={currentTabIndex + 1} total={tabs.length} onChange={(i) => onPageChange(i)} />
                <div className="controlBtn_content">
                    {
                        ocrArr && ocrArr.length > 0 ? <React.Fragment>
                            {
                                isWord ? <span style={{ backgroundColor: '#6287EC' }} className="controlBtn" onClick={() => { if (isOcr) { setIsWord(false) } }}>
                                    <IconFont type="iconWord_White"></IconFont>
                                </span> : <span className="controlBtn" onClick={() => { setIsWord(true) }}>
                                    <IconFont type="iconWord"></IconFont>
                                </span>
                            }
                        </React.Fragment> : null
                    }
                    {
                        ocrArr && ocrArr.length > 0 ? <React.Fragment>
                            {
                                isOcr ? <span style={{ backgroundColor: '#6287EC' }} className="controlBtn" onClick={() => { if (isWord) { setIsOcr(false) } }}>
                                    <IconFont type="iconOcr_White"></IconFont>
                                </span> : <span className="controlBtn" onClick={() => { setIsOcr(true) }}>
                                    <IconFont type="iconOcr"></IconFont>
                                </span>
                            }
                        </React.Fragment> : null
                    }
                </div>
                <div className="footer_right">
                    <div className="time" id="countdown_dom"></div>
                    {
                        showFormBtn ? <div className="formActions">
                            {
                                formActions && formActions.map((item: any, index: any) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {
                                                switchActionBtn(item)
                                                
                                            }
                                          
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div> : null
                    }

                    {/* 超级项目经理账号 crf */}
                    {
                        props.fromCaseAudit && props.crfParams && !crfReadonly &&
                        <Button type="primary" size={'middle'} onClick={() => onSaveCrfForm()}>
                            保存
                        </Button>
                    }
                </div>
            </div>
        </div>
    )
}


export default forwardRef(Form);