

/**
 * 活动管理
 */
import EPRORegister from '../components/activity/epro-register';
import ActivityList from '../components/activity/list/list';
const routes = [
    { 'path': '/activity/list', 'component': ActivityList },
    { 'path': '/activity/eproregister', 'component': EPRORegister },
];

export default routes