import React, { useMemo } from "react";
import { ConfigProvider, Image } from "antd";
import zh_CN from 'antd/lib/locale-provider/zh_CN';

interface Props {
  visible: boolean
  url: string
  urls: string[]
  onClose: () => void
}

const ImageViewer: React.FC<Props> = ({ url, urls, visible, onClose }) => {
  
  const current = useMemo(()=> {
    if (url && urls.length > 0) {
      return urls.indexOf(url)
    }
    return 0
  }, [url, urls])
  
  if (false === visible || urls.length === 0) {
    return null
  }



  return (
    <ConfigProvider locale={zh_CN}>
      <Image.PreviewGroup preview={{ current, visible, onVisibleChange: onClose }}>
        {
          urls.map((item: string) => (
            <Image src={item} style={{display: 'none'}}/>
          ))
        }
      </Image.PreviewGroup>
    </ConfigProvider>
  )
}

export default ImageViewer;
