// 
import { requestGetRes, requestPostRes } from "../EdcService";

// 
export interface ReportScalePayload {
  name?: string;
  doctorName?: string;
  centerCode?: string;
  bindState?: '0' | '1';
  startTime?: string;
  endTime?: string;
}

export interface Result<T> {
  code: number;
  message?: string;
  data: {
    page: number;
    pageSize: number;
    total: number;
    rows: T[];
  }
}

export interface ReportScaleResult {
  bindState: string
  centerName: null | string
  createTime: string
  doctorName: string
  hospitalCode: null | string
  name: string
  subjectCode: string
}


// ePRO注册列表
export const getReportScaleData = (page: number, pageSize: number = 20, data: ReportScalePayload = {})=> {
  return requestPostRes('data/report/scale/list', {
    ...data,
    page,
    pageSize
  }) as Promise<Result<ReportScaleResult>>
}
