import React from 'react';
import { Table, Input, Modal, Button, Select, message, Tooltip, Upload } from 'antd';
import { getAllRoleList, getProjectList, getAuditPermissionList, getAuditPermissionSet, auditExport, auditImport } from "../../../../services/system/AuditPermissionService";
import { CloseCircleOutlined } from '@ant-design/icons'
import './index.less'
import utils from "../../../../util/utils";

const { Option } = Select;

class AuditPermission extends React.Component {

    state = {
        failData: [],
        failModal: false,
        roleList: [], // 角色列表
        projectList: [], // 项目列表
        account: '', // 账号
        role: '', // 角色
        projectCode: '', // 项目
        data: [],
        page: 1,
        pageSize: 10,
        totalRow: 0,
        loading: false,
        visibleModal: false,
        auditProjectList: [], // 审核项目列表
        userCode: '',
    }

    componentDidMount() {
        this.getRoleList();
        this.getProjectList();
        this.getData();
    }

    // 获取审核权限列表
    getData = () => {
        this.setState({
            data: [],
            totalRow: 0,
        })

        let { account, role, page, pageSize, projectCode } = this.state;
        let params = {
            account,
            roleCode: role,
            projectCode,
            page,
            pageSize,
        }
        this.setState({ loading: true }, () => {
            getAuditPermissionList(params).then(res => {
                this.setState({
                    data: res.rows,
                    totalRow: res.total,
                    loading: false
                })
            }).catch(err => {
                this.setState({
                    loading: false
                })
                message.error(err.message);
            })
        })
    }

    // 获取角色列表
    getRoleList = () => {
        getAllRoleList().then(res => {
            this.setState({
                roleList: res
            })
        }).catch(err => {
            message.error(err.message);
        })
    }

    // 获取项目列表
    getProjectList = () => {
        getProjectList().then(res => {
            this.setState({
                projectList: res
            })
        }).catch(err => {
            message.error(err.message);
        })
    }

    // 选择角色
    changeRole = (value) => {
        this.setState({
            role: value
        }, () => {
            this.getData()
        })
    }

    // 选择项目
    changeProject = (value) => {
        this.setState({
            projectCode: value
        }, () => {
            this.getData()
        })
    }

    /**
     * 搜索
     */
    handleSearch = () => {
        this.setState({
            page: 1,
            data: []
        }, () => {
            this.getData()
        })
    }

    /**
     * 改变页数
     * @param {*} page 
     */
    changePage = (page) => {
        this.setState({ page }, () => {
            this.getData()
        })
    }
    changePageSize = (current, size) => {
        this.setState({ page: 1, pageSize: size }, () => {
            this.getData()
        })
    }

    // 设置
    handleSetting = (record) => {
        this.setState({
            visibleModal: true,
            auditProjectList: record.projectList || [],
            userCode: record.userCode || ''
        })
    }
    // 导出
    exportData = (record) => {
        const url = '/plat/audit/export'
        const params = {
            userCode: record.userCode,
            projectCodes: (record.projectList || []).map(e => e.projectCode),

        }
        utils.exportPostFun(url, params, '审核项目数据.xlsx')

    }

    //导入
    importData = (record) => {
        this.setState({
            currentCode: record.userCode
        })

    }
    // 删除项目
    onCancelTags = (code) => {
        let list = this.state.auditProjectList;
        if (list && list.length > 0) {
            list = list.filter(item => item.projectCode != code);
            this.setState({
                auditProjectList: list
            })
        }
    };

    // 选择项目
    chooseProject = (value) => {
        if (value) {

            let list = this.state.projectList;
            let alist = this.state.auditProjectList;

            let listItem = list.find(item => item.projectCode == value);

            if (alist && alist.length > 0) {
                let auditItem = alist.find(item => item.projectCode == value);
                if (!auditItem) {
                    alist.push(listItem)
                }
            } else {
                alist.push(listItem)
            }

            this.setState({
                auditProjectList: alist
            })
        }
    }

    // 设置
    handleOk = () => {
        let { userCode, auditProjectList } = this.state;

        let codes = [];
        if (auditProjectList && auditProjectList.length > 0) {
            auditProjectList.map(item => {
                codes.push(item.projectCode)
            })
        }

        let params = {
            projectCodes: codes,
            userCode
        }
        getAuditPermissionSet(params).then(res => {
            message.success('设置成功！');
            this.setState({
                visibleModal: false
            }, () => {
                this.getData();
            })
        }).catch(err => {
            message.error(err.message);
        })
    }

    // 账号/昵称搜索
    onAccountChange = (e) => {
        this.setState({
            account: e.target.value
        }, () => {
            this.getData()
        })
    }

    render() {
        const props = {
            name: 'file',
            accept: ".xlsx,.xls",
            headers: {
                authorization: 'authorization-text',
            },
            showUploadList: false,
            maxCount: 1,
            beforeUpload: file => {
                this.setState(state => ({
                    file: file
                }), () => {
                    auditImport({
                        file,
                        userCode: this.state.currentCode
                    }).then(data => {
                        if (data && data.length > 0) {
                            this.setState({
                                failData: data,
                                failModal: true
                            })
                        } else {
                            message.success("导入成功！");
                        }
                        this.getData();
                    })
                });
                return false
            },

        }
        const failColumns = [
            {
                title: '错误行数',
                dataIndex: 'currentRowNumber'
            },
            {
                title: '项目名称',
                dataIndex: 'projectName'
            },
            {
                title: '错误明细',
                dataIndex: 'error'
            },
        ]
        const columns = [
            {
                title: '姓名（账号）',
                dataIndex: 'userName',
                render: (text, record) => (
                    <>{record.userName}（{record.userCode}）</>
                )
            },
            {
                title: '角色',
                dataIndex: 'roleName'
            },
            {
                title: '审核项目',
                dataIndex: 'projectList',
                render: (text, record) => (
                    <>
                        {
                            record.projectList && record.projectList.length > 0 &&
                            record.projectList.map((item, index) =>
                                <span key={item.projectCode}>{item.projectName}{index != record.projectList.length - 1 && '，'}</span>
                            )
                        }
                    </>
                )
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: 200,
                align: 'center',
                render: (text, record) => (
                    <>
                        <Button type="link" size={'small'} onClick={() => this.handleSetting(record)}>设置</Button>
                        <Button type="link" size={'small'} onClick={() => this.exportData(record)}>导出</Button>
                        <Upload  {...props}>
                            <Button type="link" size={'small'} onClick={() => this.importData(record)}>导入</Button>
                        </Upload>


                    </>
                )
            }
        ];

        // 分页
        const pagination = {
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.state.totalRow,
            showTotal: total => `共 ${this.state.totalRow} 条数据， ${this.state.pageSize} / 页`,
            showSizeChanger: false,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize
        }

        const { failModal, visibleModal, roleList, projectList, loading, auditProjectList } = this.state;

        return (
            <div className='audit_permission'>
                <div className='search'>
                    <div className='searchLeft'>
                        <div className='searchItem'>
                            <Input placeholder="请输入账号/昵称搜索" allowClear onChange={e => this.onAccountChange(e)} style={{ minWidth: 160 }} />
                        </div>
                        <div className='searchItem'>角色：
                            <Select
                                placeholder='请选择平台角色'
                                onChange={this.changeRole}
                                style={{ minWidth: 220 }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear
                            >
                                {
                                    roleList && roleList.length > 0 ?
                                        roleList.map(v => (
                                            <Option
                                                value={v.code}
                                                id={v.code}
                                                key={v.code}>{v.name}</Option>
                                        )) : ''
                                }
                            </Select>
                        </div>
                        <div className='searchItem'>
                            <Select
                                placeholder='请选择项目'
                                onChange={this.changeProject}
                                style={{ minWidth: 250 }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }

                                allowClear
                            >
                                {
                                    projectList && projectList.length > 0 ?
                                        projectList.map(v => (
                                            <Option
                                                value={v.projectCode}
                                                id={v.projectCode}
                                                key={v.projectCode}>{v.projectName}</Option>
                                        )) : ''
                                }
                            </Select>
                        </div>
                        <Button type="primary" onClick={this.handleSearch}>搜索</Button>
                    </div>
                </div>

                <Table
                    locale={{ emptyText: "暂无数据" }}
                    rowKey={(record) => record.code}
                    bordered
                    columns={columns}
                    dataSource={this.state.data}
                    pagination={pagination}
                    loading={loading}
                />
                <Modal visible={failModal} title="导入的失败数据明细查看" centered destroyOnClose
                    onCancel={() => this.setState({ failModal: false })}
                    footer={null}
                >
                    <Table
                        locale={{ emptyText: "暂无数据" }}

                        columns={failColumns}
                        dataSource={this.state.failData}
                        pagination={false}

                    />
                    <div className='button_fail_sure'>
                        <Button type="primary" onClick={() => this.setState({ failModal: false })}>确认</Button>
                    </div>

                </Modal>
                {/* 设置模态框 */}
                <Modal
                    visible={visibleModal}
                    title="请选择审核项目"
                    onCancel={() => this.setState({ visibleModal: false })}
                    onOk={this.handleOk}
                    centered
                    destroyOnClose
                    footer={[
                        <Button
                            onClick={() => this.setState({ visibleModal: false })}
                            style={{ marginRight: 10 }}
                        >取消</Button>,
                        <Button type="primary" onClick={this.handleOk}>确认</Button>
                    ]}
                >
                    <Select
                        placeholder='请选择审核项目'
                        onChange={this.chooseProject}
                        style={{ minWidth: 250 }}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {
                            projectList && projectList.length > 0 ?
                                projectList.map(v => (
                                    <Option
                                        value={v.projectCode}
                                        id={v.projectCode}
                                        key={v.projectCode}>{v.projectName}</Option>
                                )) : ''
                        }
                    </Select>

                    <div className="project_wrap">
                        <span className="project_label">已选项目：</span>
                        <div className="project_project">
                            {
                                auditProjectList && auditProjectList.length > 0 &&
                                auditProjectList.map(item => (
                                    <div key={item.projectCode} className="item_project">
                                        <Tooltip title={item.projectName}>
                                            <div className="tags">{item.projectName}</div>
                                        </Tooltip>

                                        <CloseCircleOutlined onClick={() => this.onCancelTags(item.projectCode)} className="item_icon" />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default AuditPermission;
