import React, { useState } from "react"
import { Moment } from "moment"
import locale from "antd/es/date-picker/locale/zh_CN"
import { Button, Col, DatePicker, Form, Input, Row } from "antd"
import { DoctorRegistrationPayload } from "../../../../../services/plat/plat"
import ImportModal from "../import-modal"
import modStyl from './index.module.scss'

type ConditionProps = {
  onSearch: (params?: DoctorRegistrationPayload)=> void
}

const colCols = {
  span: 6
}
const fieldCols = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 19
  }
}

const Condition: React.FC<ConditionProps> = ({ onSearch })=> {

  const [form] = Form.useForm()

  // 数据导入
  const [visible, setVisible] = useState(false)

  const takeParams = ()=> {
    const {betweenAt, ...rest} = form.getFieldsValue()

    const part = {
      startTime: '', 
      endTime: ''
    }
    if (Array.isArray(betweenAt)) {
      const [startTime, endTime] = betweenAt as Moment[]
      part.startTime = startTime.format('YYYY-MM-DD')
      part.endTime = endTime.format('YYYY-MM-DD')
    }

    return {
      ...rest,
      ...part
    }
  }

  const submitControl = ()=> {
    onSearch(takeParams())
  }

  const resetControl = ()=> {
    form.resetFields()
    onSearch()
  }

  return (
    <div className={modStyl.wrapper}>
      <Form onFinish={submitControl} form={form}>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="docterName" label="医生姓名">
              <Input placeholder="医生姓名" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="hospitalName" label="医院名称">
              <Input placeholder="医院名称" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="betweenAt" label="备案时间">
              <DatePicker.RangePicker style={{width: '100%'}} locale={locale} allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Button htmlType="submit" type="primary">搜索</Button>
            <Button onClick={resetControl} style={{marginLeft: 10}}>重置</Button>
            <Button onClick={()=> setVisible(true)} style={{marginLeft: 10}} type="link">增量导入数据</Button>
          </Col>
        </Row>
      </Form>
      {/* 增量医生备案导入 */}
      <ImportModal visible={visible} onClose={()=> setVisible(false)}/>
    </div>
  )
}

export default Condition