import React, { useEffect, useRef, useState } from "react"
import { Empty, Table } from "antd"
import { ColumnsType } from "antd/lib/table"

import indexStyl from './index.module.scss'
import Condition from "./components/condition"
import { DoctorRegistrationPayload, getDoctorRegistrationList } from "../../../services/plat/plat"

const DoctorRegistration = ()=> {
  
  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [dataSource, setDataSource] = useState<any[]>([])

  const conditionRef = useRef<DoctorRegistrationPayload>({})

  //#region 表格列
  const columns: ColumnsType<any> = [
    {
      width: 120,
      title: '医生姓名',
      ellipsis: true,
      dataIndex: 'name',
    },
    {
      width: 120,
      title: '医院名称',
      dataIndex: 'hospital',
    },
    {
      width: 120,
      title: '手机号',
      dataIndex: 'phone',
    },
    {
      width: 120,
      title: '身份证号',
      dataIndex: 'idCard',
    },
    {
      width: 120,
      title: '备案时间',
      dataIndex: 'recordArchivesTime',
    },
  ];
  //#endregion

  const fetchData = async (params: DoctorRegistrationPayload = {}, page = 1)=> {
    setLoading(true)
    const {code, data} = await getDoctorRegistrationList(params, page)
    if (code) {
      return
    }
    setPageNum(page)
    setLoading(false)
    setTotal(data.total)
    setDataSource(data.rows)
  }

  const onSearch = (params?: DoctorRegistrationPayload)=> {
    conditionRef.current = params || {}
    fetchData(params)
  }

  useEffect(()=> {
    fetchData()
  }, [])

  return (
    <div className={indexStyl.wrapper}>
      <Condition onSearch={onSearch}/>
      <div className={indexStyl.tableWrapper}>
        <Table
          loading={loading}
          size="middle"
          rowKey="id"
          scroll={{y: window.innerHeight - 420}}
          columns={columns}
          dataSource={dataSource}
          locale={{emptyText: <Empty description="暂无数据"/>}}
          pagination={{
            onChange: (page: number)=> {
              fetchData(conditionRef.current, page)
            },
            total: total,
            current: pageNum,
            pageSize: pageSize,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showQuickJumper: false,
            showTotal: total=> `共${total}条，每页${pageSize}条`,
            position: ["bottomRight"]}}/>
      </div>
    </div>
  )
}

export default DoctorRegistration