export type VisitDataType = { name: string, formName: string[]}

// 访视/表单数据类型
export function transformVisitData(visit?: string[][]) {
    /**
     * [
     *   // 只选择一级访视
     *   ['访视名称1'],
     *   ['访视名称2'],
     *   // 选择访视下的表单
     *   ['访视名称1', '表单名称1'],
     *   ['访视名称1', '表单名称2'],
     *   ['访视名称2', '表单名称4'],
     * ]
     */

    const map = new Map<string, number>()
    const mergeVisit: VisitDataType[] = []

    if (Array.isArray(visit)) {
      for(const pieces of visit) {
        const [name, formName] = pieces
        if (pieces.length === 1) {
          mergeVisit.push({
            name,
            formName: [],
          })
          continue
        }

        // 访视名称已存在
        const index = map.get(name)
        if (typeof index === 'number') {
          mergeVisit[index].formName.push(formName)
        } else {
          map.set(name, mergeVisit.length)
          mergeVisit.push({
            name,
            formName: [formName],
          })
        }
      }
    }

    return mergeVisit
  }