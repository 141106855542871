import { Col, Descriptions, Popover, Row, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { getDataCRCStatistic, getVisitStatistic } from "../../../../../services/data/DataService";
import { createFromIconfontCN, InfoCircleOutlined } from "@ant-design/icons";
import { getSumCount } from "../../../../../services/data/data";
import { IconFontProps } from "@ant-design/icons/lib/components/IconFont";

const IconFont = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/font_2343612_ixe83tdh89g.js',
  ],
});

interface StatRecord {
  crcProjectCodes: string[]
  exchangedNum: number
  finnishNum: number
  goalNum: number
  groupNum: number
  groupsStatistic: null
  num: number
  remianingNum: number
  roleName: string
  visitOne: number
  visitThree: number
  visitTwo: number
}

interface GroupsStatisticRecord {
  shortName: string
  finishNum: number
  groupNum: number
}

interface OtherRecord {
  nodeName: string
  count: number
}

interface RecordItem {
  key: string
  icon: IconFontProps
  name: string
  num: number | null | OtherRecord[]
  color: string
  background: string
  info?: keyof GroupsStatisticRecord
}



interface Props {
  editModel?: number
  roleCode?: string
  projectCode?: string
}

const Stat: React.FC<Props> = ({ editModel, projectCode, roleCode }) => {

  const [loading, setLoading] = useState(false);

  const [records, setRecords] = useState<RecordItem[]>([]);

  const [groupsStatistic, setGroupsStatistic] = useState<GroupsStatisticRecord[]>([])

  const targetName = useMemo(()=> sessionStorage.targetName || '受试者', [])

  const isShow = useMemo(()=> {
    const env = process.env.REACT_APP_ENV
    const code = localStorage.tenantCode
    // 是否显示受试者访视完成数
    if (env === 'test' || env === 'development') {
      return 'TENANT-0176' === code
    }
    if (env === 'production') {
      return "TENANT-042" === code
    }
    return false
  }, [])

  const readonly = useMemo(()=> roleCode === 'ReadOnly', [roleCode])

  const fetchOtherStat = async ()=> {
    const { code, message, data } = await getVisitStatistic({ projectCode })
    return data || []
  }

  /**
     * 获取 任务表数据
     * @param key
     */
  const fetchStat = async () => {
    const { code, message, data }: { code: number, message: string, data: StatRecord } = await getDataCRCStatistic(projectCode)

    setLoading(false)
    if (code !== 0) {
      return
    }

    const partials: RecordItem[] = [
      { key: '0', icon: <IconFont type='iconruzu'/>, name: `${targetName}目标数`, num: data.goalNum, color: '#7c6cb4', background: '#faf4ff' },
      { key: '1', icon: <IconFont type='iconluru1'/>, name: `${targetName}入组数`, num: data.groupNum, color: '#B2D2DB', background: '#F0F7FF', info: 'groupNum' },
    ]

    if (isShow) {
      const num = await fetchOtherStat()
      partials.push({ key: '6', icon: <IconFont type='icondongjie'/>, name: `${targetName}访视完成数`, num, color: '#B7EB8F', background: '#EBFCDE' })
    }

    // 放在isShow之后
    partials.push({ key: '2', icon: <IconFont type='iconshenhe1'/>, name: `${targetName}完成数`, num: data.finnishNum, color: '#ECD48E', background: '#FFF8E5', info: 'finishNum' })

    // 
    if (false === isShow) {
      partials.push(...[
        { key: '3', icon: <IconFont type='iconqianming'/>, name: readonly ? '访视1' : '累计积分', num: readonly ? data.visitOne : data.num, color: '#FFC9CB', background: '#FFF1F7' },
        { key: '4', icon: <IconFont type='iconhecha'/>, name: readonly ? '访视2' : '可兑换积分', num: readonly ? data.visitTwo : data.remianingNum, color: '#FFBB96', background: '#FFF6F1' },
        { key: '5', icon: <IconFont type='icondongjie'/>, name: readonly ? '访视3' : '已兑换积分', num: readonly ? data.visitThree : data.exchangedNum, color: '#B7EB8F', background: '#EBFCDE' },
      ])
    }


    setRecords(partials)
    setGroupsStatistic(data.groupsStatistic || [])

    // {
    //   subjectStatcisicData: {
    //     goalNum: u.goalNum,
    //     groupNum: u.groupNum,
    //     grofinnishNumup: u.finnishNum,
    //     visitorFinish: visitRes.data || [],
    //     num: u.num,
    //     locremianingNumk: u.remianingNum,
    //     exchangedNum: u.exchangedNum,
    //     visitOne: u.visitOne,
    //     visitThree: u.visitThree,
    //     visitTwo: u.visitTwo,
    //     groupsStatistic: (Array.isArray(u.groupsStatistic) && u.groupsStatistic.length > 0) ? u.groupsStatistic : null
    //   }
    // }

    //   const subjectStatcisicList = isShowVisit ? [
    //     { key: 0, icon: 'iconruzu', name: `${targetName}目标数`, num: goalNum, color: '#7c6cb4', background: '#faf4ff' },
    //     { key: 1, icon: 'iconluru1', name: `${targetName}入组数`, num: groupNum, color: '#B2D2DB', background: '#F0F7FF', info: 'groupNum' },
    //     { key: 6, icon: 'icondongjie', name: `${targetName}访视完成数`, num: visitorFinish, color: '#B7EB8F', background: '#EBFCDE' },
    //     { key: 2, icon: 'iconshenhe1', name: `${targetName}完成数`, num: grofinnishNumup, color: '#ECD48E', background: '#FFF8E5', info: 'finishNum' },
    //     // { key: 3, icon: 'iconqianming', name: pRoleCode == 'ReadOnly' ? '访视1' : '累计积分', num: pRoleCode == 'ReadOnly' ? visitOne : num, color: '#FFC9CB', background: '#FFF1F7' },
    //     // { key: 4, icon: 'iconhecha', name: pRoleCode == 'ReadOnly' ? '访视2' : '可兑换积分', num: pRoleCode == 'ReadOnly' ? visitTwo : locremianingNumk, color: '#FFBB96', background: '#FFF6F1' },
    //     // { key: 5, icon: 'icondongjie', name: pRoleCode == 'ReadOnly' ? '访视3' : '已兑换积分', num: pRoleCode == 'ReadOnly' ? visitThree : exchangedNum, color: '#B7EB8F', background: '#EBFCDE' },
    // ] :
    //     [
    //         { key: 0, icon: 'iconruzu', name: `${targetName}目标数`, num: goalNum, color: '#7c6cb4', background: '#faf4ff' },
    //         { key: 1, icon: 'iconluru1', name: `${targetName}入组数`, num: groupNum, color: '#B2D2DB', background: '#F0F7FF', info: 'groupNum' },
    //         { key: 2, icon: 'iconshenhe1', name: `${targetName}完成数`, num: grofinnishNumup, color: '#ECD48E', background: '#FFF8E5', info: 'finishNum' },
    //         { key: 3, icon: 'iconqianming', name: pRoleCode == 'ReadOnly' ? '访视1' : '累计积分', num: pRoleCode == 'ReadOnly' ? visitOne : num, color: '#FFC9CB', background: '#FFF1F7' },
    //         { key: 4, icon: 'iconhecha', name: pRoleCode == 'ReadOnly' ? '访视2' : '可兑换积分', num: pRoleCode == 'ReadOnly' ? visitTwo : locremianingNumk, color: '#FFBB96', background: '#FFF6F1' },
    //         { key: 5, icon: 'icondongjie', name: pRoleCode == 'ReadOnly' ? '访视3' : '已兑换积分', num: pRoleCode == 'ReadOnly' ? visitThree : exchangedNum, color: '#B7EB8F', background: '#EBFCDE' },
    //     ]
            
  }

  const fetchSumCount = async()=> {
    const { code, message, data } = await getSumCount()

    setLoading(false)

    if (code !== 0) {
      return
    }
    setRecords([
      { key: 'allCount', icon: <IconFont type='iconruzu'/>, name: '患者总数', num: data.allCount, color: '#7c6cb4', background: '#faf4ff' },
      { key: 'groupNum', icon: <IconFont type='iconluru1'/>, name: '入组患者数', num: data.groupNum, color: '#B2D2DB', background: '#F0F7FF' },
      { key: 'questionNum', icon: <IconFont type='iconshenhe1'/>, name: '质疑未回复者数', num: data.questionNum, color: '#B2D2DB', background: '#F0F7FF' },
    ])
  }

  useEffect(()=> {
    if (projectCode) {
      setLoading(true)
      if (editModel) {
        fetchSumCount()
      } else {
        fetchStat()
      }
    }
  }, [projectCode, editModel])

  const takeEast = ({num, color}: RecordItem)=> {
    if (!Array.isArray(num)) {
      return (
        <div className={'number'} style={{ color }}>{num ? num + '例' : 0}</div>
      )
    }

    if (Array.isArray(num)) {
      return (
        <>
          <div style={{ color }}>
            {num && num[0].nodeName && <span>{num && num[0].nodeName}:</span>}
            <span className={'number'}>{`${num && num[0].count || 0}例 `}</span>
          </div>
          <div className='info-tip'>
            <Popover
              trigger="hover"
              placement="bottom"
              content={
                <div>
                  {
                    num.map((e)=> {
                      return (
                        <Descriptions layout="horizontal" column={1} style={{ width: 180 }}>
                          {
                            num.map((e)=> (
                              <Descriptions.Item label={e.nodeName}>{e.count}例</Descriptions.Item>
                            ))
                          }
                        </Descriptions>
                      )
                    })
                  }
                </div>
              }>
                <span>完成进度</span> <InfoCircleOutlined style={{ fontSize: '12px', color: '#6c96f7' }} />
            </Popover>
          </div>
        </>
      )
    }
    return null
  }


  if (loading) {
    return (
      <div style={{height: 85, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Spin />
      </div>
    )
  }

  return (
    <Row gutter={12}>
      {
        records.map((record)=> {
          return (
            <Col key={record.key} className="gutter-row" span={4}>
              <div className={'cols-con'}>
                <div className={'subjects-left'}>
                  <div className={'item_icon'} style={{ background: record.background }}>{record.icon}</div>
                </div>
                <div className={'subjects-right'}>
                  { takeEast(record)}
                  <div className={'name'}>
                    <span>{record.name} </span>
                  </div>
                </div>
                {
                  (record.info && groupsStatistic.length > 0) && (
                    <div className='info-tip'>
                      <Popover
                        trigger="hover"
                        content={
                          <Descriptions layout="horizontal" column={1} style={{ width: 150 }}>
                            {
                              groupsStatistic.map((t)=> (
                                <Descriptions.Item label={t.shortName}>{t[record.info!]}</Descriptions.Item>
                              ))
                            }
                          </Descriptions>
                        }>
                        分组数据&nbsp;<InfoCircleOutlined style={{ fontSize: '12px', color: '#6c96f7' }} />
                      </Popover>
                    </div>
                  )
                }
              </div>
            </Col>
          )
        })
      }
    </Row >
  )
}

export default Stat;
