import React, { useEffect, useState } from "react"
import { CloudUploadOutlined } from "@ant-design/icons"
import { Button, message as messager, Modal, Space, Table, UploadFile } from "antd"
import { downloadDoctorRegistrationTemplate, ImportDoctorRegistration, ImportDoctorRegistrationRecord } from "../../../../../services/plat/plat"
import Dragger from "antd/lib/upload/Dragger"
import modStyl from './index.module.scss'

interface Props {
  visible: boolean
  onClose: ()=> void
}
const ImportModal: React.FC<Props> = ({ visible, onClose })=> {

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [messages, setMessages] = useState<ImportDoctorRegistrationRecord[]>([])

  const handleClose = ()=> {
    setFileList([])
    setMessages([])
    onClose()
  }

  const uploadFile = async()=> {
    const f = fileList[0] as unknown
    const { data } = await ImportDoctorRegistration(f as File)
    if (data.length === 0) {
      messager.success('导入成功')
      handleClose()
    } else {
      messager.success('部分数据导入失败，请修改后重新导入!')
      setMessages(data)
    }
  }

  const draggerProps = {
    name: 'file',
    accept: ".xlsx,.xls",
    maxCount: 1,
    fileList,
    beforeUpload: (file: UploadFile)=> {
      const allowedTypes = [".xlsx", ".xls"];
      const fileType = file.name.slice(file.name.lastIndexOf('.'))
    
      if (!file.size) {
        messager.error(`请选择要导入的文件`);
        return
      }
      if(file.size > 30 * 1024 * 1024){
        messager.error(`文件大小不能超过30MB`);
        return
      }
      if(!allowedTypes.includes(fileType)){
        messager.error(`请上传xlsx或者xls类型文件`);
        return
      }
      setFileList([file])
      return false
    },
    onRemove: ({ uid }: UploadFile)=> {
      for(const i in fileList) {
        if (fileList[i].uid === uid) {
          fileList.splice(Number(i), 1)
          setFileList([...fileList])
          break;
        }
      }
    },
  }

  const dlTemplate = async()=> {
    const data = await downloadDoctorRegistrationTemplate()

    const url = window.URL.createObjectURL(new Blob([data as BufferSource]));

    const a = document.createElement('a');
    a.href = url;
    a.download = '增量医生备案导入模板.xlsx';

    // 触发下载  
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  return (
    <Modal
        className='modal-account'
        title="增量医生备案导入"
        maskClosable={false}
        destroyOnClose
        onCancel={handleClose}
        open={visible}
        footer={null}>
          {
            messages.length === 0 && (
              <div className={modStyl.container}>
                <div className={modStyl.tips}>请先下载模板信息，更新后上传，<Button onClick={dlTemplate} type="link" size="small">点击下载模板</Button></div>
                <Dragger {...draggerProps}>
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p className="ant-upload-text">将文件拖到此处，或点击上传</p>
                  <p className="ant-upload-hint">
                      只能上传xlsx文件，且不超过30MB
                  </p>
                </Dragger>
                <Space size="large" align="center" style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 16}}>
                  <Button onClick={handleClose}>取消</Button>
                  <Button disabled={fileList.length === 0} type="primary" onClick={uploadFile}>导入</Button>
                </Space>
              </div>
            )
          }
          
          {
            messages.length > 0 && (
              <Table
                bordered
                scroll={messages.length <= 5 ? {} : {y: 200}}
                pagination={false}
                columns={[
                  {width: 40, title: '', align: 'right', dataIndex: 'key', render: (_, _1, i)=> i+1,},
                  {width: 80, title: '行号', align: 'right', dataIndex: 'currentRowNumber'},
                  {title: '错误信息', align: 'center', dataIndex: 'msg'},
                ]}
                size="small"
                style={{maxHeight: 240}}
                dataSource={messages}/>
            )
          }
      </Modal>
  )
}

export default ImportModal
