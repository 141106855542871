import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import axios from "axios";
import {
  Modal,
  Upload,
  Image,
  ConfigProvider,
  message,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { GetCaseSts } from "../../../../../../../services/data/DataService";
import { scaleUpload } from "@/services/data/patientSelfReported";
// import './photo.less';
import zh_CN from "antd/lib/locale-provider/zh_CN";
import OSS from "ali-oss";
import {
  makeThumb,
  makeUploadHeaders,
  makeUploadName,
  makeUploadOptions,
} from "../../../../../../crf_form/components/ocr-autofill/utils";

import ImageViewer from "../../../../../../crf_form/components/image-viewer";

let client: any = null;
let timerId;

function PhotoOssCom(props: any, ref: any) {
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [fileList, setFileList] = useState<any>([]);
  const [value, setValue] = useState<any>([]);
  const [data, setData] = useState<any>();
  const [previewImage, setPreviewImage] = useState<any>();

  const [ossData, setOssData] = useState<any>({});
  const [vedioProgress, setVedioProgress] = useState<any>([null]);

  useEffect(() => {
    if (props.data) {
      let fieldList = [];
      const urls = props.data.url;
      for (let i = 0; i < urls?.length; i++) {
        fieldList.push(makeThumb(urls[i]));
      }
      setValue(fieldList);
      setFileList(fieldList);
    } else {
      setValue([]);
      setFileList([]);
    }
  }, [props.data]);

  /**
   * 提取上传类型
   * @param json
   * @returns
   */
  const filterPathPre = (json: any, type: any) => {
    let newJson = json;
    let data = [];
    data = newJson.pathPre.split(",");
    data.map((i: any) => {
      if (i.includes(type)) {
        newJson.pathPre = i;
      }
    });

    return newJson;
  };

  /**
   * 获取OSS访问令牌
   */
  const getOssData = () => {
    // 当前缓存中已经存储了oss token
    // let oss = sessionStorage.getItem("ossToken");
    // if (oss) {
    //     let json = JSON.parse(oss);
    //     client = new OSS({ ...filterPathPre(json, 'image') });
    //     setOssData(filterPathPre(json, 'image'))
    //     return;
    // }

    let loading = sessionStorage.getItem("loadingOssToken");
    if (loading == "true") {
      // setTimeout(getOssData, 500);
      return;
    } else {
      sessionStorage.setItem("loadingOssToken", "true");
    }

    GetCaseSts().then((res) => {
      if (res.code == 0) {
        sessionStorage.setItem("ossToken", JSON.stringify(res.data));
        sessionStorage.setItem("loadingOssToken", "false");
        if (!res.data) {
          return;
        }
        setOssData(filterPathPre(res.data, "image"));

        timerId = setTimeout(() => {
          sessionStorage.removeItem("ossToken");
        }, 3600 * 1000);

        client = new OSS({ ...filterPathPre(res.data, "image") });
      }
    });
  };

  //图片预览
  const handlePreview = ({ url }: UploadFile) => {
    setPreviewImage(url);
    setPreviewVisible(true);
  };

  const handleChange = async ({ file }: any) => {
    if (!file) {
      return;
    }

    const [_, ext] = file.type.split("/");
    if (![".jpg", ".jpeg", ".png"].includes(`.${ext}`)) {
      message.error("上传格式不允许");
      return;
    }

    const pieces = await makeUploadOptions(message.error);

    if (!pieces) {
      message.error("初始化上传参数失败");
      return;
    }

    const [prefix, options] = pieces;

    const client = new OSS(options);

    const start = Date.now();

    //处理FileReader
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      const pathname = makeUploadName(prefix, file);

      if (!pathname) {
        message.error("上传缺少必要参数");
        return;
      }
      client
        .multipartUpload(pathname, file, {
          headers: makeUploadHeaders(props.formId),
          // 获取分片上传进度、断点和返回值。
          progress: (p: any, cpt: any, res: any) => {
            let timer;
            if (p > 0) {
              timer = Math.floor(((Date.now() - start) * (1 - p)) / p / 1000);
              timer =
                timer > 60
                  ? Math.floor(timer / 60) + "分" + (timer % 60) + "秒"
                  : timer + "秒";
            } else {
              timer = "计算中";
            }
            setVedioProgress({
              pre: Math.floor(p * 1000) / 10 + "%",
              rt: Math.floor((res?.rt || 0) / 100) + "kb/s",
              timer: timer,
            });
          },
          // 设置并发上传的分片数量。
          parallel: 4,
          // 设置分片大小。默认值为1 MB，最小值为100 KB。
          partSize: 1024 * 1024 * 1,
          mime: "text/plain",
        })
        .then((res: any) => {
          setVedioProgress(null);
          if (res.res.status !== 200) {
            message.error("上传失败，请重试！");
            return;
          }
          let url = res.res.requestUrls[0];
          if (url) {
            if (url.indexOf("?") >= 0) {
              url = url.substring(0, url.indexOf("?"));
            }
            const thumbs = [...value, makeThumb(url)];
            setValue(thumbs);
            setFileList(thumbs);
          }
        })
        .catch((err: any) => {
          message.error("上传失败，请重试！");
        });
    };
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>点击上传图片</div>
    </div>
  );

  const beforeUploadControl = (file: File) => {
    return file;
  };

  useImperativeHandle(ref, () => ({
    // 暴露给父组件的方法
    getModifyValue: () => {
      try {
        scaleUpload({
          id: props.data.id,
          url: fileList.map(({ url }: { url: string }) => url),
        });
      } catch (error) {
        console.error(error);
      }
    },
  }));

  const removeChange = (file: any) => {
    let _fileList = fileList.filter((v: any) => v.uid !== file.uid);
    setValue(_fileList);
    setFileList(_fileList);
  };

  return (
    <ConfigProvider locale={zh_CN}>
      <div className="photoCom">
        {
          <div>
            <Upload
              method="POST"
              accept=".jpg, .jpeg, .png"
              customRequest={handleChange}
              listType="picture-card"
              fileList={fileList}
              beforeUpload={beforeUploadControl}
              onPreview={handlePreview}
              onRemove={removeChange}
              maxCount={8}
            >
              {value.length >= 8 ? null : uploadButton}
            </Upload>
            {vedioProgress && vedioProgress.pre && (
              <div>
                <div>离开页面会导致上传失败，请耐心等待上传完成</div>
                <div className={"item"}>剩余时间：{vedioProgress.timer}</div>
                <div className={"item"}>上传进度：{vedioProgress.pre}</div>
                <div className={"item"}>上传速度：{vedioProgress.rt}</div>
              </div>
            )}

            <ImageViewer
              visible={previewVisible}
              urls={fileList.map(({ url }: { url: string }) => url)}
              url={previewImage}
              onClose={() => setPreviewVisible(false)}
            />
          </div>
        }
      </div>
    </ConfigProvider>
  );
}

export default forwardRef(PhotoOssCom);
