import { Canvas, Point, Polygon, Group, FabricObject } from "fabric";
import { OcrMark } from ".";

// ocr标记渲染
export const handleOcrMark = (
  canvas: Canvas,
  isOcrMark: boolean,
  targetDatas: OcrMark[] | undefined,
  group: Group | null,
  isShake?: boolean 
) => {
  if (targetDatas?.length) {

    targetDatas = removeDuplicatesByLocation(targetDatas)

    targetDatas.forEach((e: any) => {
      if (e.location && JSON.parse(e.location)) {
        const canvasCoords = convertPointsToCanvasCoords(
          canvas,
          JSON.parse(e.location)
        );
        highlightPolygon(canvas, isOcrMark, canvasCoords, group, isShake);
      }
    });
  }
};
   // 坐标去重，避免反复渲染加深了
 const removeDuplicatesByLocation = (data: OcrMark[]) => {
  const locationMap = new Map();

  // 遍历数组，用location作为key存储item
  data.forEach(item => {
    if (!locationMap.has(item.location)) {
      locationMap.set(item.location, item);
    }
  });

  // 将Map中的值转换回数组
  return Array.from(locationMap.values());
}

export const 
highlightPolygon = (
  canvas: Canvas,
  isOcrMark: boolean,
  points: { X: number; Y: number }[],
  group: Group | null,
  isShake?: boolean 
) => {
  // 根据缩放比例调整点的位置
  const polygonPoints = points.map((point) => new Point(point.X, point.Y));
  let polygon = new Polygon(polygonPoints, {
    fill: "rgba(255, 165, 0, 0.7)",
    stroke: "#FF0000",
    strokeWidth: 2,
    selectable: false,
    hoverCursor: "default",
    opacity: 0.7,
    strokeDashArray: [8, 8],
  });

  if (isShake && isOcrMark) {
    // 高亮
     polygon = new Polygon(polygonPoints, {
      fill: "rgba(0, 200, 200, 0.7)",
      stroke: "#FF0000",
      strokeWidth: 2,
      selectable: false,
      hoverCursor: "default",
      opacity: 0.7,
      strokeDashArray: [10, 10],
    });
    for (const o of canvas.getObjects()) {
      if (o.type === "group") {
        console.log((o as Group).getObjects())
        break;
      }
    }
    //  shakePolygon(canvas, polygon, 5, 1000);
  }
  if (group) {
    group.add(polygon);
    canvas.renderAll();
  } else {
    for (const o of canvas.getObjects()) {
      if (o.type === "group") {
        (o as Group).add(polygon);
        canvas.renderAll();
        if(isShake && isOcrMark){
          setTimeout(()=>{
            (o as Group).remove(polygon);
            canvas.renderAll();
          }, 1000)
        }
        break;
      }
    }
  }
};

// 获取图片对象的方法
export const getActiveImage = (canvas: Canvas) => {
  const [first, ...objects] = canvas.getObjects();

  if (first && first.type === "group") {
    // return (first as Group).getObjects().find((o) => o.type === "image");
    return first;
  } else {
    // 直接在画布上寻找image类型的对象
    return objects.find((o) => o.type === "image");
  }
};

// 相对图片的坐标
export const convertPointsToCanvasCoords = (
  canvas: Canvas,
  pointsOnImage: { X: number; Y: number }[]
) => {
  const img = getActiveImage(canvas) || {
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  };
  const left = img.left;
  const top = img.top;
  if (!img) return pointsOnImage; // 如果没有找到图片，则直接返回原始点
  return pointsOnImage.map((point) => ({
    X: point.X + (left || 0),
    Y: point.Y + (top || 0),
  }));
};

// 清空高亮数据
export const handleClearMark = (canvas: Canvas) => {
  if (canvas && canvas.getObjects()) {
    const [first] = canvas.getObjects();
    // 当前处于移动状态
    if (first?.type === "group") {
      // 清除高亮数据
      (first as Group)?.getObjects().forEach((e:FabricObject) => {
        // if (e.type == "polygon") {
        //   (first as Group)?.remove(e);
        // }
        // 删除掉除背景图以外的所有元素
        if (e.type !== "image") {
          (first as Group)?.remove(e);
        }
        first.hasControls = false
        
      });
      
    }else{
      for(const obj of canvas.getObjects()) {
        canvas.remove(obj);
      }
    }
    canvas.renderAll();
  }
};

// 晃动
export const shakePolygon = (
  canvas: Canvas,
  polygon: FabricObject,
  distance: number,
  duration: number
) => {
  let start = performance.now();
  const interval = duration / 10;
  const animate = (time: number) => {
    const elapsed = time - start;
    if (elapsed > duration) {
      polygon.set({ left: polygon.left, top: polygon.top, fill:'rgba(255, 165, 0)', opacity:'0.7'});
      canvas.renderAll();
      return;
    }

    const progress = (elapsed % interval) / interval;
    const offsetX = Math.sin(progress * Math.PI * 2) * distance;
    const offsetY = Math.cos(progress * Math.PI * 2) * distance;

    polygon.set({ left: polygon.left + offsetX, top: polygon.top + offsetY, fill:'rgba(255, 165, 0)', opacity:'0.7' });
    canvas.renderAll();
    requestAnimationFrame(animate);
  };

  requestAnimationFrame(animate);
};




