import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { DatePicker, Input, Radio, Space, Tooltip, Select , message, Cascader} from 'antd';
import { saveFormData } from "../../../../services/data/DataService";
import moment from 'moment';
import './unknownDate.less';
import ActionView from '../../com/actionView/actionView';
import formAction from '../../utils/formAction';
import mExper from "../../utils/operation.ts";
import { InfoCircleOutlined } from '@ant-design/icons';
import verify from '../../utils/verify';
import utils from '../../utils/utils';
function UnknownDateCom(props: any, ref: any) {
    const [options, setOptions] = useState<any>([]);
    const [yearOptions, setYearOptions] = useState<any>([]);
    const [monthOptions, setMonthOptions] = useState<any>([]);
    const [dayOptions, setDayOptions] = useState<any>([]);
    const [currentYear, setCurrentYear] = useState<any>()
    const [currentMonth, setCurrentMonth] = useState<any>()
    const [currentDay, setCurrentDay] = useState<any>()
    const [value, setValue] = useState<any>();
    const [data, setData] = useState<any>();
    const [formId, setFormId] = useState<any>();
    const [dataActions, setDataActions] = useState<any>([]);
    const [formIsRead, setFormIsRead] = useState<boolean>(true);
    const [savedValues, setSavedValues] = useState<any>([]);
    const [updatedValue, setUpdatedValue] = useState<any>();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [direction, setDirection] = useState<any>('horizontal');
    const [minDate, setMinDate] = useState<any>();
    const [maxDate, setMaxDate] = useState<any>();
    // 超范围异常提醒
    const [annotation, setAnnotation] = useState<any>(null);
    // 不详时间控制的默认值
    const [ defaultChineseName, setDefaultChineseName] = useState<any>('不详');
    useEffect(() => {
        if (props.data) {
            setDefaultChineseName(props.data?.defaultChineseName || '不详')
            //设置dataActions
            setDataActions(props.dataActions);
            //表单是否只读
            setFormIsRead(props.formIsRead);
            //获取当前表单的id
            setFormId(props.formId);
            //该条表单元素的数据
            setData(props.data);
            //已经保存的表单数据
            setSavedValues(props.savedValues);
            //如果是修改时
            if (props.isUpdate) {
                setIsUpdate(props.isUpdate);
            }
            else {
                setIsUpdate(false);
            }
            //设置排列方向
            setDirection(props.data.direction ? props.data.direction : 'horizontal');

            //设置当前表单已存的value
            let currentItem = props.savedValues.find((i: any) => {
            return i.dataCode == props.data.dataCode
            });
        
            if (currentItem?.value) {
                const dateArr = currentItem.value.split("-");
                setValue([dateArr[0],dateArr[1],dateArr[2]]);
                setUpdatedValue([dateArr[0], dateArr[1], dateArr[2]]);
            } else {
                setValue(null);
                setUpdatedValue(null);
            }
           

        }
    }, [props])

    useEffect(()=>{
        //有初始化data完毕后，数值范围控制
        rangeControl();
    }, [props.savedValues, data])

    useEffect(() => {
        setOptions(generateOptions());
    }, [defaultChineseName, maxDate, minDate])
 
    // 自定义显示值
    const customDisplayRender = (labels:any) => labels.join('-');

    const rangeControl  = ()=>{
        //设置最大值最小值
        let allMax, allMin; // 所有比较最后的最大最小值
        let maxArr: Array<any> = []; // 最大值和最小值数组
        let minArr: Array<any> = [];

        if (data && data.validationRules && data.validationRules.length > 0) {
             // 数据预处理 list类型规则数组
             const listAlls =  data.validationRules.filter((e: any)=> e.listAllValues === 'all');
             const extraArr: any = [];
             for(const e of listAlls){
                 for(const s of props?.globalValues || []){
                     const Index = (s.dataCode).indexOf(`${e.rule}#`);
                     if(Index !== -1 ){
                         let ee = JSON.parse(JSON.stringify(e));
                         ee["rule"] = s.dataCode;
                         // 检查ee是否已经在extraArr中
                         if (!(extraArr|| []).some((extra:any)=>extra.rule === ee.rule)) {
                             extraArr.push(ee);
                         }
                     }
                 }
                
             }
             const noListAlls = data.validationRules.filter((e: any)=> e.listAllValues !== 'all');
             // 换算新的时间的规则
            const newValidationRules = [...noListAlls, ...extraArr];
            // console.log("newValidationRules:", newValidationRules)
            for (let item of newValidationRules) {
                let max;
                let min;
                if (item.type === 'range') {
                    if (item.condition === 'lte') {
                        if (item.rule === '${currDate}') {
                            max = new Date();
                            
                        } else if (item.rule === 'visitDate') {
                            if (item.visit) {
                                let _data = props.visitInfoList.find((v: any) => {
                                    return v.visitName == item.visit
                                })
                                if (_data && _data.visitDate) {
                                    if (item.space) {
                                        let newDate = utils.getNewDateFormDays(_data.visitDate, item.space, 'reduce')
                                        max = new Date(newDate);
                                        
                                    } else {
                                        max = new Date(_data.visitDate);
                                    }
                                }
                            }
                        }
                    }
                    else if (item.condition === 'gte') {
                        if (item.rule === 'visitDate') {
                            if (item.visit) {
                                let _data = props.visitInfoList.find((v: any) => {
                                    return v.visitName == item.visit
                                })
                                if (_data && _data.visitDate) {
                                    if (item.space) {
                                        let newDate = utils.getNewDateFormDays(_data.visitDate, item.space, 'add')
                                        min = new Date(newDate);
                                    } else {
                                        min = new Date(_data.visitDate);
                                    }
                                }
                            }

                        } else if (item.rule) {
                            // min = new Date(item.rule)
                            min = new Date(getNextDate(item.rule, -1))
                        }
                    }
                    else if(item.condition === 'between') {
                        if(Array.isArray(item.rule)) {
                            // 要填充的时间
                            // const fill = ['00:00:00', '23:59:59']
                            // // 只有日期时自动补充时间
                            // const pieces = item.rule.map((now: string, i: number)=> {
                            //     if(dateRegExp.test(now)) {
                            //         return `${now} ${fill[i]}`
                            //     }
                            // })
                            const pieces = item.rule;
                            // for(const now of pieces) {
                            //     if(!dateTimeRegExp.test(now)) {
                            //         message.error('日期格式配置错误')
                            //         // return false
                            //     }
                            // }

                            [min, max] = pieces.map((t: string)=> new Date(t))
                        }
                    }
                }
                else if (item.type === 'compare') {
                    let compareData;
                    if (props.savedValues && props.savedValues.length > 0) {
                       
                        let _index = -1; // 多组时当前下标
                        if (props.data && props.data["dataCode"] && props.data["dataCode"].indexOf('#') >= 0) {
                            _index = props.data["dataCode"].split('#')[1];
                        }
                        for (let value of props.savedValues) {
                            if ((item.rule == value["dataCode"]) || (item.rule == value["deCode"])) {
                                compareData = value["value"];
                            }
                            // 多组
                            if (_index != -1) {
                                // eg -> startTime12#{index}
                                if (item.rule && item.rule.indexOf('#') >= 0) {
                                    let rulePre = item.rule.split('#')[0];
                                    let nowRule = rulePre + '#' + _index;
                                    if (nowRule == value["dataCode"]) {
                                        compareData = value["value"];
                                    }
                                }
                            }
                        }
                    }
                    // 从全局找最新的值
                    if(!compareData){
                        const arr =  (props?.globalValues|| []).filter((s:any) => s.dataCode === item.rule)
                        if(arr?.length){
                            compareData = arr[arr.length-1].value
                        }  
                    }

                    if (compareData && item.condition === 'lte') {
                        max = new Date(compareData)
                    } else if (compareData && item.condition === 'gte') {
                        min = new Date(compareData)
                    }
                 }
                 if (max) {
                    maxArr.push(max)
                 }
                 if(min){
                    minArr.push(min)
                 }
            }
         
        }
        // console.log("data.validationRules:" ,data?.validationRules)
        // console.log("maxArr:" , maxArr.map((e:any)=>moment(e).format('YYYY-MM-DD')));
        // console.log("minArr:" , minArr.map((e:any)=>moment(e).format('YYYY-MM-DD')));
        // 大于的时间和小于的时间做比较
      
        if(maxArr?.length){
            allMax =  Math.min(...maxArr.map((e:any) => e.getTime()));
        }
        if(minArr?.length){
            allMin =  Math.max(...minArr.map((e:any) => e.getTime()));
        }

        if (allMax && allMin) {
            setMaxDate(moment(allMax).format('YYYY-MM-DD'));
            setMinDate(moment(allMin).format('YYYY-MM-DD'));
        } else if (allMax) {
            setMaxDate(moment(allMax).format('YYYY-MM-DD'));
            setMinDate(null);
        } else if (allMin) {
            setMaxDate(null)
            setMinDate(moment(allMin).format('YYYY-MM-DD'));
        } 

    };

    const getNextDate = (date: any, day: any) => {
        var dd = new Date(date);
        dd.setDate(dd.getDate() + day);
        var y = dd.getFullYear();
        var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
        var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        return y + "-" + m + "-" + d;
    };

    // 针对年月日的修改
    const handleDayChange = (value: any) => {
        setCurrentDay(value);
    }
    const handleMonthChange = (value: any) => {
        setCurrentMonth(value);
        setCurrentDay(value === defaultChineseName ? defaultChineseName: currentDay);
    }

    const handlYearChange = (value: any) => {
        setCurrentYear(value);
        setCurrentMonth(value === defaultChineseName? defaultChineseName: currentMonth) ;
        setCurrentDay(value === defaultChineseName? defaultChineseName: currentDay) ;
    }
    const generateOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        let maxYear = maxDate ? maxDate.split("-")[0] : null;
        let minYear = minDate ? minDate.split("-")[0] : null;
      
        for (let year = currentYear; year >= 1920; year--) {
        
          const yearObj = {
            value: year.toString(),
            label: year.toString(),
            disabled: (maxYear && year > maxYear) || (minYear && year < minYear) ,
            children: generateMonthOptions(year),
          };
          years.push(yearObj);
        }
    
        years.unshift({
          value: defaultChineseName,
          label: defaultChineseName,
          disabled: false,
          children: [
            {
                value: defaultChineseName,
                label: defaultChineseName,
                disabled: false,
                children: [{
                    value: defaultChineseName,
                    label: defaultChineseName,
                    disabled: false,
                    children: [
                        
                    ]
                 } ],
            }
          ],
        });
    
        return years;
      };
    // 年份下拉选
    // const generateYearOptions = () => {
    //     const currentYear = new Date().getFullYear();
    //     const years = [];
    //     let  maxYear = maxDate? maxDate.split("-")[0] : null;
    //     let  minYear = minDate? minDate.split("-")[0] : null;
       
    //     for (let year = currentYear; year >= 1920; year--) {
    //         const yearObj = { label: year.toString(), value: year.toString(), disabled: false};
            
    //         if ((maxYear  && year > maxYear) || (minYear && year < minYear)) {
    //             yearObj.disabled = true;
    //         }
    //         years.push(yearObj);
    //     }
    //     // 添加“不详”选项  
    //     years.unshift({ label: defaultChineseName, value: defaultChineseName });
    //     return years;
    // }

    
    const generateMonthOptions = (year:any) => {
        let maxYear = maxDate ? maxDate.split("-")[0] : null;
        let maxMonth = maxDate ? maxDate.split("-")[1] : null;
        let minYear = minDate ? minDate.split("-")[0] : null;
        let minMonth = minDate ? minDate.split("-")[1] : null;

        const months = [
        ...Array.from({ length: 12 }, (_, index) => ({
            value: index < 9 ? `0${index + 1}` : `${index + 1}`,
            label: index < 9 ? `0${index + 1}` : `${index + 1}`,
            disabled: false,
            children: generateDayOptions(year, index + 1),
        })),
        ];

        months.forEach((e) => {
            if (
                (maxMonth && e.value > maxMonth && year == maxYear) ||
                (minMonth && e.value < minMonth && year == minYear)
            ) {
                e.disabled = true;
            }
        });

        months.unshift({
            value: defaultChineseName,
            label: defaultChineseName,
            disabled: false,
            children: [{
                value: defaultChineseName,
                label: defaultChineseName,
                disabled: false,
             } ],
        });

        return months;
    };

    const generateDayOptions = (year : any, month : any) => {
        let maxYear = maxDate ? maxDate.split("-")[0] : null;
        let maxMonth = maxDate ? maxDate.split("-")[1] : null;
        let maxDay = maxDate ? maxDate.split("-")[2] : null;
        let minYear = minDate ? minDate.split("-")[0] : null;
        let minMonth = minDate ? minDate.split("-")[1] : null;
        let minDay = minDate ? minDate.split("-")[2] : null;
    
        const daysInMonth = new Date(year, month, 0).getDate();
        const days = [
          ...Array.from({ length: daysInMonth }, (_, index) => ({
            value: index < 9 ? `0${index + 1}` : `${index + 1}`,
            label: index < 9 ? `0${index + 1}` : `${index + 1}`,
            disabled: false,
          })),
        ];
    
        days.forEach((e) => {
          if (
            (maxDay && e.value > maxDay && year == maxYear && month == maxMonth) ||
            (minDay && e.value < minDay && year == minYear && month == minMonth)
          ) {
            e.disabled = true;
          }
        });
    
        days.unshift({
          value: defaultChineseName,
          label: defaultChineseName,
          disabled: false,
        });
    
        return days;
      };
    // // 月份下拉选
    // function generateMonthOptions() {
    //     let  maxYear = maxDate? maxDate.split("-")[0] : null;
    //     let  maxMonth = maxDate? maxDate.split("-")[1] : null;
    //     let  minYear = minDate? minDate.split("-")[0] : null;
    //     let  minMonth = minDate? minDate.split("-")[1] : null;

    //     const months = [
    //         ...Array.from({ length: 12 }, (_, index) => ({
    //             label: (index + 1).toString(),
    //             value: index < 9 ? `${"0" + (index + 1)}` : `${(index + 1).toString()}`,
    //             disabled: false
    //         }))
    //     ];
   
    //     months.forEach((e,index)=>{
    //         if ( (maxMonth  &&  e.value  > maxMonth && currentYear == maxYear ) || (minMonth &&  e.value  < minMonth  && currentYear == minYear)) {
    //             e.disabled = true;
    //         }
    //     })

    //     // 添加“不详”选项  
    //     months.unshift({ label: defaultChineseName, value: defaultChineseName, disabled: false });  
    //     return months;
    // }
    // // 日期下拉选
    // function generateDayOptions(year: any, month: any) {
    //     let  maxYear = maxDate? maxDate.split("-")[0] : null;
    //     let  maxMonth = maxDate? maxDate.split("-")[1] : null;
    //     let  maxDay = maxDate? maxDate.split("-")[2] : null;
    //     let  minYear = minDate? minDate.split("-")[0] : null;
    //     let  minMonth = minDate? minDate.split("-")[1] : null;
    //     let  minDay = minDate? minDate.split("-")[2] : null;
    
    //     const daysInMonth = new Date(year, month, 0).getDate(); // 获取指定月份的天数  
    //     const days = [
    //         ...Array.from({ length: daysInMonth }, (_, index) => ({
    //             label: (index + 1).toString(),
    //             value: index < 9 ? `${"0" + (index + 1)}` : `${(index + 1).toString()}`,
    //             disabled: false
    //         }))

    //     ];

    //     days.forEach((e,index)=>{
    //         if ((maxDay && e.value > maxDay && currentYear == maxYear && currentMonth == maxMonth) 
    //             || (minDay &&  e.value < minDay && currentYear == minYear && currentMonth == minMonth)) {
    //             e.disabled = true;
    //         }
    //     })
       
    //     // 添加“不详”选项  
    //     days.unshift({ label: defaultChineseName, value: defaultChineseName, disabled: false });  
    //     return days;
    // }

    const onChange = (v:any)=>{
        console.log(v)
        setValue(v);
        setUpdatedValue(v);
        // 变化保存
        let values = [
            {
                dataCode: props.data.dataCode,
                deCode: props.data.deCode,
                value: v?.length? `${v[0]}-${v[1]}-${v[2]}`:''
            }
        ]
        saveData(formId, values);     
    }

    //暂存数据
    const saveData = (formId: any, values: any) => {
        let params = {
            formId: formId,
            submit: false,
            values: values
        }
        formAction.saveData(params);
        props.updateFun && props.updateFun()
    }

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        getModifyValue: () => {
            let params = {
                dataCode: data.dataCode,
                deCode: data.deCode,
                formId: formId,
                value: updatedValue?.length?`${updatedValue[0]}-${updatedValue[1]}-${updatedValue[2]}`:"",
            }
            return params;
        },
    }));

    return (
        <div className="selectCom">

            <React.Fragment >
      
                <Cascader
                    disabled={!formIsRead}
                    style={{ width: '100%' }}
                    options={options}
                    value={value}
                    onChange={ onChange}
                    placeholder={data ? `请选择${data.label}` : ''}
                    allowClear
                    displayRender={customDisplayRender}
                />
                {
                    annotation && annotation.status &&
                    <Tooltip title={annotation.message}>
                        <InfoCircleOutlined style={{ color: 'red', fontSize: 16 }} />
                    </Tooltip>
                }
            </React.Fragment>
        </div>
    )
}

export default forwardRef(UnknownDateCom);
