import React, { useEffect, useMemo, useState } from "react"
import { Button, Cascader, Col, Form, Input, Row, Select } from "antd"

import modStyl from './condition.module.scss'
import utils from "../../../../../util/utils"
import TenantConfiguration from "../../../../../util/tenantConfiguration"
import { useFetchCenter, useFetchProject, useFetchStatus, useFetchVisitTree } from "../repo"
import { transformVisitData } from "../utils"

interface Payload {
  // 所属项目
  projectCode?: string
  // 所属中心
  centerCode?: string[]
  // 访视&表单
  visit?: {name: string, formName: string[]}[]
  // 采集人
  createUser?: string
  // 患者编号
  subjectCode?: string
  // 状态
  questionStatus?: string
}

const colCols = {
  span: 6
}
const fieldCols = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 18
  }
}

interface ConditionProps {
  onSearch: (params?: Payload)=> void
}
const Condition: React.FC<ConditionProps> = ({ onSearch })=> {

  const [disabledCascader, setDisabledCascader] = useState(true)

  const [form] = Form.useForm()

  // 
  const targetName = useMemo(()=> TenantConfiguration.read('label'), [])

  // 状态
  const { statusOptions } = useFetchStatus()

  // 访视&表单
  const { fetchVisitTreeData, visitFetching, visitTreeOptions, setVisitTreeOptions} = useFetchVisitTree()

  // 中心列表
  const { fetchCenterData, centerFetching, centerOptions } = useFetchCenter()
  
  // 项目列表
  const { fetchProjectData, projectFetching, projectOptions } = useFetchProject()

  const takePayload = ()=> {
    const { visit, ...rest } = form.getFieldsValue()

    const parsed = transformVisitData(visit)

    return {
      ...rest,
      visit: parsed,
    }
  }

  const submitControl = ()=> {
    onSearch(takePayload())
  }

  const resetControl = ()=> {
    form.resetFields()
    if (projectOptions.length === 1) {
      form.setFieldValue('projectCode', projectOptions[0].value)
    }
    setDisabledCascader(true)
    setVisitTreeOptions([])
    onSearch()
  }

  const exportControl = ()=> {
    utils.exportPostFun('dct/project/cra/downLoadImagetList', takePayload())
  }

  const handleProjectChange = (value?: string)=> {
    form.setFieldValue('visit', undefined)
    setVisitTreeOptions([])
    setDisabledCascader(typeof value === 'undefined')
  }

  const handleFetchCenterData = (open: boolean)=> {
    if (open && centerOptions.length === 0) {
      fetchCenterData()
    }
  }

  const handleFetchVisitTree = (open: boolean)=> {
    if (open && visitTreeOptions.length === 0) {
      fetchVisitTreeData(form.getFieldValue('projectCode'))
    }
  }

  useEffect(()=> {
    fetchProjectData().then((projectCode)=> {
      if ('' !== projectCode) {
        setDisabledCascader(false)
        form.setFieldValue('projectCode', projectCode)
      }
      onSearch({
        projectCode,
      })
    })
  }, [])

  return (
    <div className={modStyl.wrapper}>
      <Form form={form} >
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="projectCode" label="所属项目">
              <Select
                loading={projectFetching}
                options={projectOptions}
                optionFilterProp="label"
                placeholder="请选择"
                onChange={handleProjectChange}
                showSearch
                allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="centerCode" label="所属中心">
              <Select
                onDropdownVisibleChange={handleFetchCenterData}
                loading={centerFetching}
                options={centerOptions}
                maxTagCount={1}
                optionFilterProp="label"
                mode="multiple"
                placeholder="请选择"
                showSearch
                allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="visit" label="访视/表单">
              <Cascader
                onDropdownVisibleChange={handleFetchVisitTree}
                options={visitTreeOptions}
                disabled={disabledCascader}
                loading={visitFetching}
                fieldNames={{label: 'formName', value: 'formName'}}
                placeholder="请选择"
                maxTagCount={1}
                multiple
                allowClear/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="createUser" label="　采集人">
              <Input placeholder="请输入采集人" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="subjectCode" label={`${targetName}编号`}>
              <Input placeholder={'请输入'+targetName+'姓名缩写/编号查找'} allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="questionStatus" label="状态">
              <Select options={statusOptions} placeholder="请选择" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Button onClick={submitControl} type="primary">搜索</Button>
            <Button onClick={resetControl} style={{marginLeft: 10}}>重置</Button>
            <Button onClick={exportControl} style={{marginLeft: 10}} type="link">导出数据</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Condition