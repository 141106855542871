import React, { useEffect, useRef, useState } from "react"

import indexStyl from './index.module.scss'
import Condition from "./components/condition"
import { Empty, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { getReportScaleData, ReportScalePayload, ReportScaleResult } from "../../../services/data/report"


const EPRORegister = ()=> {

  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [dataSource, setDataSource] = useState<ReportScaleResult[]>([])

  const conditionRef = useRef<ReportScalePayload>({})

  //#region 表格列
  const columns: ColumnsType<ReportScaleResult> = [
    {
      width: 150,
      title: '患者编号',
      align: 'center',
      dataIndex: 'subjectCode',
    },
    {
      width: 120,
      title: '姓名缩写',
      align: 'center',
      dataIndex: 'name',
    },
    {
      width: 120,
      title: '是否注册',
      align: 'center',
      dataIndex: 'bindState',
    },
    {
      width: 120,
      title: '研究中心编号',
      align: 'center',
      dataIndex: 'hospitalCode',
    },
    {
      width: 120,
      title: '研究者姓名',
      align: 'center',
      dataIndex: 'doctorName',
    },
    {
      width: 120,
      title: '研究中心',
      align: 'center',
      dataIndex: 'centerName',
    },
    {
      width: 160,
      title: '患者注册时间',
      align: 'center',
      dataIndex: 'createTime',
      render: (value: string)=> value || '-'
    },
  ];
  //#endregion

  const fetchData = async (params: ReportScalePayload, page = 1)=> {
    setLoading(true)
    const {code, data} = await getReportScaleData(page, pageSize, params)
    if (code) {
      return
    }
    setPageNum(page)
    setLoading(false)
    setTotal(data.total)
    setDataSource(data.rows)
  }

  const onSearch = (params: ReportScalePayload)=> {
    conditionRef.current = {
      ...params
    }
    fetchData(conditionRef.current)
  }

  useEffect(()=> {
    fetchData({})
  }, [])

  return (
    <div className={indexStyl.wrapper}>
      <Condition onSearch={onSearch}/>
      <div className={indexStyl.tableWrapper}>
        <Table
          loading={loading}
          size="middle"
          rowKey="id"
          scroll={{y: window.innerHeight - 350}}
          columns={columns}
          dataSource={dataSource}
          locale={{emptyText: <Empty description="暂无数据"/>}}
          pagination={{
            onChange: (page: number)=> {
              fetchData(conditionRef.current, page)
            },
            total: total,
            current: pageNum,
            pageSize: pageSize,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showQuickJumper: false,
            showTotal: total=> `共${total}条，每页${pageSize}条`,
            position: ["bottomRight"]}}/>
      </div>
    </div>
  )
}

export default EPRORegister