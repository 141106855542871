import { Properties } from "."
import options from "./options"

export interface Option {
  value: string
  label: string
  disabled?: boolean
  children?: Option[]
}

const filterLevel = (data: Option[], current: number, properties: Properties): Option[]=> {
  const { level = 2, display = true, includes = [], excludes = [] } = properties
  const pieces = []
  
  const currentIncludes = includes[current - 1] || []
  const currentExcludes = excludes[current - 1] || []

  for (let { label, value, children } of data) {
    
    let disabled = false

    if (currentIncludes.length > 0) {
      disabled = !currentIncludes.includes(label)
    } else {
      if (currentExcludes.length > 0) {
        disabled = currentExcludes.includes(label)
      }
    }

    // 禁用了直接跳过 不进行子节点遍历
    if (disabled) {
      if (display) {
        pieces.push({ label, value, disabled })
      }
      continue
    }

    if (current === level) {
      pieces.push({ label, value, disabled })
    } else if (current < level) {
      if (Array.isArray(children)) {
        pieces.push({ label, value, disabled, children: filterLevel(children, current + 1, properties) })
      }
    }
  }
  return pieces
}

export const filterOptions = (properties: Properties): Option[]=> {
  return filterLevel(options, 1, properties)
}
